import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import { Button, Col, Form, Nav, Row, Tab } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  useGetUserEventsQuery,
  useGetUserGroupsQuery,
  useGetUserRequestsQuery,
} from '../../services/profile';

import {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
} from '../../services/organizations';

import { useGetBookingByUserQuery } from '../../services/booking';

import {
  useGetEmployeeRequestsQuery,
  useUpdateEmployeeRequestsMutation,
} from '../../services/requests';

import {
  useCreateResumeMutation,
  useUpdateResumeMutation,
  useDeleteResumeMutation,
} from '../../services/users';

import { logout, fetchUserUpdate } from '../../redux/slices/authSlice';

import {
  FaAddressBook,
  FaBookmark,
  FaBuilding,
  FaEdit,
  FaFile,
  FaHistory,
  FaInfoCircle,
  FaListUl,
  FaRedo,
  FaRegCalendarAlt,
  FaSignOutAlt,
  FaTrashAlt,
  FaUserCircle,
} from 'react-icons/fa';

import EventItem from '../../components/Events';
import BookingItem from '../../components/Booking';

import CreateModal from '../../components/Modals/Organizations/Create';
import EditModal from '../../components/Modals/Organizations/Edit';
import DeleteModal from '../../components/Modals/Organizations/Delete';

import CreateModalResume from '../../components/Modals/Resume/Create';
import EditModalResume from '../../components/Modals/Resume/Edit';
import DeleteModalResume from '../../components/Modals/Resume/Delete';

import useAuth from '../../hooks/useAuth';

import logo from '../../assets/img/logo.jpg';

import EditRecruiting from '../../components/Modals/Requests/EditRecruiting';
import AlertMessage from '../../components/AlertMessage';

import dogovor from '../../assets/docs/Dogovor_po_mobilizacii_ShABLON.docx';
import dogovorCP from '../../assets/docs/DOGOVOR-O-CELEVOM.docx';

import './Profile.scss';

import STATUS from '../../statusRequest';
import UserResumes from './Resumes/Users';
import EmployerResumes from './Resumes/Employer';

const REQUEST_FROM = {
  CONSTRUCTOR: 'КОНСТРУКТОР ПРОГРАММ',
  PROGRAM: 'ЗАПИСЬ НА ПРОГРАММУ',
  FORM: 'ЗАЯВКА НА ОБУЧЕНИЕ',
};

const Profile = () => {
  const dispatch = useDispatch();
  const { data, isUser, isAuth, isAdmin, isEmployer, isManager } = useAuth();

  const [createResume, { isError: isErrorCreateResume }] = useCreateResumeMutation();
  const [updateResume, { isError: isErrorUpdateResume }] = useUpdateResumeMutation();
  const [deleteResume, { isError: isErrorDeleteResume }] = useDeleteResumeMutation();

  const { data: requests, isLoadingRequests } = useGetUserRequestsQuery();
  const { data: groups } = useGetUserGroupsQuery();
  const { data: booking } = useGetBookingByUserQuery({ size: 20, page: 0 });
  const { data: events } = useGetUserEventsQuery();

  const organizationsQuery = {
    role: isEmployer ? 'employer' : 'user',
    page: '0',
    size: 18,
  };

  const employeeRequestsQuery = {
    role: isAdmin ? 'admin' : isEmployer ? 'employer' : 'user',
    page: 0,
  };

  const { data: employeeRequests } = useGetEmployeeRequestsQuery(employeeRequestsQuery);
  const [updateRequest, { isError: isErrorUpdateRequest }] = useUpdateEmployeeRequestsMutation();

  const { data: organizations, isLoading } = useGetOrganizationsQuery(organizationsQuery);
  const [createOrganization, { isError: isErrorCreate }] = useCreateOrganizationMutation();
  const [updateOrganization, { isError: isErrorUpdate }] = useUpdateOrganizationMutation();
  const [deleteOrganization, { isError: isErrorDelete }] = useDeleteOrganizationMutation();

  const validationSchemaProfile = yup.object().shape({
    email: yup.string().required('Обязательное поле!').min(6, 'Минимальная длина 6'),
    // password: yup.string().required('Обязательное поле!').min(6, 'Минимальная длина 6'),
  });

  const initialValuesProfile = {
    id: data.user ? data.user.id : data.id,
    lastName: data.user ? data.user.lastName : data.lastName ?? '',
    firstName: data.user ? data.user.firstName : data.firstName ?? '',
    secondName: data.user ? data.user.secondName : data.secondName ?? '',
    email: data.user ? data.user.email : data.email ?? '',
    phone: data.user ? data.user.phone : data.phone ?? '',
    city: data.user ? data.user.city : data.city ?? '',
    region: data.user ? data.user.region : data.region ?? '',
    type: data.user
      ? data.user.type
        ? { id: data.user.type.id }
        : null
      : data.type
      ? { id: data.type.id }
      : null,
    role: data.user ? { id: data.user.role.id } : data.role ? { id: data.role.id } : null,
    organization: data.user
      ? data.user.organization
        ? { id: data.user.organization.id }
        : null
      : data.organization
      ? { id: data.organization }
      : null,
    birthdate: data.user
      ? `${data.user.birthdate[0]}-${
          data.user.birthdate[1] < 10 ? '0' + data.user.birthdate[1] : data.user.birthdate[1]
        }-${data.user.birthdate[2] < 10 ? '0' + data.user.birthdate[2] : data.user.birthdate[2]}`
      : `${data.birthdate[0]}-${
          data.birthdate[1] < 10 ? '0' + data.birthdate[1] : data.birthdate[1]
        }-${data.birthdate[2] < 10 ? '0' + data.birthdate[2] : data.birthdate[2]}` ?? '',
    sex: data.user ? data.user.sex : data.sex,
  };

  const formikProfile = useFormik({
    initialValues: initialValuesProfile,
    validationSchema: validationSchemaProfile,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(fetchUserUpdate(JSON.stringify(values)));
    },
  });

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [organization, setOrganization] = React.useState({});

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createOrganization({ role: 'employer', body: values })
      .unwrap()
      .then((response) => {
        setMessage(`Организация "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить организацию. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setOrganization(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateOrganization({
      role: isAdmin ? 'admin' : isEmployer ? 'employer' : 'manager',
      body: { id, ...values },
    })
      .unwrap()
      .then((response) => {
        setMessage(`Организация "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить организацию.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setOrganization(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить организацию` /*${
        organizations.content.find((x) => x.id === obj.id).name
      }*/ + `?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteOrganization({ role: isAdmin ? 'admin' : isEmployer ? 'employer' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Организация "${
            organizations.content.find((x) => x.id === response).name
          }" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить организацию.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  const [showEditRequest, setShowEditRequest] = React.useState(false);
  const [request, setRequest] = React.useState({});

  const modalCloseEditRequest = () => setShowEditRequest(false);
  const modalShowEditRequest = (obj) => {
    setRequest(obj);
    setShowEditRequest(true);
  };
  const submitEditRequest = (id, values) => {
    values.organization = { id: values.organization };

    updateRequest({ role: isAdmin ? 'admin' : 'employer', id, body: { id, ...values } })
      .unwrap()
      .then((response) => {
        setMessage(`Заявка на подбор персонала успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось изменить заявку на подбор персонала.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEditRequest();
  };

  // Добавление, редактирование и удаление резюме
  const [resume, setResume] = React.useState({});
  const [showCreateResume, setShowCreateResume] = React.useState(false);
  const [showEditResume, setShowEditResume] = React.useState(false);
  const [showDeleteResume, setShowDeleteResume] = React.useState(false);

  const modalCloseCreateResume = () => setShowCreateResume(false);
  const modalShowCreateResume = () => setShowCreateResume(true);
  const submitCreateResume = (values) => {
    createResume({ ...values, status: 'ACTIVE' })
      .unwrap()
      .then((response) => {
        setMessage(`Резюме успешно добавлено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить резюме. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreateResume();
  };

  const modalCloseEditResume = () => setShowEditResume(false);
  const modalShowEditResume = (obj) => {
    setResume(obj);
    setShowEditResume(true);
  };
  const submitEditResume = (id, values) => {
    updateResume({ id, ...values, status: 'ACTIVE' })
      .unwrap()
      .then((response) => {
        setMessage(`Резюме успешно изменено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить резюме.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEditResume();
  };

  const modalCloseDeleteResume = () => setShowDeleteResume(false);
  const modalShowDeleteResume = (obj) => {
    setResume(obj);
    setDeleteMessage('Вы уверены, что хотите удалить резюме?');
    setShowDeleteResume(true);
  };
  const submitDeleteResume = (id) => {
    deleteResume(id)
      .unwrap()
      .then((response) => {
        setMessage('Резюме успешно удалено.');
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить резюме.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDeleteResume();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Профиль';
  }, []);

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  const onLogout = () => {
    dispatch(logout());
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
  };

  return (
    <>
      {isEmployer && (
        <>
          <CreateModal
            submitCreate={submitCreate}
            showCreate={showCreate}
            modalCloseCreate={modalCloseCreate}
          />

          <EditModal
            organization={organization}
            showEdit={showEdit}
            submitEdit={submitEdit}
            modalCloseEdit={modalCloseEdit}
          />

          <DeleteModal
            organization={organization}
            showDelete={showDelete}
            modalCloseDelete={modalCloseDelete}
            submitDelete={submitDelete}
            deleteMessage={deleteMessage}
          />

          <EditRecruiting
            request={request}
            showEdit={showEditRequest}
            submitEdit={submitEditRequest}
            modalCloseEdit={modalCloseEditRequest}
          />
        </>
      )}

      {isUser && (
        <>
          <CreateModalResume
            data={data}
            submitCreate={submitCreateResume}
            showCreate={showCreateResume}
            modalCloseCreate={modalCloseCreateResume}
          />

          <EditModalResume
            resume={resume}
            showEdit={showEditResume}
            submitEdit={submitEditResume}
            modalCloseEdit={modalCloseEditResume}
          />

          <DeleteModalResume
            resume={resume}
            showDelete={showDeleteResume}
            modalCloseDelete={modalCloseDeleteResume}
            submitDelete={submitDeleteResume}
            deleteMessage={deleteMessage}
          />
        </>
      )}

      <section className="section section-profile my-5">
        <div className="container-fluid gx-3">
          {(isEmployer || isManager) && (
            <>
              <a href={dogovor} download className="d-block text-center mb-4">
                Договор о трудоустройстве обучающихся на вакансию в период частичной мобилизации по
                индивидуальному учебному плану
              </a>

              <a href={dogovorCP} download className="d-block text-center mb-4">
                Договор о целевом обучении по образовательной программе
              </a>
            </>
          )}

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={
                isErrorUpdateRequest ||
                isErrorCreateResume ||
                isErrorUpdateResume ||
                isErrorDeleteResume
              }
            />
          )}

          <Tab.Container id="left-tabs" defaultActiveKey="profile">
            <Row className="gy-4 gy-xl-0">
              <Col className="col-12 col-xl-3 col-xxl-2">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="profile" role="button">
                      <FaUserCircle className="me-2" size="1.5em" /> Личные данные
                    </Nav.Link>
                  </Nav.Item>

                  {isUser && (
                    <Nav.Item>
                      <Nav.Link eventKey="resume" role="button">
                        <FaFile className="me-2" size="1.5em" /> Резюме
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  {isEmployer && (
                    <Nav.Item>
                      <Nav.Link eventKey="organization" role="button">
                        <FaBuilding className="me-2" size="1.5em" /> Организация
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  <Nav.Item>
                    <Nav.Link eventKey="requests" role="button">
                      <FaListUl className="me-2" size="1.5em" /> Заявки
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="groups" role="button">
                      <FaAddressBook className="me-2" size="1.5em" /> Учебные группы
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="booking" role="button">
                      <FaHistory className="me-2" size="1.5em" /> Бронирования
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="events" role="button">
                      <FaInfoCircle className="me-2" size="1.5em" /> Мероприятия
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link role="button" onClick={() => console.log('reset')}>
                      <FaRedo className="me-2" size="1.5em" /> Сбросить пароль
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link role="button" onClick={onLogout} style={{ color: '#dc3545' }}>
                      <FaSignOutAlt className="me-2" size="1.5em" /> Выйти
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="profile">
                    <div className="profile">
                      <Form className="auth-form" onSubmit={formikProfile.handleSubmit} noValidate>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="profileLastname">
                            <Form.Label>Фамилия</Form.Label>
                            <Form.Control
                              type="lastName"
                              name="lastName"
                              value={formikProfile.values.lastName}
                              onChange={formikProfile.handleChange}
                              isValid={
                                formikProfile.touched.lastName && !formikProfile.errors.lastName
                              }
                              isInvalid={formikProfile.errors.lastName}
                            />

                            <Form.Control.Feedback type="invalid">
                              {formikProfile.errors.lastName}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} controlId="profileFirstname">
                            <Form.Label>Имя</Form.Label>
                            <Form.Control
                              type="firstName"
                              name="firstName"
                              value={formikProfile.values.firstName}
                              onChange={formikProfile.handleChange}
                              isValid={
                                formikProfile.touched.firstName && !formikProfile.errors.firstName
                              }
                              isInvalid={formikProfile.errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikProfile.errors.firstName}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} controlId="profileSecondname">
                            <Form.Label>Отчество</Form.Label>
                            <Form.Control
                              type="secondName"
                              name="secondName"
                              value={formikProfile.values.secondName}
                              onChange={formikProfile.handleChange}
                              isValid={
                                formikProfile.touched.secondName && !formikProfile.errors.secondName
                              }
                              isInvalid={formikProfile.errors.secondName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikProfile.errors.secondName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="profileEmail">
                            <Form.Label>EMail</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={formikProfile.values.email}
                              onChange={formikProfile.handleChange}
                              isValid={formikProfile.touched.email && !formikProfile.errors.email}
                              isInvalid={formikProfile.errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikProfile.errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} controlId="profilePhone">
                            <Form.Label>Телефон</Form.Label>
                            <Form.Control
                              type="tel"
                              name="phone"
                              value={formikProfile.values.phone}
                              onChange={formikProfile.handleChange}
                              isValid={formikProfile.touched.phone && !formikProfile.errors.phone}
                              isInvalid={formikProfile.errors.phone}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikProfile.errors.phone}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} controlId="profileBirthdate">
                            <Form.Label>Дата рождения</Form.Label>
                            <Form.Control
                              type="date"
                              name="birthdate"
                              value={formikProfile.values.birthdate}
                              onChange={formikProfile.handleChange}
                              isValid={
                                formikProfile.touched.birthdate && !formikProfile.errors.birthdate
                              }
                              isInvalid={formikProfile.errors.birthdate}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikProfile.errors.birthdate}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="profileBirthdate">
                            <Form.Label>Город</Form.Label>
                            <Form.Control
                              name="city"
                              value={formikProfile.values.city}
                              onChange={formikProfile.handleChange}
                              isValid={formikProfile.touched.city && !formikProfile.errors.city}
                              isInvalid={formikProfile.errors.city}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikProfile.errors.city}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} controlId="profileBirthdate">
                            <Form.Label>Муниципальный район</Form.Label>
                            <Form.Control
                              name="region"
                              value={formikProfile.values.region}
                              onChange={formikProfile.handleChange}
                              isValid={formikProfile.touched.region && !formikProfile.errors.region}
                              isInvalid={formikProfile.errors.region}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formikProfile.errors.region}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Button variant="outline-success" type="submit" className="w-100 mb-3">
                          Сохранить изменения
                        </Button>
                      </Form>
                    </div>
                  </Tab.Pane>

                  {isUser && (
                    <Tab.Pane eventKey="resume">
                      <UserResumes
                        modalShowEditResume={modalShowEditResume}
                        modalShowDeleteResume={modalShowDeleteResume}
                      />

                      <button
                        className="btn btn-outline-primary w-100"
                        onClick={modalShowCreateResume}>
                        Добавить резюме
                      </button>
                    </Tab.Pane>
                  )}

                  {isEmployer && (
                    <Tab.Pane eventKey="resumes">
                      <EmployerResumes />
                    </Tab.Pane>
                  )}

                  <Tab.Pane eventKey="organization">
                    {isEmployer && organizations?.content?.length === 0 && (
                      <Button
                        variant="outline-primary"
                        className="w-100 mb-4"
                        onClick={modalShowCreate}>
                        Добавить организацию
                      </Button>
                    )}

                    <div className="organizations">
                      {organizations?.content?.map((obj) => (
                        <div className="organization-item" key={obj.id}>
                          <div className="organization-name">{obj.name}</div>
                          <div className="organization-phone">{obj.phone}</div>
                          <div className="organization-address mb-3">
                            {obj.city && obj.city.startsWith('г.')
                              ? `${obj?.city}, `
                              : `г. ${obj?.city}, `}
                            {obj.address}
                            {obj?.region ? `, ${obj.region}` : ''}
                          </div>

                          <div className="organization-btns">
                            <Button variant="outline-primary" onClick={() => modalShowEdit(obj)}>
                              <FaEdit /> Редактировать
                            </Button>

                            <Button variant="outline-danger" onClick={() => modalShowDelete(obj)}>
                              <FaTrashAlt /> Удалить
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="requests">
                    <div className="requests">
                      {requests?.content
                        ?.slice()
                        .concat(employeeRequests?.content ?? [])
                        .map((x) => (
                          <div className="request-item" key={x.id}>
                            <div className="request-item__from">
                              {x.requestFrom
                                ? `Откуда: ${REQUEST_FROM[x.requestFrom]}`
                                : 'Заявка на подбор персонала'}
                            </div>

                            {!x.requestFrom && (
                              <div className="request-item__position">Должность: {x.position}</div>
                            )}

                            <div className="request-item__status">
                              Статус: {STATUS[x.requestStatus]}
                            </div>

                            <div className="request-item__date">
                              Дата: {new Date(x?.created).toLocaleDateString()}
                            </div>

                            {!x.requestFrom && (
                              <div className="request-item__btns">
                                <Button
                                  variant="outline-primary"
                                  className="btn btn-outline-primary w-100"
                                  onClick={() => modalShowEditRequest(x)}>
                                  Редактировать
                                </Button>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="groups">
                    <div className="groups">
                      {groups?.map((x) => (
                        <div className="group-item" key={x.id}>
                          <div className="group-item__name">{x.name}</div>

                          {/* <img
                          src={process.env.REACT_APP_API_URL + x.program.image.src}
                          alt={x.program.name}
                        /> */}

                          <Link to={`/programs/${x.program.id}`} className="group-item__program">
                            {x.program.name}
                          </Link>

                          <div className="group-item__date">
                            Обучения с {x.startDate.split('-').reverse().join('.')} до{' '}
                            {x.endDate.split('-').reverse().join('.')}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="booking">
                    <div className="booking">
                      {booking?.content?.map((obj) => (
                        <BookingItem key={obj.id} {...obj} isProfile={true} />
                      ))}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="events">
                    <div className="events profile">
                      {events?.map((x) => (
                        <div className="event-item" key={x.id}>
                          <div className="event-img">
                            <img
                              src={
                                x?.image.src ? process.env.REACT_APP_API_URL + x.image.src : logo
                              }
                              alt={x.name}
                            />
                          </div>

                          <div className="event-content">
                            <Link className="event-title" to={`/events/${x.id}`}>
                              {x.name}
                            </Link>

                            <div className="event-date">
                              <FaRegCalendarAlt /> Дата проведения:{' '}
                              {new Date(x.begin).toLocaleString().slice(0, -3)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default Profile;
