import React from 'react';
import { Form, Modal } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditModalStats = ({ data, showEdit, submitEdit, modalCloseEdit }) => {
  const { id, number, text } = data;

  const initialValues = {
    number: number || '',
    text: text || '',
  };

  const validationSchema = Yup.object({
    number: Yup.string().required('Обязательно поле!'),
    text: Yup.string().required('Обязательно поле!'),
  });

  let formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование показателя</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group controlId="statsNumber" className="mb-3">
            <Form.Label>Показатель (число)</Form.Label>
            <Form.Control
              name="number"
              onChange={formik.handleChange}
              value={formik.values.number}
              isValid={formik.touched.number && !formik.errors.number}
              isInvalid={!!formik.errors.number}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.number}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="statsText" className="mb-3">
            <Form.Label>Текст</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.text}
              isValid={formik.touched.text && !formik.errors.text}
              isInvalid={!!formik.errors.text}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.text}</Form.Control.Feedback>
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModalStats;
