import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';

import { FormikSelect } from '../FormikSelect';

const FilterEvents = ({ filterIsOpen, setFilterIsOpen, types, targets }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onFilterClose = () => setFilterIsOpen(false);

  const initialValues = {
    name: '',
    type: -1,
    targetGroup: -1,
    date: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      [...searchParams.keys()].map((x) => {
        searchParams.delete(x);
      });
      setSearchParams(searchParams);

      if (values.name) {
        searchParams.set('name', values.name);
        setSearchParams(searchParams);
      }

      if (values.type !== -1) {
        searchParams.set('type', values.type);
        setSearchParams(searchParams);
      }

      if (values.targetGroup !== -1) {
        searchParams.set('targetGroup', values.targetGroup);
        setSearchParams(searchParams);
      }

      onFilterClose();
    },
  });

  return (
    <>
      <Modal show={filterIsOpen} onHide={onFilterClose} centered={true} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Фильтр</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit} noValidate>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label className="mb-1">Название мероприятия</Form.Label>
              <Form.Control name="name" value={formik.values.name} onChange={formik.handleChange} />
            </Form.Group>

            <Row>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label className="mb-1">Форма проведения</Form.Label>

                <FormikSelect
                  name="type"
                  value={formik.values.type}
                  form={formik}
                  options={types.map((x) => ({
                    value: x.id,
                    label: x.name,
                  }))}
                  placeholder="---------"
                />
              </Form.Group>

              <Form.Group className="mb-3" as={Col}>
                <Form.Label className="mb-1">Целевая аудитория</Form.Label>

                <FormikSelect
                  name="targetGroup"
                  value={formik.values.targetGroup}
                  form={formik}
                  options={targets.map((x) => ({
                    value: x.id,
                    label: x.name,
                  }))}
                  placeholder="---------"
                />
              </Form.Group>
            </Row>

            {/* <Form.Group className="mb-3" as={Col}>
              <Form.Label className="mb-1">Дата проведения</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-success" onClick={() => formik.submitForm()} className="col">
            Фильтровать
          </Button>

          <Button variant="outline-secondary" onClick={onFilterClose} className="col">
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterEvents;
