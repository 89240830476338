import React from 'react';

import { useGetResumesQuery } from '../../../services/users';
import { Link } from 'react-router-dom';

const UserResumes = ({ modalShowEditResume, modalShowDeleteResume }) => {
  const { data: resumes, isLoadingResumes } = useGetResumesQuery();

  return (
    <>
      {!isLoadingResumes && (
        <div className="resumes mb-3">
          {resumes?.content?.map((resume) => (
            <div className="resumes-item" key={resume.id}>
              <div className="resumes-item__position">
                Резюме{' '}
                {resume?.educations
                  .map((education) => education.name + ' / ' + education.organization)
                  .join(', ')}
              </div>

              <div className="resumes-item__btns">
                {/* <Link className="btn btn-outline-primary" to={`/resumes/${x.id}/edit`}>
                  Редактировать
                </Link> */}
                <button
                  className="btn btn-outline-primary"
                  onClick={() => modalShowEditResume(resume)}>
                  Редактировать
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => modalShowDeleteResume(resume)}>
                  Удалить
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default UserResumes;
