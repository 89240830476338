import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const rolesAPI = createApi({
  reducerPath: 'rolesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Roles'],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: (role) => `${role}/roles/`,
      providesTags: () => ['Roles'],
    }),

    createRole: builder.mutation({
      query: (body) => ({
        url: 'admin/roles/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Roles'],
    }),

    updateRole: builder.mutation({
      query: (body) => ({
        url: `admin/roles/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Roles'],
    }),

    deleteRole: builder.mutation({
      query: (id) => ({
        url: `admin/roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Roles'],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = rolesAPI;
