import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const roomsAPI = createApi({
  reducerPath: 'roomsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Rooms'],
  endpoints: (builder) => ({
    getRooms: builder.query({
      query: ({ size = 20, page = 0, role }) => `${role}/rooms/?size=${size}&page=${page}`,
      providesTags: () => ['Rooms'],
    }),

    createRoom: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/rooms/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Rooms'],
    }),

    updateRoom: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/rooms/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Rooms'],
    }),

    deleteRoom: builder.mutation({
      query: ({ role, id }) => ({
        url: `${role}/rooms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Rooms'],
    }),
  }),
});

export const {
  useGetRoomsQuery,
  useCreateRoomMutation,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
} = roomsAPI;
