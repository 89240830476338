import React from 'react';

import { useSignUpEventMutation } from '../services/events';

const EventSignUp = ({ event, userId, isUser }) => {
  const [signUpEvent, { isError: isErrorSignUp }] = useSignUpEventMutation();

  const handleSignUpEvent = () => {
    signUpEvent(event)
      .unwrap()
      .then(() => {
        //setMessage('Вы успешно записались на мероприятие.');
      })
      .catch((err) => {
        console.error(err);
        //setMessage('При записи произошла ошибка, пожалуйста, попробуйте позже.');
      });
  };

  if (isUser) {
    if (event?.listeners?.find((x) => x?.id === userId)) {
      return (
        <button className="event-btn w-100 btn btn-outline-primary">
          Вы записаны на данное мероприятие
        </button>
      );
    }

    return (
      <button className="event-btn w-100 btn btn-outline-primary" onClick={handleSignUpEvent}>
        Принять участие
      </button>
    );
  }

  return null;
};

export default EventSignUp;
