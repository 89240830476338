import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const vacancyAPI = createApi({
  reducerPath: 'vacancyAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Vacancy'],
  endpoints: (builder) => ({
    getVacancy: builder.query({
      query: ({ role = 'user', size = 5, page = 0 }) =>
        role === 'employer'
          ? `employer/vacancy/?size=${size}&page=${page}`
          : `/vacancy/?size=${size}&page=${page}`,
      providesTags: () => ['Vacancy'],
    }),
    getVacancyById: builder.query({
      query: (id) => `vacancy/${id}`,
      providesTags: () => ['Vacancy'],
    }),

    createVacancy: builder.mutation({
      query: (body) => ({
        url: 'employer/vacancy',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Vacancy'],
    }),
    updateVacancy: builder.mutation({
      query: (body) => ({
        url: `employer/vacancy`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Vacancy'],
    }),
    deleteVacancy: builder.mutation({
      query: (id) => ({
        url: `employer/vacancy/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Vacancy'],
    }),
  }),
});

export const {
  useGetVacancyQuery,
  useGetVacancyByIdQuery,
  useCreateVacancyMutation,
  useUpdateVacancyMutation,
  useDeleteVacancyMutation,
} = vacancyAPI;
