import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormikSelect } from '../../FormikSelect';
import { fileResizeAndUpload } from '../../../fileResizeAndUpload';

const EditModal = ({
  program,
  isAdmin,
  types,
  targets,
  modules,
  subspecies,
  organizations,
  setImage,
  showEdit,
  submitEdit,
  modalCloseEdit,
}) => {
  const { id, name, description, type, target, hours, organization } = program;

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    await fileResizeAndUpload(file, setImage);
  };

  const initialValues = {
    name: name || '',
    description: description || '',
    type: type ? type.id : -1 || -1,
    subspecies: program.subspecies ? program.subspecies.id : -1 || -1,
    target: target ? target.id : -1 || -1,
    hours: hours || 1,
    organization: organization ? (organization.id ? organization.id : organization) : -1 || -1,
    modules: program.modules ? program.modules.map((x) => (x.id ? x.id : x)) : [] || [],
    available: program.available || false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле'),
    description: Yup.string().required('Обязательно поле'),
    type: Yup.number().moreThan(-1, 'Выберите вид программы').required('Обязательно поле'),
    subspecies: Yup.number().moreThan(-1, 'Выберите подвид программы').required('Обязательно поле'),
    target: Yup.number().moreThan(-1, 'Выберите целевую аудиторию').required('Обязательно поле'),
    hours: Yup.number().required('Обязательно поле'),
    //organization: isAdmin ? Yup.number().moreThan(-1, 'Выберите организацию') : Yup.number(),
    organization: Yup.number().moreThan(-1, 'Выберите организацию'),
    available: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      submitEdit(id, values);
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование программы</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="programmName" className="mb-3">
              <Form.Label>Название программы *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="programmDescription" className="mb-3">
              <Form.Label>Аннотация *</Form.Label>
              <Form.Control
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                isValid={formik.touched.description && !formik.errors.description}
                isInvalid={!!formik.errors.description}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="programmType" className="mb-3">
              <Form.Label>Вид программы *</Form.Label>

              <Form.Select
                name="type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type}
                isValid={formik.touched.type && !formik.errors.type}
                isInvalid={!!formik.errors.type}>
                <option value="-1">Выберите вид программы</option>
                {types?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.type}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="programmPhone" className="mb-3">
              <Form.Label>Подвид программы</Form.Label>

              <Form.Select
                name="subspecies"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subspecies}
                isValid={formik.touched.subspecies && !formik.errors.subspecies}
                isInvalid={!!formik.errors.subspecies}>
                <option value="-1">Выберите подвид программы</option>
                {subspecies?.map((sub) => (
                  <option key={sub.id} value={sub.id}>
                    {sub.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {formik.errors.subspecies}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="programmTarget" className="mb-3">
              <Form.Label>Целевая аудитория *</Form.Label>

              <Form.Select
                name="target"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.target}
                isValid={formik.touched.target && !formik.errors.target}
                isInvalid={!!formik.errors.target}>
                <option value="-1">Выберите целевую аудиторию</option>
                {targets?.map((target) => (
                  <option key={target.id} value={target.id}>
                    {target.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.target}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="programmHours" className="mb-3">
              <Form.Label>Количество часов *</Form.Label>
              <Form.Control
                type="number"
                min="1"
                name="hours"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.hours}
                isValid={formik.touched.hours && !formik.errors.hours}
                isInvalid={!!formik.errors.hours}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.hours}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          {isAdmin && (
            <Form.Group as={Col} controlId="programmOrganization" className="mb-3">
              <Form.Label>Организация *</Form.Label>

              <FormikSelect
                name="organization"
                value={formik.values.organization}
                form={formik}
                options={organizations?.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                placeholder="Выберите организацию"
                isError={formik.errors.organization}
              />

              {formik.errors.organization && (
                <div className="invalid-feedback d-block">{formik.errors.organization}</div>
              )}
            </Form.Group>
          )}

          <Form.Group as={Col} controlId="programmModules" className="mb-3">
            <Form.Label>Модули</Form.Label>

            <FormikSelect
              name="modules"
              value={formik.values.modules}
              form={formik}
              options={modules?.map((module) => ({
                value: module.id,
                label: module.name,
              }))}
              isMulti={true}
              placeholder="Выберите модули"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              id="available"
              required
              name="available"
              label='Отображать в разделе "Программы"'
              onChange={formik.handleChange}
              value={formik.values.available}
              checked={formik.values.available}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="programmImage" className="mb-3">
            <Form.Label>Изображение</Form.Label>
            <Form.Control type="file" name="image" onChange={handleFileUpload} />
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
