import React from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';

import { FormikSelect } from '../FormikSelect';

const FilterPrograms = ({ filterIsOpen, setFilterIsOpen, types, subspecies, targets }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onFilterClose = () => setFilterIsOpen(false);

  const initialValues = {
    name: '',
    type: -1,
    subspecies: -1,
    target: -1,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      [...searchParams.keys()].map((x) => {
        searchParams.delete(x);
      });
      setSearchParams(searchParams);

      if (values.name) {
        searchParams.append('name', values.name);
        setSearchParams(searchParams);
      }

      if (values.type !== -1) {
        searchParams.append('type', values.type);
        setSearchParams(searchParams);
      }

      if (values.subspecies !== -1) {
        searchParams.append('subspecies', values.subspecies);
        setSearchParams(searchParams);
      }

      if (values.target !== -1) {
        searchParams.append('target', values.target);
        setSearchParams(searchParams);
      }
      //submitCreate(values);
      //resetForm({});
      onFilterClose();
    },
  });

  return (
    <>
      <Modal show={filterIsOpen} onHide={onFilterClose} centered={true} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Фильтр</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit} noValidate>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label className="mb-1">Название программы</Form.Label>
              <Form.Control name="name" value={formik.values.name} onChange={formik.handleChange} />
            </Form.Group>

            <Form.Group className="mb-3" as={Col}>
              <Form.Label className="mb-1">Вид программы</Form.Label>

              <FormikSelect
                name="type"
                value={formik.values.type}
                form={formik}
                options={types.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                placeholder="---------"
              />
            </Form.Group>

            <Form.Group className="mb-3" as={Col}>
              <Form.Label className="mb-1">Подвид программы</Form.Label>

              <FormikSelect
                name="subspecies"
                value={formik.values.subspecies}
                form={formik}
                options={subspecies.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                placeholder="---------"
              />
            </Form.Group>

            <Form.Group className="mb-3" as={Col}>
              <Form.Label className="mb-1">Целевая аудитория</Form.Label>

              <FormikSelect
                name="target"
                value={formik.values.target}
                form={formik}
                options={targets.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                placeholder="---------"
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-success" onClick={() => formik.submitForm()} className="col">
            Фильтровать
          </Button>

          <Button variant="outline-secondary" onClick={onFilterClose} className="col">
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterPrograms;
