import { FaChevronRight, FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import './SuccessStoryItem.scss';

const SuccessStoryItem = ({ obj, modalShowEdit, modalShowDelete, isAdmin = false }) => {
  const { id, name, image, text } = obj;
  return (
    <div className="success-story__item">
      {image && (
        <div className="success-story__img">
          <img src={process.env.REACT_APP_API_URL + image.src} alt={name} />
        </div>
      )}

      <div className="success-story__content">
        <h4 className="success-story__title">{name}</h4>
        <p className="success-story__text">{text.slice(0, 500) + '...'}</p>

        <Link to={`/success-story/${id}`} className="success-story__btn mt-3 text-center">
          Подробнее <FaChevronRight className="ms-1" size="0.75em" />
        </Link>
      </div>

      {isAdmin && (
        <div className="success-story__btns d-flex align-items">
          <Link to={`/success-story/${id}`} className="btn btn-outline-success w-100">
            <FaEye size="3em" />
          </Link>

          <button className="btn btn-outline-primary w-100" onClick={() => modalShowEdit(obj)}>
            <FaEdit size="3em" />
          </button>

          <button className="btn btn-outline-danger w-100" onClick={() => modalShowDelete(obj)}>
            <FaTrashAlt size="3em" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SuccessStoryItem;
