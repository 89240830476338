import React from 'react';
import { useParams } from 'react-router-dom';

import { Button, Col, Form, InputGroup, Row, Spinner, Table } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dateFormat } from '../../../dateFormat';

import { useGetResumeByIdQuery } from '../../../services/users';

import NotFound from '../../NotFound/index';

const EditResume = (submitEdit) => {
  const { id } = useParams();
  const { data: resume, isLoading } = useGetResumeByIdQuery(id);

  const [perks, setPerks] = React.useState(resume?.perks);
  const [softSkills, setSoftSkills] = React.useState(resume?.softSkills);
  const [independenceScore, setIndependenceScore] = React.useState(resume?.independenceScore);
  const [educations, setEducations] = React.useState(resume?.educations);
  const [additionalEducations, setAdditionalEducations] = React.useState(
    resume?.additionalEducations,
  );

  const [perk, setPerk] = React.useState('');
  const [softSkill, setSoftSkill] = React.useState('');
  const [independence, setIndependence] = React.useState('');

  React.useEffect(() => {
    setPerks(resume?.perks);
    setSoftSkills(resume?.softSkills);
    setIndependenceScore(resume?.independenceScore);
    setEducations(resume?.educations);
    setAdditionalEducations(resume?.additionalEducations);
  }, [
    resume?.perks,
    resume?.softSkills,
    resume?.independenceScore,
    resume?.educations,
    resume?.additionalEducations,
  ]);

  const educationEmpty = {
    name: '',
    form: '',
    endDate: '',
    organization: '',
    city: '',
  };
  const [education, setEducation] = React.useState(educationEmpty);

  const additionalEducationEmpty = {
    name: '',
    endDate: '',
    organization: '',
    city: '',
  };
  const [additionalEducation, setAdditionalEducation] = React.useState(additionalEducationEmpty);

  const initialValues = {
    lastName: resume?.lastName || '',
    firstName: resume?.firstName || '',
    secondName: resume?.secondName || '',
    birthdate: dateFormat(resume?.birthdate, 4) || '',
    city: resume?.city || '',
    educationLevel: resume?.educationLevel || '',
    email: resume?.email || '',
    phone: resume?.phone || '',
    softSkills: softSkills || [],
    perks: perks || [],
    independenceScore: independenceScore || [],
    educations: educations || [],
    additionalEducations: additionalEducations || [],
  };

  const validationSchema = Yup.object({
    lastName: Yup.string().required('Обязательное поле'),
    firstName: Yup.string().required('Обязательное поле'),
    birthdate: Yup.date().required('Обязательное поле'),
    city: Yup.string().required('Обязательное поле'),
    educationLevel: Yup.string().required('Обязательное поле'),
    email: Yup.string().email().required('Обязательное поле'),
    phone: Yup.string().required('Обязательное поле'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, {
        ...values,
        perks,
        softSkills,
        independenceScore,
        educations,
        additionalEducations,
      });
      resetForm({});
    },
  });

  if (resume === null) {
    return <NotFound />;
  }

  if (isLoading) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <Spinner animation="border" style={{ width: '4em', height: '4em' }} />
      </div>
    );
  }

  return (
    <div className="container flex-grow-1">
      <h1 className="my-4">Редактирование резюме</h1>

      <Form onSubmit={formik.handleSubmit} noValidate className="event-form">
        <Row className="mb-3">
          <Form.Group as={Col} controlId="resumeLastname">
            <Form.Label>
              Фамилия <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              isValid={formik.touched.lastName && !formik.errors.lastName}
              isInvalid={formik.errors.lastName}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="resumeFirstname">
            <Form.Label>
              Имя <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              isValid={formik.touched.firstName && !formik.errors.firstName}
              isInvalid={formik.errors.firstName}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="resumeBirthdate">
            <Form.Label>
              Дата рождения <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="date"
              name="birthdate"
              value={formik.values.birthdate}
              onChange={formik.handleChange}
              isValid={formik.touched.birthdate && !formik.errors.birthdate}
              isInvalid={formik.errors.birthdate}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.birthdate}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="resumeEmail">
            <Form.Label>EMail</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="profilePhone">
            <Form.Label>Телефон</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              isValid={formik.touched.phone && !formik.errors.phone}
              isInvalid={formik.errors.phone}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="resumeCity">
            <Form.Label>Город проживания</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              isValid={formik.touched.city && !formik.errors.city}
              isInvalid={formik.errors.city}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="resumeEducationLevel">
            <Form.Label>Уровень образования</Form.Label>
            <Form.Control
              type="text"
              name="educationLevel"
              value={formik.values.educationLevel}
              onChange={formik.handleChange}
              isValid={formik.touched.educationLevel && !formik.errors.educationLevel}
              isInvalid={formik.errors.educationLevel}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.educationLevel}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        {/* Perks */}
        <Row className="align-items-center mb-3">
          <Col xs={'auto'}>
            <h5 className="mb-0">Навыки</h5>
          </Col>

          <Col>
            <InputGroup>
              <Form.Control
                value={perk}
                onChange={(e) => setPerk(e.target.value)}
                placeholder="Навык"
                aria-label="Навык"
              />
              <Button
                variant="success"
                onClick={() => {
                  if (perk.trim()) {
                    setPerks([...perks, { text: perk.trim() }]);
                    setPerk('');
                  }
                }}>
                +
              </Button>
            </InputGroup>
          </Col>
        </Row>

        {perks?.length > 0 && (
          <ul>
            {perks.map((x, i) => (
              <li key={i} className="mb-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>{x.text}</div>
                  <Button
                    variant="danger"
                    onClick={() => setPerks(perks.filter((_) => _.text !== x.text))}>
                    x
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        )}

        {/* SoftSkills */}
        <Row className="align-items-center mb-3">
          <Col xs={'auto'}>
            <h5 className="mb-0">Soft Skills</h5>
          </Col>

          <Col>
            <InputGroup>
              <Form.Control
                value={softSkill}
                onChange={(e) => setSoftSkill(e.target.value)}
                placeholder="Soft skill"
                aria-label="Soft skill"
              />
              <Button
                variant="success"
                onClick={() => {
                  if (softSkill.trim()) {
                    setSoftSkills([...softSkills, { text: softSkill.trim() }]);
                    setSoftSkill('');
                  }
                }}>
                +
              </Button>
            </InputGroup>
          </Col>
        </Row>

        {softSkills?.length > 0 && (
          <ul>
            {softSkills.map((x, i) => (
              <li key={i} className="mb-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>{x.text}</div>
                  <Button
                    variant="danger"
                    onClick={() => setSoftSkills(softSkills.filter((_) => _.text !== x.text))}>
                    x
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        )}

        {/* Independence Score */}
        <Row className="align-items-center mb-3">
          <Col xs={'auto'}>
            <h5 className="mb-0">Независимая оценка квалификаций</h5>
          </Col>

          <Col>
            <InputGroup>
              <Form.Control
                value={independence}
                onChange={(e) => setIndependence(e.target.value)}
                placeholder="Независимая оценка квалификаций"
                aria-label="Независимая оценка квалификаций"
              />
              <Button
                variant="success"
                onClick={() => {
                  if (independence.trim()) {
                    setIndependenceScore([...independenceScore, { text: independence.trim() }]);
                    setIndependence('');
                  }
                }}>
                +
              </Button>
            </InputGroup>
          </Col>
        </Row>

        {independenceScore?.length > 0 && (
          <ul>
            {independenceScore.map((x, i) => (
              <li key={i} className="mb-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>{x.text}</div>
                  <Button
                    variant="danger"
                    onClick={() =>
                      setIndependenceScore(independenceScore.filter((_) => _.text !== x.text))
                    }>
                    x
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        )}

        <div className="border rounded-1 p-3 mb-3">
          <h5 className="text-center mb-2">Образование</h5>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="resumeEducationName">
              <Form.Label>Специальность</Form.Label>
              <Form.Control
                value={education.name}
                onChange={(e) => setEducation({ ...education, name: e.target.value })}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="resumeEducationForm">
              <Form.Label>Форма обучения</Form.Label>
              <Form.Control
                value={education.form}
                onChange={(e) => setEducation({ ...education, form: e.target.value })}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="resumeEducationEndDate">
              <Form.Label>Дата окончания</Form.Label>
              <Form.Control
                type="date"
                value={education.endDate}
                onChange={(e) => setEducation({ ...education, endDate: e.target.value })}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="resumeEducationOrganization">
              <Form.Label>Учебное заведение</Form.Label>
              <Form.Control
                value={education.organization}
                onChange={(e) => setEducation({ ...education, organization: e.target.value })}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="resumeEducationCity">
              <Form.Label>Город</Form.Label>
              <Form.Control
                value={education.city}
                onChange={(e) => setEducation({ ...education, city: e.target.value })}
              />
            </Form.Group>
          </Row>

          {educations?.length > 0 && (
            <Table bordered responsive className="text-center">
              <thead className="table-secondary">
                <tr>
                  <th>#</th>
                  <th>Специальность</th>
                  <th>Форма обучения</th>
                  <th>Дата окончания</th>
                  <th>Учебное заведение</th>
                  <th>Город</th>
                </tr>
              </thead>
              <tbody>
                {educations?.map((x, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{x.name}</td>
                    <td>{x.form}</td>
                    <td>{new Date(x.endDate).toLocaleDateString()}</td>
                    <td>{x.organization}</td>
                    <td>{x.city}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          <Button
            variant="success"
            onClick={() => {
              if (
                education.name.trim() &&
                education.form.trim() &&
                education.endDate.trim() &&
                education.organization.trim() &&
                education.city.trim()
              ) {
                setEducations([...educations, education]);
                setEducation(educationEmpty);
              }
            }}
            className="w-100">
            Добавить
          </Button>
        </div>

        <div className="border rounded-1 p-3 mb-3">
          <h5 className="text-center mb-2">Дополнительное образование</h5>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="resumeAdditionalCourseForm">
              <Form.Label>Название курса</Form.Label>
              <Form.Control
                value={additionalEducation.name}
                onChange={(e) =>
                  setAdditionalEducation({ ...additionalEducation, name: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="resumeAdditionalCourseEndDate">
              <Form.Label>Дата окончания</Form.Label>
              <Form.Control
                type="date"
                value={additionalEducation.endDate}
                onChange={(e) =>
                  setAdditionalEducation({ ...additionalEducation, endDate: e.target.value })
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="resumeAdditionalCourseOrganization">
              <Form.Label>Учебное заведение</Form.Label>
              <Form.Control
                value={additionalEducation.organization}
                onChange={(e) =>
                  setAdditionalEducation({ ...additionalEducation, organization: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="resumeAdditionalCourseCity">
              <Form.Label>Город</Form.Label>
              <Form.Control
                value={additionalEducation.city}
                onChange={(e) =>
                  setAdditionalEducation({ ...additionalEducation, city: e.target.value })
                }
              />
            </Form.Group>
          </Row>

          {additionalEducations?.length > 0 && (
            <Table bordered responsive className="text-center">
              <thead className="table-secondary">
                <tr className="">
                  <th>#</th>
                  <th>Название курса</th>
                  <th>Дата окончания</th>
                  <th>Учебное заведение</th>
                  <th>Город</th>
                </tr>
              </thead>
              <tbody>
                {additionalEducations?.map((x, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{x.name}</td>
                    <td>{new Date(x.endDate).toLocaleDateString()}</td>
                    <td>{x.organization}</td>
                    <td>{x.city}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          <Button
            variant="success"
            onClick={() => {
              if (
                additionalEducation.name.trim() &&
                additionalEducation.endDate.trim() &&
                additionalEducation.organization.trim() &&
                additionalEducation.city.trim()
              ) {
                setAdditionalEducations([...additionalEducations, additionalEducation]);
                setAdditionalEducation(additionalEducationEmpty);
              }
            }}
            className="w-100">
            Добавить
          </Button>
        </div>

        <button type="submit" className="btn btn-outline-secondary w-100">
          Сохранить
        </button>
      </Form>
    </div>
  );
};

export default EditResume;
