import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FaChartLine, FaEdit } from 'react-icons/fa';

import {
  useGetBookingRequestsQuery,
  useBookingRequestUpdateMutation,
} from '../../../../services/booking';

import PaginationCustom from '../../../../components/PaginationCustom';
import EditBooking from '../../../../components/Modals/Requests/EditBooking';
import AlertMessage from '../../../../components/AlertMessage';

import STATUS from '../../../../statusRequest';
import { dateFormat } from '../../../../dateFormat';

const RequestsBooking = () => {
  const [page, setPage] = React.useState(0);
  const query = {
    size: 20,
    page: page,
  };
  const { data: booking, isLoading } = useGetBookingRequestsQuery(query);
  const [updateRequest, { isError }] = useBookingRequestUpdateMutation();

  const [request, setRequest] = React.useState({});

  const [message, setMessage] = React.useState(null);
  const [showEdit, setShowEdit] = React.useState(false);

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setRequest(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, status) => {
    updateRequest({ id, status })
      .unwrap()
      .then(() => {
        setMessage(`Заявка от пользователя успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось изменить заявку. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
    modalCloseEdit();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Запросы на бронирование';
  }, []);

  return (
    <>
      <EditBooking
        request={request}
        submitEdit={submitEdit}
        showEdit={showEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <section className="section section-requests__booking min-height my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Запросы на бронирование</h1>
            <hr />
          </div>

          <Button
            variant="outline-primary"
            className="mb-4 d-block mx-auto"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_API_URL}/statistics/booking/`)
            }>
            <FaChartLine size="1.1em" /> Выгрузить статистику
          </Button>

          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    <th>Площадка</th>
                    <th>Дата бронирования</th>
                    <th>Продолжительность</th>
                    <th>От кого</th>
                    <th>Телефон</th>
                    <th>EMail</th>
                    <th>Статус</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {booking?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.name}</td>
                      <td>{dateFormat(obj?.date, 2)}</td>
                      <td>{obj?.hours} ч.</td>
                      <td>{obj?.author}</td>
                      <td>{obj?.phone}</td>
                      <td>{obj?.email}</td>
                      <td>{STATUS[obj?.requestStatus]}</td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowEdit(obj)}>
                          <FaEdit size="1.1em" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={booking?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default RequestsBooking;
