import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ size = 20, page = 0, type = 0, name = '', role }) =>
        `${role}/users/?page=${page}&size=${size}${type > 0 ? `&type=${type}` : ''}${
          name ? `&name=${name}` : ''
        }`,
      providesTags: () => ['Users'],
    }),

    createUser: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/users/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Users'],
    }),

    updateUser: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/users/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Users'],
    }),

    deleteUser: builder.mutation({
      query: ({ role, id }) => ({
        url: `${role}/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Users'],
    }),

    getResumes: builder.query({
      query: () => '/user/resume',
      providesTags: () => ['UserResume'],
    }),

    getResumeById: builder.query({
      query: (id) => `/user/resume/${id}`,
      providesTags: () => ['UserResume'],
    }),

    createResume: builder.mutation({
      query: (body) => ({
        url: '/user/resume',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['UserResume'],
    }),
    updateResume: builder.mutation({
      query: (body) => ({
        url: `/user/resume/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['UserResume'],
    }),
    deleteResume: builder.mutation({
      query: (id) => ({
        url: `user/resume/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['UserResume'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetResumesQuery,
  useGetResumeByIdQuery,
  useCreateResumeMutation,
  useUpdateResumeMutation,
  useDeleteResumeMutation,
} = usersAPI;
