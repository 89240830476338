import React from 'react';

import { FaUsers } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import { useGetEventByIdQuery } from '../../../services/events';

import NotFound from '../../../pages/NotFound';

import LoaderEventsDetail from '../../../components/Loaders/EventsDetail';

import useAuth from '../../../hooks/useAuth';

import './Detail.scss';
import EventSignUp from '../../../components/EventSignUp';
import { dateFormat } from '../../../dateFormat';

const timeLeft = (begin, end) => {
  if (!begin || !end) {
    return 0;
  }

  const beginFormatDate = `${begin[0]}-${begin[1] < 10 ? '0' + begin[1] : begin[1]}-${
    begin[2] < 10 ? '0' + begin[2] : begin[2]
  }T${begin[3] < 10 ? '0' + begin[3] : begin[3]}:${begin[4] < 10 ? '0' + begin[4] : begin[4]}`;

  const endFomatDate = `${end[0]}-${end[1] < 10 ? '0' + end[1] : end[1]}-${
    end[2] < 10 ? '0' + end[2] : end[2]
  }T${end[3] < 10 ? '0' + end[3] : end[3]}:${end[4] < 10 ? '0' + end[4] : end[4]}`;

  const beginDate = new Date(beginFormatDate);
  const endDate = new Date(endFomatDate);
  const diff = endDate - beginDate;

  let delta = Math.floor(diff / 1000);

  let days = Math.floor(delta / 86400);
  delta -= days * 86400;

  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  let minutes = Math.floor(delta / 60) % 60;

  if (days > 0) {
    return `${days} д. ${hours} ч. ${minutes} мин.`;
  }

  return `${hours} ч. ${minutes} мин.`;
};

const EventsDetail = () => {
  const { id } = useParams();
  const { data, isAuth, isAdmin, isManager, isUser } = useAuth();

  const { data: event, isLoading } = useGetEventByIdQuery({
    role: isAdmin ? 'admin' : isManager ? 'manager' : 'user',
    id,
  });

  if (event === null) {
    return <NotFound />;
  }

  return (
    <section className="section section-events my-5">
      <div className="container">
        {isLoading ? (
          <LoaderEventsDetail />
        ) : (
          <div className="event-detail">
            <h4 className="event-detail__title mb-3">{event?.name}</h4>

            <div className="event-detail__description">
              Описание мероприятия:
              <span>{event?.description}</span>
            </div>

            <div className="event-detail__location">
              Место проведения:
              <span>{event?.address}</span>
            </div>

            <div className="event-detail__format">
              <div className="format-item">
                Форма проведения: <span>{event?.type?.name}</span>
              </div>
              <div className="format-item">
                Время начала:{' '}
                <span>
                  {event?.begin[3] < 10 ? '0' + event?.begin[3] : event?.begin[3]}:
                  {event?.begin[4] < 10 ? '0' + event?.begin[4] : event?.begin[4]}
                </span>
              </div>
              <div className="format-item">
                Целевая аудитория: <span>{event?.targetGroup?.name ?? ''}</span>
              </div>
              <div className="format-item">
                Продолжительность: <span>{timeLeft(event?.begin, event?.end)}</span>
              </div>
            </div>

            <div className="event-detail__people">
              Участники:
              <span>
                {<FaUsers />} {event?.bookedSeats + event?.listeners.length}/{event?.seats}
              </span>
            </div>

            <div className="event-detail__date">
              Дата проведения:
              <span>{dateFormat(event?.begin)}</span>
            </div>

            {!isAuth ? (
              <Link className="btn btn-outline-primary py-2 w-100" to="/login">
                Авторизуйтесь или зарегистрируйтесь, чтобы принять участие
              </Link>
            ) : (
              <EventSignUp event={event} userId={data?.id} isUser={isUser} />
            )}

            {isAdmin && event?.listeners && (
              <div className="event-detail__users">
                <h4>Слушатели</h4>

                <div className="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">ФИО</th>
                        <th scope="col">Телефон</th>
                        <th scope="col">EMail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {event?.listeners?.map((x, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>
                            {x?.firstName} {x?.lastName} {x?.secondName}
                          </td>
                          <td>{x?.phone}</td>
                          <td>{x?.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default EventsDetail;
