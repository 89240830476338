import { FaFireAlt, FaBurn, FaRubleSign } from 'react-icons/fa';
import ModuleTheme from './ModuleTheme';

const ModuleItem = ({ name, themes }) => {
  const price = themes?.reduce((a, b) => a + (b.price || 0), 0);

  return (
    <div className="module-item">
      <h6 className="module-title">{name}</h6>

      {/* <p className="module-prof-area">
        <FaFireAlt />
        <span>Проф. область:</span> Транспорт и логистика
      </p>

      <p className="module-competence">
        <FaBurn />
        <span>Компетенция:</span> Эксплуатация беспилотных авиационных систем
      </p> */}

      <div className="module-theme">
        {themes?.map((obj) => (
          <ModuleTheme key={obj.id} {...obj} />
        ))}
      </div>

      <div className="module-price">
        <FaRubleSign />
        Стоимость {price} руб.
      </div>
    </div>
  );
};

export default ModuleItem;
