import React from 'react';

import { Modal, Form, Col } from 'react-bootstrap';

import { useFormik } from 'formik';

import { FormikSelect } from '../../FormikSelect';

const EditConstructor = ({ request, showEdit, submitEdit, modalCloseEdit }) => {
  const { id, requestStatus } = request;

  const initialValues = {
    requestStatus: requestStatus || null,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitEdit(id, values.requestStatus);
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование запроса из конструктора</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group as={Col} controlId="requestConstructorStatus" className="mb-3">
            {/* <Form.Label>Статус запроса *</Form.Label> */}

            <FormikSelect
              name="requestStatus"
              value={formik.values.requestStatus}
              form={formik}
              options={[
                { value: 'WAITING', label: 'На рассмотрении' },
                { value: 'ACCEPTED', label: 'Одобрена' },
                { value: 'DECLINE', label: 'Отклонена' },
              ]}
              placeholder="Статус запроса"
            />
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditConstructor;
