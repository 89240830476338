import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const groupsAPI = createApi({
  reducerPath: 'groupsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Groups'],
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: ({ size = 20, page = 0 }) => `admin/groups/?size=${size}&page=${page}`,
      providesTags: () => ['Groups'],
    }),

    createGroup: builder.mutation({
      query: (body) => ({
        url: 'admin/groups/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Groups'],
    }),

    updateGroup: builder.mutation({
      query: (body) => ({
        url: `admin/groups/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Groups'],
    }),

    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `admin/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Groups'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = groupsAPI;
