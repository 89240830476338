import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const professionalObjectsAPI = createApi({
  reducerPath: 'professionalObjectsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['ProfessionalObjects'],
  endpoints: (builder) => ({
    getProfessionalObjects: builder.query({
      query: () => 'admin/professional-objects/',
      providesTags: () => ['ProfessionalObjects'],
    }),

    createProfessionalObject: builder.mutation({
      query: (body) => ({
        url: 'admin/professional-objects/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['ProfessionalObjects'],
    }),

    updateProfessionalObject: builder.mutation({
      query: (body) => ({
        url: `admin/professional-objects/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['ProfessionalObjects'],
    }),

    deleteProfessionalObject: builder.mutation({
      query: (id) => ({
        url: `admin/professional-objects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['ProfessionalObjects'],
    }),
  }),
});

export const {
  useGetProfessionalObjectsQuery,
  useCreateProfessionalObjectMutation,
  useUpdateProfessionalObjectMutation,
  useDeleteProfessionalObjectMutation,
} = professionalObjectsAPI;
