import { Pagination } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

const PaginationCustom = ({ totalPages, page, setPage }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isCurrentPageFirst = page === 0;
  const isCurrentPageLast = page === totalPages - 1;

  const changePage = (number) => {
    if (page === number) return;
    setPage(number);
    setSearchParams({ page: number + 1 });
  };

  const onPageNumberClick = (pageNumber) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = (pageNumber) => {
    changePage(pageNumber);
  };

  const onNextPageClick = (pageNumber) => {
    changePage(pageNumber);
  };

  let isPageNumberOutOfRange;
  const pageNumbers = [...new Array(totalPages)].map((_, index) => {
    const pageNumber = index;
    const isPageNumberFirst = pageNumber === 0;
    const isPageNumberLast = pageNumber === totalPages - 1;
    const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - page) <= 2;

    if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinTwoPageNumbers) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === page}>
          {pageNumber + 1}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  return (
    totalPages > 1 && (
      <Pagination className="justify-content-center" size="lg">
        <Pagination.Prev
          onClick={() => onPreviousPageClick(page - 1)}
          disabled={isCurrentPageFirst}
        />
        {pageNumbers}
        <Pagination.Next onClick={() => onNextPageClick(page + 1)} disabled={isCurrentPageLast} />
      </Pagination>
    )
  );
};

export default PaginationCustom;
