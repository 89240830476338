import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const roomTypesAPI = createApi({
  reducerPath: 'roomTypesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['RoomTypes'],
  endpoints: (builder) => ({
    getRoomTypes: builder.query({
      query: (size = 20) => `services/room-types/?size=${size}`,
      providesTags: () => ['RoomTypes'],
    }),

    createRoomType: builder.mutation({
      query: (body) => ({
        url: '/admin/room-types/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['RoomTypes'],
    }),

    updateRoomType: builder.mutation({
      query: (body) => ({
        url: `admin/room-types/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['RoomTypes'],
    }),

    deleteRoomType: builder.mutation({
      query: (id) => ({
        url: `admin/room-types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['RoomTypes'],
    }),
  }),
});

export const {
  useGetRoomTypesQuery,
  useCreateRoomTypeMutation,
  useUpdateRoomTypeMutation,
  useDeleteRoomTypeMutation,
} = roomTypesAPI;
