import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Table } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetProgramsQuery,
  useCreateProgramMutation,
  useUpdateProgramMutation,
  useDeleteProgramMutation,
} from '../../../../services/programs';

import { useGetModulesQuery } from '../../../../services/modules';
import { useGetTargetGroupsQuery } from '../../../../services/targetGroups';
import { useGetProgramTypesQuery } from '../../../../services/programTypes';
import { useGetOrganizationsQuery } from '../../../../services/organizations';
import { useGetProgramSubspeciesQuery } from '../../../../services/programSubspecies';

import CreateModal from '../../../../components/Modals/Programs/Create';
import DeleteModal from '../../../../components/Modals/Programs/Delete';
import EditModal from '../../../../components/Modals/Programs/Edit';

import AlertMessage from '../../../../components/AlertMessage';

import useAuth from '../../../../hooks/useAuth';
import PaginationCustom from '../../../../components/PaginationCustom';

const ProgramsAdmin = () => {
  const { isAdmin, isManager } = useAuth();

  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: programs, isLoading } = useGetProgramsQuery({
    size: 20,
    page,
    role: isManager ? 'manager' : 'user',
  });
  const [createProgram, { isError: isErrorCreate }] = useCreateProgramMutation();
  const [updateProgram, { isError: isErrorUpdate }] = useUpdateProgramMutation();
  const [deleteProgram, { isError: isErrorDelete }] = useDeleteProgramMutation();

  const organizationsQuery = {
    role: isAdmin ? 'admin' : 'manager',
    page: 0,
    size: 300,
  };

  const { data: types } = useGetProgramTypesQuery();
  const { data: targets } = useGetTargetGroupsQuery();
  const { data: modules } = useGetModulesQuery({
    size: 2000,
    page: 0,
    role: isAdmin ? 'admin' : 'manager',
  });
  const { data: subspecies } = useGetProgramSubspeciesQuery();
  const { data: organizations } = useGetOrganizationsQuery(organizationsQuery);

  const [image, setImage] = React.useState({ name: '', file: '' });

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const onModalOpen = () => setModalIsOpen(true);
  const onModalClose = () => setModalIsOpen(false);

  const [program, setProgram] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.type = { id: values.type };
    values.subspecies = { id: values.subspecies };
    values.target = { id: values.target };
    values.modules = values.modules.map((x) => ({ id: x }));
    values.organization = values.organization !== -1 ? { id: values.organization } : null;

    createProgram({
      role: isAdmin ? 'admin' : 'manager',
      body: image.file ? { ...values, image } : values,
    })
      .unwrap()
      .then((response) => {
        setMessage(`Программа "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить программу. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setProgram(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.type = { id: values.type };
    values.subspecies = { id: values.subspecies };
    values.target = { id: values.target };
    values.modules = values.modules.map((x) => ({ id: x }));
    values.organization = values.organization > 0 ? { id: values.organization } : null;

    updateProgram({
      role: isAdmin ? 'admin' : 'manager',
      body: image.file ? { id, ...values, image } : { id, ...values },
    })
      .unwrap()
      .then((response) => {
        setMessage(`Программа "${response.name}" успешно обновлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить программу.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setProgram(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить программу ${
        programs.content.find((x) => x.id === obj.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteProgram({ role: isAdmin ? 'admin' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Программа "${programs.content.find((x) => x.id === response).name}" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить программу.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Программы';
  }, []);

  return (
    <>
      {(isAdmin || isManager) && !isLoading && (
        <>
          <CreateModal
            types={types?.content ?? []}
            targets={targets?.content ?? []}
            modules={modules?.content ?? []}
            subspecies={subspecies?.content ?? []}
            organizations={organizations?.content ?? []}
            setImage={setImage}
            submitCreate={submitCreate}
            showCreate={showCreate}
            modalCloseCreate={modalCloseCreate}
          />

          <EditModal
            program={program}
            isAdmin={isAdmin}
            types={types?.content ?? []}
            targets={targets?.content ?? []}
            modules={modules?.content ?? []}
            subspecies={subspecies?.content ?? []}
            organizations={organizations?.content ?? []}
            setImage={setImage}
            showEdit={showEdit}
            submitEdit={submitEdit}
            modalCloseEdit={modalCloseEdit}
          />

          <DeleteModal
            program={program}
            showDelete={showDelete}
            modalCloseDelete={modalCloseDelete}
            submitDelete={submitDelete}
            deleteMessage={deleteMessage}
          />
        </>
      )}

      <section className="section section-admin__programs min-height my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Программы</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-2" onClick={modalShowCreate}>
            Добавить программу
          </Button>

          <Button
            variant="outline-primary"
            className="w-100 mb-4"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_API_URL}/statistics/programs/`)
            }>
            Выгрузить статистику
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    <th>Название программы</th>
                    <th>Организация</th>
                    <th>Целевая аудитория</th>
                    <th>Вид программы</th>
                    <th>Подвид программы</th>
                    <th>Статус программы</th>
                    <th>Доступно для запросов</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {programs?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.name}</td>
                      <td>{organizations?.content?.name}</td>
                      <td>{obj.target?.name ?? ''}</td>
                      <td>{obj.type?.name ?? ''}</td>
                      <td>{obj.subspecies?.name ?? ''}</td>
                      <td>{obj.status ?? ''}</td>
                      <td>{obj.status ?? ''}</td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowEdit(obj)}>
                          <FaEdit size="1.1em" />
                        </Button>

                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt color="red" size="1.1em" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={programs?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ProgramsAdmin;
