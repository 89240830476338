import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const programsAPI = createApi({
  reducerPath: 'programsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Programs'],
  endpoints: (builder) => ({
    getPrograms: builder.query({
      query: ({ size = 5, page = 0, role }) =>
        role === 'manager'
          ? `manager/study-programs/?size=${size}&page=${page}`
          : `programs/?size=${size}&page=${page}`,
      providesTags: () => ['Programs'],
    }),

    getProgramById: builder.query({
      query: ({ role, id }) =>
        role === 'manager' ? `manager/study-program/${id}` : `programs/${id}`,
      providesTags: () => ['Programs'],
    }),

    signUpProgram: builder.mutation({
      query: (program) => ({
        url: 'programs',
        method: 'POST',
        body: program,
        headers: { 'Content-Type': 'application/json;' },
      }),
    }),

    createProgram: builder.mutation({
      query: ({ role, body }) => ({
        url: role === 'manager' ? 'manager/study-programs/' : 'admin/study-programs/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Programs'],
    }),

    updateProgram: builder.mutation({
      query: ({ role, body }) => ({
        url:
          role === 'manager'
            ? `manager/study-programs/${body.id}`
            : `admin/study-programs/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Programs'],
    }),

    deleteProgram: builder.mutation({
      query: ({ role, id }) => ({
        url: role === 'manager' ? `manager/study-programs/${id}` : `admin/study-programs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Programs'],
    }),
  }),
});

export const {
  useGetProgramsQuery,
  useGetProgramByIdQuery,
  useSignUpProgramMutation,
  useCreateProgramMutation,
  useUpdateProgramMutation,
  useDeleteProgramMutation,
} = programsAPI;
