import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';

import { useGetVacancyByIdQuery } from '../../../services/vacancy';
import { useGetResponsesQuery, useRespondToVacancyMutation } from '../../../services/responses';
import { useGetResumesQuery } from '../../../services/users';

import useAuth from '../../../hooks/useAuth';

import NotFound from '../../NotFound';

import workType from '../../../workType.js';
import relations from '../../../relations.js';

import AlertMessage from '../../../components/AlertMessage';
import '../Vacancy.scss';

const VacancyDetail = () => {
  const { id } = useParams();
  const { data, isAuth, isEmployer } = useAuth();

  const { data: responses, isLoadingResponses } = useGetResponsesQuery(
    isEmployer ? 'employer' : 'user',
  );

  const { data: vacancy, isLoadingVacancy } = useGetVacancyByIdQuery(id);
  const { data: resumes, isLoadingResumes } = useGetResumesQuery(id);

  const [respondToVacancy, { isError: isErrorRespond }] = useRespondToVacancyMutation();

  const [isInvite, setIsInvite] = React.useState(false);

  const handleInviteClose = () => setIsInvite(false);
  const handleInviteShow = () => setIsInvite(true);

  const [message, setMessage] = React.useState({ text: '', isError: false });

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    if (!formData.has('resume') || !formData.get('resume')) {
      setMessage({ text: 'Выберите резюме', isError: true });
      return;
    }

    let body = {
      organization: { id: +vacancy?.organizationId },
      user: { id: data.user ? data.user?.id : data.id },
      resume: { id: +formData.get('resume') },
      vacancy: { id: vacancy?.id },
      responseStatus: 'WAITING',
    };

    respondToVacancy(body)
      .unwrap()
      .then((response) => {
        setMessage({ text: `Вы успешно откликнулись на вакансию`, isError: false });
      })
      .catch((err) => {
        setMessage({
          text: `Не удалось откликнуться на вакансию. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
          isError: true,
        });
      });

    handleInviteClose();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Просмотр вакансии';
    window.scroll(0, 0);
  }, []);

  if (vacancy === null) {
    return <NotFound />;
  }

  return (
    <section className="section section-vacancy-detail my-5">
      <div className="container">
        {message?.text && (
          <AlertMessage
            message={message?.text}
            setMessage={setMessage}
            isError={message?.isError || isErrorRespond}
          />
        )}

        {isLoadingVacancy ? (
          <h1 className="text-center">Загрузка ...</h1>
        ) : (
          <div className="vacancy-detail">
            <div className="vacancy-detail__info mb-3">
              <h4 className="vacancy-detail__info-title text-center">
                {vacancy?.position} (г. {vacancy?.city})
              </h4>
              <h5 className="vacancy-detail__info-salary text-center">
                {' '}
                от {String(vacancy?.salaryFrom).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} до{' '}
                {String(vacancy?.salaryTo).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} руб.
              </h5>
              <h6 className="vacancy-detail__info-organization text-center">
                {vacancy?.organization}
              </h6>

              <p className="vacancy-detail__info-experience text-center mb-0">
                Опыт работы: {vacancy?.experience}
              </p>

              <p className="vacancy-detail__info-experience text-center mb-0">
                Тип занятости: {workType[vacancy?.workType]} / {relations[vacancy?.relations]}
              </p>

              <hr />
              <div className="vacancy-detail__info-date text-muted text-center">
                Вакансия опубликована {new Date(vacancy?.created).toLocaleDateString()}
              </div>
            </div>

            <div className="vacancy-detail__object mb-1">
              <strong>Отрасль компании: </strong>
              {vacancy?.object}
            </div>

            <div className="vacancy-detail__education-level mb-1">
              <strong>Образование: </strong>
              {vacancy?.educationLevel}
            </div>

            <div className="vacancy-detail__charge mb-1">
              <strong>Обязанности:</strong>
              <ul>
                {vacancy?.charge
                  .split(';')
                  .filter((x) => x !== '')
                  .map((x, index) => (
                    <li key={index}>{x}</li>
                  ))}
              </ul>
            </div>

            <div className="vacancy-detail__required-skills mb-1">
              <strong>Требования:</strong>
              <ul>
                {vacancy?.requiredSkills
                  .split(';')
                  .filter((x) => x !== '')
                  .map((x, index) => (
                    <li key={index}>{x}</li>
                  ))}
              </ul>
            </div>

            <div className="vacancy-detail__contacts mb-3">
              <h2>Контактная информация</h2>
              {isAuth ? (
                <>
                  <Modal show={isInvite} onHide={handleInviteClose}>
                    <Form onSubmit={handleSubmit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Отклик на вакансию</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <Form.Select name="resume">
                          <option value="">Выберите резюме</option>
                          {resumes?.content?.map((resume) => (
                            <option value={resume?.id} key={resume?.id}>
                              {resume?.educations
                                .map((education) => education.name + ' / ' + education.organization)
                                .join(', ')}
                            </option>
                          ))}
                        </Form.Select>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button variant="success" onClick={handleInviteClose} type="submit">
                          Отправить
                        </Button>
                        <Button variant="danger" onClick={handleInviteClose}>
                          Отмена
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>

                  <p className="mb-1">
                    <strong>Контактное лицо:</strong> {vacancy?.contact}
                  </p>
                  <p className="mb-1">
                    <strong>Телефон:</strong> {vacancy?.phone}
                  </p>
                  <p className="mb-1">
                    <strong>EMail:</strong> {vacancy?.email}
                  </p>

                  {!isLoadingResponses &&
                    !isEmployer &&
                    (responses?.content?.find(
                      (response) => response?.vacancy?.id === vacancy?.id,
                    ) ? (
                      <h3 className="mt-3 text-center">
                        Вы уже откликнулись или приглашены на данную вакансию
                      </h3>
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100 mt-3 py-2 text-uppercase fw-bold"
                        onClick={handleInviteShow}>
                        Откликнуться
                      </Button>
                    ))}
                </>
              ) : (
                <h5>
                  Для просмотра контактной информации необходимо{' '}
                  <Link to="/login">авторизоваться</Link>
                </h5>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default VacancyDetail;
