import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderNews = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={350}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="5" ry="5" width="450" height="340" />
    <rect x="470" y="0" rx="5" ry="5" width="822" height="35" />

    <rect x="470" y="60" rx="5" ry="5" width="750" height="25" />
    <rect x="470" y="95" rx="5" ry="5" width="750" height="25" />
    <rect x="470" y="130" rx="5" ry="5" width="750" height="25" />
    <rect x="470" y="165" rx="5" ry="5" width="750" height="25" />
    <rect x="470" y="200" rx="5" ry="5" width="750" height="25" />
    <rect x="470" y="235" rx="5" ry="5" width="750" height="25" />
    <rect x="470" y="270" rx="5" ry="5" width="750" height="25" />

    <rect x="1150" y="305" rx="5" ry="5" width="135" height="35" />
  </ContentLoader>
);

export default LoaderNews;
