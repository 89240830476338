import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const buildingsAPI = createApi({
  reducerPath: 'buildingsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Buildings'],
  endpoints: (builder) => ({
    getBuildings: builder.query({
      query: ({ size = 20, page = 0, role }) => `${role}/buildings/?page=${page}&size=${size}`,
      providesTags: () => ['Buildings'],
    }),

    createBuilding: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/buildings/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Buildings'],
    }),

    updateBuilding: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/buildings/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Buildings'],
    }),

    deleteBuilding: builder.mutation({
      query: ({ role, id }) => ({
        url: `${role}/buildings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Buildings'],
    }),
  }),
});

export const {
  useGetBuildingsQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useDeleteBuildingMutation,
} = buildingsAPI;
