import { FaBook } from 'react-icons/fa';

const ModuleTheme = ({ name, duration }) => {
  return (
    <div className="module-theme__item">
      <div className="item-title">
        <FaBook />
        <span>Тема:</span> {name}
      </div>

      <div className="item-type">(Л, {duration}ч)</div>
    </div>
  );
};

export default ModuleTheme;
