import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderBookingDetail = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={745}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="50" />
    <rect x="0" y="80" rx="5" ry="5" width="520" height="330" />
    <rect x="540" y="80" rx="5" ry="5" width="100%" height="40" />
    <rect x="540" y="140" rx="5" ry="5" width="100%" height="40" />
    <rect x="540" y="200" rx="5" ry="5" width="100%" height="40" />
    <rect x="540" y="260" rx="5" ry="5" width="100%" height="40" />
    <rect x="0" y="440" rx="5" ry="5" width="100%" height="300" />
  </ContentLoader>
);

export default LoaderBookingDetail;
