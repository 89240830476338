import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { FaFilter } from 'react-icons/fa';

import { useGetProgramsQuery, useSignUpProgramMutation } from '../../services/programs';

import { useGetProgramTypesQuery } from '../../services/programTypes';
import { useGetTargetGroupsQuery } from '../../services/targetGroups';
import { useGetProgramSubspeciesQuery } from '../../services/programSubspecies';

import Programm from '../../components/Programm';
import LoaderPrograms from '../../components/Loaders/Programs';
import PaginationCustom from '../../components/PaginationCustom';
import AlertMessage from '../../components/AlertMessage';
import FilterPrograms from '../../components/Modals/FilterPrograms';

import './Programs.scss';

const Programs = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const nameOfFilter = searchParams.get('name') ?? null;
  const typeOfFilter = Number(searchParams.get('type')) ?? null;
  const subspeciesOfFilter = Number(searchParams.get('subspecies')) ?? null;
  const targetOfFilter = Number(searchParams.get('target')) ?? null;

  const [filterIsOpen, setFilterIsOpen] = React.useState(false);

  const { data: programs, isLoading } = useGetProgramsQuery({
    size: 20,
    page,
    role: 'user',
  });
  const [signUpProgram, { isError }] = useSignUpProgramMutation();

  const { data: types } = useGetProgramTypesQuery();
  const { data: targets } = useGetTargetGroupsQuery();
  const { data: subspecies } = useGetProgramSubspeciesQuery();

  const [message, setMessage] = React.useState(null);

  const handleSignUpProgram = (program) => {
    signUpProgram(program)
      .unwrap()
      .then(() => setMessage('Вы успешно записались на программу.'))
      .catch((err) => {
        setMessage('При записи произошла ошибка, пожалуйста, попробуйте позже.');
      });
  };

  const filtersArray = (programs) => {
    return programs.filter((program) => {
      let filters = {
        name: true,
        type: true,
        subspecies: true,
        target: true,
      };

      if (nameOfFilter) {
        filters.name = program.name.toLowerCase().indexOf(nameOfFilter.toLocaleLowerCase()) !== -1;
      }

      if (typeOfFilter) {
        filters.type = program.type?.id === typeOfFilter;
      }

      if (subspeciesOfFilter) {
        filters.subspecies = program.subspecies?.id === subspeciesOfFilter;
      }

      if (targetOfFilter) {
        filters.target = program.target?.id === targetOfFilter;
      }

      return filters.name && filters.type && filters.subspecies && filters.target;
    });
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Программы';
  }, []);

  return (
    <>
      <FilterPrograms
        filterIsOpen={filterIsOpen}
        setFilterIsOpen={setFilterIsOpen}
        types={types?.content ?? []}
        subspecies={subspecies?.content ?? []}
        targets={targets?.content ?? []}
      />

      <section className="section section-programs my-5">
        <div className="container">
          <div className="section-title">
            <h1>Программы</h1>
            <hr />

            <div className="filters-wrapper" onClick={() => setFilterIsOpen(true)}>
              <div className="filters">{<FaFilter />} Фильтр</div>
              <span>{isLoading ? '...' : programs?.content?.length}</span>
            </div>
          </div>

          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          {isLoading ? (
            [...new Array(6)].map((_, index) => <LoaderPrograms key={index} />)
          ) : (
            <>
              <div className="programs-wrapper mb-4">
                <div className="programs">
                  {filtersArray(programs?.content).map((obj) => (
                    <Programm key={obj.id} obj={obj} handleSignUpProgram={handleSignUpProgram} />
                  ))}
                </div>
              </div>

              <PaginationCustom totalPages={programs?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Programs;
