import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const eventsAPI = createApi({
  reducerPath: 'eventsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Events'],
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: ({
        size = 20,
        page = 0,
        role,
        date = null,
        name = null,
        type = null,
        targetGroup = null,
      }) =>
        role === 'user'
          ? `events/?page=${page}&size=${size}${date ? `&date=${date}` : ''}${
              name ? `&name=${name}` : ''
            }${type ? `&name=${type}` : ''}${targetGroup ? `&name=${targetGroup}` : ''}`
          : `${role}/events/?page=${page}&size=${size}`,
      providesTags: () => ['Events'],
    }),

    getEventById: builder.query({
      query: ({ role, id }) => (role === 'user' ? `events/${id}` : `${role}/events/${id}`),
      providesTags: () => ['Events'],
    }),

    getEventsByUser: builder.query({
      query: () => `user/events/`,
      providesTags: () => ['Events'],
    }),

    createEvent: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/events/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Events'],
    }),

    signUpEvent: builder.mutation({
      query: (event) => ({
        url: 'user/events/',
        method: 'POST',
        body: event,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Events'],
    }),

    updateEvent: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/events/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Events'],
    }),

    deleteEvent: builder.mutation({
      query: ({ role, id }) => ({
        url: `${role}/events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Events'],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventByIdQuery,
  useGetEventsByUserQuery,
  useCreateEventMutation,
  useSignUpEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
} = eventsAPI;
