import React from 'react';
import { Link } from 'react-router-dom';

import { useGetResumesQuery } from '../../services/resumes';

import './Resumes.scss';

const Resumes = () => {
  const { data: resumes, isLoading } = useGetResumesQuery();

  React.useEffect(() => {
    document.title = 'ЦОПП - Резюме';
  }, []);

  return (
    <>
      <section className="section section-resumes my-5">
        <div className="container">
          <div className="section-title">
            <h1>Резюме</h1>
            <hr />
          </div>

          {isLoading ? (
            <h1 className="text-center">Загрузка ...</h1>
          ) : (
            <>
              <div className="responses-wrapper mb-4">
                <div className="resumes">
                  {resumes?.content?.map((x) => (
                    <div className="resumes-item" key={x.id}>
                      <div className="resumes-item__author">
                        От {x.firstName} {x.lastName} {x.secondName}
                      </div>

                      <div className="resumes-item__educations">
                        <h6>Образование - {x.educationLevel}</h6>
                        <p>
                          {x?.educations?.map(
                            (education) =>
                              `${education?.organization ?? ''}, ${education?.name ?? ''}, ${
                                new Date(education?.endDate).toLocaleDateString() ?? ''
                              }`,
                          )}
                        </p>
                      </div>

                      <Link to={`/resumes/${x.id}`} className="btn btn-outline-primary w-100">
                        Подробнее
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Resumes;
