import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const feedbackAPI = createApi({
  reducerPath: 'feedbackAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Feedback'],
  endpoints: (builder) => ({
    // getFeedback: builder.query({
    //   query: ({ size = 5, page = 0 }) => `feedback/?size=${size}&page=${page}`,
    //   providesTags: () => ['Feedback'],
    // }),

    createFeedback: builder.mutation({
      query: (body) => ({
        url: 'feedback/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Feedback'],
    }),

    updateFeedback: builder.mutation({
      query: (body) => ({
        url: `admin/feedback/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Feedback'],
    }),

    deleteFeedback: builder.mutation({
      query: (id) => ({
        url: `admin/feedback/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Feedback'],
    }),
  }),
});

export const { useCreateFeedbackMutation, useUpdateFeedbackMutation, useDeleteFeedbackMutation } =
  feedbackAPI;
