export const dateFormat = (date, type = 1) => {
  if (!date) {
    return '';
  }

  // type 1 - Просто дата, type 2 - Дата со временем, 3 - Формат для форм c временем, 4 - Формат для форм
  if (type === 1) {
    return `${date[2] < 10 ? '0' + date[2] : date[2]}.${date[1] < 10 ? '0' + date[1] : date[1]}.${
      date[0]
    }`;
  }

  if (type === 2) {
    return `${date[2] < 10 ? '0' + date[2] : date[2]}.${date[1] < 10 ? '0' + date[1] : date[1]}.${
      date[0]
    } ${date[3] < 10 ? '0' + date[3] : date[3]}:${date[4] < 10 ? '0' + date[4] : date[4]}`;
  }

  if (type === 3) {
    return `${date[0]}-${date[1] < 10 ? '0' + date[1] : date[1]}-${
      date[2] < 10 ? '0' + date[2] : date[2]
    }T${date[3] < 10 ? '0' + date[3] : date[3]}:${date[4] < 10 ? '0' + date[4] : date[4]}`;
  }

  return `${date[0]}-${date[1] < 10 ? '0' + date[1] : date[1]}-${
    date[2] < 10 ? '0' + date[2] : date[2]
  }`;
};
