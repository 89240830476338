import React from 'react';

import { Modal, Form } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormikSelect } from '../../FormikSelect';
import { fileResizeAndUpload } from '../../../fileResizeAndUpload';

const EditModal = ({
  professionalObject,
  competentions,
  setImage,
  showEdit,
  submitEdit,
  modalCloseEdit,
}) => {
  const { id, name } = professionalObject;

  const initialValues = {
    name: name || '',
    competentions: professionalObject.competentions?.map((x) => x.id) || [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Обязательно поле'),
    }),
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    await fileResizeAndUpload(file, setImage);
  };

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование профессиональной области</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group controlId="organizationName" className="mb-3">
            <Form.Label>Наименование *</Form.Label>
            <Form.Control
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Компетенции *</Form.Label>

            <FormikSelect
              name="competentions"
              value={formik.values.competentions}
              form={formik}
              options={competentions.map((competention) => ({
                value: competention.id,
                label: competention.name,
              }))}
              isMulti={true}
              placeholder="Выберите компетенции"
            />
          </Form.Group>

          <Form.Group controlId="organizationEmail" className="mb-3">
            <Form.Label>Изображение *</Form.Label>
            <Form.Control type="file" name="image" onChange={handleFileUpload} />
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
