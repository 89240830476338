import React from 'react';

import { Table, Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetProgramSubspeciesQuery,
  useCreateProgramSubspeciesMutation,
  useUpdateProgramSubspeciesMutation,
  useDeleteProgramSubspeciesMutation,
} from '../../../../services/programSubspecies';

import CreateModal from '../../../../components/Modals/ProgrammSubspecies/Create';
import DeleteModal from '../../../../components/Modals/ProgrammSubspecies/Delete';
import EditModal from '../../../../components/Modals/ProgrammSubspecies/Edit';

import AlertMessage from '../../../../components/AlertMessage';

import './ProgramSubspecies.scss';

const ProgramSubspecies = () => {
  const { data: programSubspecies, isLoading } = useGetProgramSubspeciesQuery();
  const [createProgramSubspecies, { isError: isErrorCreate }] =
    useCreateProgramSubspeciesMutation();
  const [updateProgramSubspecies, { isError: isErrorUpdate }] =
    useUpdateProgramSubspeciesMutation();
  const [deleteProgramSubspecies, { isError: isErrorDelete }] =
    useDeleteProgramSubspeciesMutation();

  const [subspecies, setSubspecies] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createProgramSubspecies(values)
      .unwrap()
      .then((response) => {
        setMessage(`Подвид программы "${response.name}" успешно добавлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить подвид программы.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (subspecies) => {
    setSubspecies(subspecies);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateProgramSubspecies({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Подвид программы "${response.name}" успешно изменен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить подвид программы.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (subspecies) => {
    setSubspecies(subspecies);
    setDeleteMessage(
      `Вы уверены, что хотите удалить подвид программы ${
        programSubspecies.content.find((x) => x.id === subspecies.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteProgramSubspecies(id)
      .unwrap()
      .then(() => {
        setMessage(
          `Подвид программы "${
            programSubspecies.content.find((x) => x.id === id).name
          }" успешно удалён.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить подвид программы "${
            programSubspecies.content.find((x) => x.id === id).name
          }".  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Подвиды программ';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        subspecies={subspecies}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        subspecies={subspecies}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-program-subspecies my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Подвиды программ</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
            Добавить подвид программы
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <Table bordered responsive className="text-center">
              <thead className="align-middle">
                <tr>
                  <th>Название</th>
                  <th>Действия</th>
                </tr>
              </thead>

              <tbody className="align-middle">
                {programSubspecies.content.map((subspecies) => (
                  <tr key={subspecies.id}>
                    <td>{subspecies.name}</td>
                    <td>
                      <Button
                        variant="link"
                        className="p-0 mx-2"
                        onClick={() => modalShowEdit(subspecies)}>
                        <FaEdit size="1.1em" />
                      </Button>

                      <Button
                        variant="link"
                        className="p-0 mx-2"
                        onClick={() => modalShowDelete(subspecies)}>
                        <FaTrashAlt size="1.1em" color="red" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </section>
    </>
  );
};

export default ProgramSubspecies;
