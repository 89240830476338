import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import { FaEdit, FaChartLine } from 'react-icons/fa';

import AlertMessage from '../../../../components/AlertMessage';
import EditRecruiting from '../../../../components/Modals/Requests/EditRecruiting';
import PaginationCustom from '../../../../components/PaginationCustom';

import {
  useGetEmployeeRequestsQuery,
  useUpdateEmployeeRequestsMutation,
} from '../../../../services/requests';

const STATUS = {
  WAITING: 'На рассмотрении',
  ACCEPTED: 'Одобрена',
  DECLINE: 'Отклонена',
  SEARCHING: 'Идёт набор на обучение специалистов',
  TEACHING: 'Готовятся специалисты',
};

const RequestsRecruiting = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );
  const query = {
    page: page,
    role: 'admin',
  };

  const { data: requests, isLoading } = useGetEmployeeRequestsQuery(query);
  const [updateRequest, { isError }] = useUpdateEmployeeRequestsMutation();

  const [request, setRequest] = React.useState({});

  const [message, setMessage] = React.useState(null);
  const [showEdit, setShowEdit] = React.useState(false);

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setRequest(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, body) => {
    body.organization = { id: body.organization };
    updateRequest({ role: 'admin', id, body: { id, ...body } })
      .unwrap()
      .then((response) => {
        setMessage(`Заявка на подбор персонала успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось изменить заявку. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
    modalCloseEdit();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Запросы на подбор персонала';
  }, []);

  return (
    <>
      <EditRecruiting
        request={request}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
        isAdmin
      />

      <section className="section section-requests__recruiting min-height my-5">
        <div className="container">
          <div className="section-title">
            <h1>Запросы на подбор персонала</h1>
            <hr />
          </div>

          <Button
            variant="outline-primary"
            className="mb-4 d-block mx-auto"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_API_URL}/statistics/employee-request/`)
            }>
            <FaChartLine size="1.1em" /> Выгрузить статистику
          </Button>

          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    <th>Наименование должности</th>
                    <th>Количество штатных единиц</th>
                    <th>Дата заявки</th>
                    <th>Статус запроса</th>
                    <th>Работодатель</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {requests?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.position}</td>
                      <td>{obj.quantity}</td>
                      <td>{new Date(obj.created).toLocaleDateString()}</td>
                      <td>{STATUS[obj.requestStatus]}</td>
                      <td>{obj.contacts}</td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowEdit(obj)}>
                          <FaEdit size="1.1em" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={requests?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default RequestsRecruiting;
