import React from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const ForgotPassword = ({ showModal, onModalClose, resetPassword }) => {
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Некорректный EMail').required('Обязательно поле'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetPassword(values);
      resetForm({});
    },
  });

  return (
    <>
      <Modal show={showModal} onHide={onModalClose} centered={true} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Сброс пароля</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit} noValidate>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label className="mb-1">EMail *</Form.Label>
              <Form.Control
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={!!formik.errors.email}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-success" onClick={() => formik.submitForm()} className="col">
            Сбросить
          </Button>

          <Button variant="outline-secondary" onClick={onModalClose} className="col">
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForgotPassword;
