import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditModal = ({ theme, studyFormats, showEdit, submitEdit, modalCloseEdit }) => {
  const { id, name, description, duration, price, format } = theme;

  const initialValues = {
    name: name || '',
    description: description || '',
    duration: duration || '',
    price: price || '',
    format: format?.id || '',
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Обязательно поле'),
      description: Yup.string(),
      duration: Yup.number().required('Обязательно поле'),
      price: Yup.number().required('Обязательно поле'),
      format: Yup.number().moreThan(-1, 'Выберите форму проведения').required('Обязательно поле'),
    }),
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование темы</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="themesName" className="mb-3">
              <Form.Label>Наименование *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="themesFormat" className="mb-3">
              <Form.Label>Форма проведения *</Form.Label>
              <Form.Select
                name="format"
                onChange={formik.handleChange}
                value={formik.values.format}
                isValid={formik.touched.format && !formik.errors.format}
                isInvalid={!!formik.errors.format}>
                <option value="-1">Выберите форму проведения</option>
                {studyFormats.map((obj) => (
                  <option key={obj.id} value={obj.id}>
                    {obj.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.format}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="themesDescription" className="mb-3">
              <Form.Label>Содержание</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                isValid={formik.touched.description && !formik.errors.description}
                isInvalid={!!formik.errors.description}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="themesDuration" className="mb-3">
              <Form.Label>Продолжительность в часах *</Form.Label>
              <Form.Control
                type="number"
                min="1"
                name="duration"
                onChange={formik.handleChange}
                value={formik.values.duration}
                isValid={formik.touched.duration && !formik.errors.duration}
                isInvalid={!!formik.errors.duration}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.duration}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="themesPrice" className="mb-3">
              <Form.Label>Цена *</Form.Label>
              <Form.Control
                name="price"
                onChange={formik.handleChange}
                value={formik.values.price}
                isValid={formik.touched.price && !formik.errors.price}
                isInvalid={!!formik.errors.price}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.price}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
