import React from 'react';

import { Modal, Form, Col, Row } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormikSelect } from '../../FormikSelect';

const EditRecruiting = ({ request, showEdit, submitEdit, modalCloseEdit, isAdmin = false }) => {
  const {
    id,
    requestStatus,
    position,
    quantity,
    city,
    subdivision,
    openState,
    workMobilisation,
    disabledAllowed,
    charge,
    nationality,
    educationLevel,
    requiredSkills,
    experience,
    allowance,
    additionalRequirements,
    relations,
    workType,
    living,
    supply,
    compensation,
    relocationHelp,
    salaryFrom,
    salaryTo,
    social,
    guarantee,
    interviewer,
    employer,
    contacts,
    organization,
  } = request;

  const initialValues = {
    requestStatus: requestStatus || '',

    position: position || '',
    quantity: quantity || '',
    city: city || '',
    subdivision: subdivision || '',
    openState: openState || false,
    workMobilisation: workMobilisation || false,
    disabledAllowed: disabledAllowed || false,
    charge: charge || '',
    nationality: nationality || '',
    educationLevel: educationLevel || '',
    requiredSkills: requiredSkills || '',
    experience: experience || '',
    allowance: allowance || false,
    additionalRequirements: additionalRequirements || '',

    relations: relations || '',
    workType: workType || '',
    living: living || '',

    supply: supply || false,
    compensation: compensation || false,
    relocationHelp: relocationHelp || false,
    salaryFrom: salaryFrom || 0,
    salaryTo: salaryTo || 0,
    social: social || '',
    guarantee: guarantee || '',
    interviewer: interviewer || '',
    employer: employer || '',
    contacts: contacts || '',
    organization: organization ? (organization.id ? organization.id : organization) : null,
  };

  const validationSchema = Yup.object({
    position: Yup.string().required('Укажите наименование должности'),
    quantity: Yup.number()
      .moreThan(0, 'Должно быть больше 0')
      .required('Укажите количество штатных единиц'),
    city: Yup.string().required('Укажите место работы'),
    subdivision: Yup.string().required('Укажите наименование структурного подразделения'),
    openState: Yup.boolean(),
    workMobilisation: Yup.boolean(),
    disabledAllowed: Yup.boolean(),
    charge: Yup.string()
      .required('Укажите функциональные обязанности')
      .max(1500, 'Максимальное кол-во символом 1500'),
    nationality: Yup.string().required('Укажите гражданство'),
    educationLevel: Yup.string().required('Укажите образование'),
    requiredSkills: Yup.string()
      .required('Укажите проф. знания и навыки')
      .max(1500, 'Максимальное кол-во символом 1500'),
    experience: Yup.string().required('Укажите опыт'),
    allowance: Yup.boolean(),
    additionalRequirements: Yup.string(),

    relations: Yup.string()
      .oneOf(['PARTTIME', 'TIMELESS'], 'Выберите тип трудовых отношений')
      .required('Выберите тип трудовых отношений'),
    workType: Yup.string()
      .oneOf(['TOUR', 'FULLTIME', 'SHIFT', 'FLEXIBLE', 'REMOTE'], 'Выберите график работы')
      .required('Выберите график работы'),
    living: Yup.string().required('Укажите проживание'),

    supply: Yup.boolean(),
    compensation: Yup.boolean(),
    relocationHelp: Yup.boolean(),
    salary: Yup.number(),
    social: Yup.string().required('Укажите базовый соц. пакет'),
    guarantee: Yup.string().required('Укажите дополнительные льготы и гарантии'),
    interviewer: Yup.string().required('Укажите лицо, проводящее собеседование'),
    employer: Yup.string().required('Укажите лицо, принимающее решение о найме кандидата'),
    contacts: Yup.string().required('Укажите контактное лицо по заявке'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование запроса на подбор персонала</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          {isAdmin && (
            <Form.Group className="mb-3">
              <Form.Label>Статус заявки *</Form.Label>

              <FormikSelect
                name="requestStatus"
                value={formik.values.requestStatus}
                form={formik}
                options={[
                  { value: 'WAITING', label: 'На рассмотрении' },
                  { value: 'ACCEPTED', label: 'Одобрена' },
                  { value: 'DECLINE', label: 'Отклонена' },
                  { value: 'SEARCHING', label: 'Идёт набор на обучение специалистов' },
                  { value: 'TEACHING', label: 'Готовятся специалисты' },
                ]}
                placeholder="Статус запроса"
              />
            </Form.Group>
          )}

          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Form.Group controlId="recruitingPosition" className="mb-3">
                <Form.Label>Наименование должности *</Form.Label>

                <Form.Control
                  name="position"
                  onChange={formik.handleChange}
                  value={formik.values.position}
                  isValid={formik.touched.position && !formik.errors.position}
                  isInvalid={!!formik.errors.position}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.position}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} md={3}>
              <Form.Group controlId="recruitingQuantity" className="mb-3">
                <Form.Label>Количество штатных единиц *</Form.Label>
                <Form.Control
                  name="quantity"
                  type="number"
                  min="1"
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                  isValid={formik.touched.quantity && !formik.errors.quantity}
                  isInvalid={!!formik.errors.quantity}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.quantity}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} md={5}>
              <Form.Group controlId="recruitingCity">
                <Form.Label>Место работы (субъект, область, город, улица, дом) *</Form.Label>
                <Form.Control
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  isValid={formik.touched.city && !formik.errors.city}
                  isInvalid={!!formik.errors.city}
                />

                <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="recruitingSubdivision" className="mb-3">
            <Form.Label>
              Наименование структурного подразделения (Юр.лицо/Трест/; Служба/Управление;
              Отдел/Сектор) *
            </Form.Label>
            <Form.Control
              name="subdivision"
              onChange={formik.handleChange}
              value={formik.values.subdivision}
              isValid={formik.touched.subdivision && !formik.errors.subdivision}
              isInvalid={!!formik.errors.subdivision}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.subdivision}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Check
              id="openState"
              required
              name="openState"
              label="Наличие открытой ставки в штатном расписании"
              onChange={formik.handleChange}
              value={formik.values.openState}
              checked={formik.values.openState}
            />
          </Form.Group>

          <Form.Group>
            <Form.Check
              id="workMobilisation"
              required
              name="workMobilisation"
              label="Трудовая мобилизация"
              onChange={formik.handleChange}
              value={formik.values.workMobilisation}
              checked={formik.values.workMobilisation}
            />
          </Form.Group>

          <Form.Group>
            <Form.Check
              id="disabledAllowed"
              required
              name="disabledAllowed"
              label="Возможность трудоустройства граждан с ОВЗ, инвалидов"
              onChange={formik.handleChange}
              value={formik.values.disabledAllowed}
              checked={formik.values.disabledAllowed}
            />
          </Form.Group>

          <div className="recruiting-form__title">
            <h5>Функциональные обязанности</h5>
            <hr />
          </div>

          <Form.Group controlId="recruitingCharge" className="mb-3">
            <Form.Label>Функциональные обязанности *</Form.Label>
            <Form.Control
              as="textarea"
              name="charge"
              onChange={formik.handleChange}
              value={formik.values.charge}
              isValid={formik.touched.charge && !formik.errors.charge}
              isInvalid={!!formik.errors.charge}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.charge}</Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col xs={12} md={3}>
              <Form.Group controlId="recruitingNationality" className="mb-3">
                <Form.Label>Гражданство *</Form.Label>
                <Form.Control
                  name="nationality"
                  onChange={formik.handleChange}
                  value={formik.values.nationality}
                  isValid={formik.touched.nationality && !formik.errors.nationality}
                  isInvalid={!!formik.errors.nationality}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.nationality}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} md={5}>
              <Form.Group controlId="recruitingEducationLevel" className="mb-3">
                <Form.Label>Образование(уровень, квалификация) *</Form.Label>
                <Form.Control
                  name="educationLevel"
                  onChange={formik.handleChange}
                  value={formik.values.educationLevel}
                  isValid={formik.touched.educationLevel && !formik.errors.educationLevel}
                  isInvalid={!!formik.errors.educationLevel}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.educationLevel}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group controlId="recruitingExperience">
                <Form.Label>Опыт работы *</Form.Label>
                <Form.Control
                  name="experience"
                  onChange={formik.handleChange}
                  value={formik.values.experience}
                  isValid={formik.touched.experience && !formik.errors.experience}
                  isInvalid={!!formik.errors.experience}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.experience}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="recruitingRequiredSkills" className="mb-3">
            <Form.Label>Необходимые проф. знания и навыки *</Form.Label>
            <Form.Control
              as="textarea"
              name="requiredSkills"
              onChange={formik.handleChange}
              value={formik.values.requiredSkills}
              isValid={formik.touched.requiredSkills && !formik.errors.requiredSkills}
              isInvalid={!!formik.errors.requiredSkills}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.requiredSkills}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Check
            className="mb-3"
            id="allowance"
            required
            name="allowance"
            label="Аттестация/Допуски"
            onChange={formik.handleChange}
            value={formik.values.allowance}
            checked={formik.values.allowance}
          />

          <Row className="mb-3">
            <Col xs={12} md={12}>
              <Form.Group controlId="recruitingAdditionalRequirements">
                <Form.Label>Доп. требования</Form.Label>
                <Form.Control
                  name="additionalRequirements"
                  onChange={formik.handleChange}
                  value={formik.values.additionalRequirements}
                  isValid={
                    formik.touched.additionalRequirements && !formik.errors.additionalRequirements
                  }
                  isInvalid={!!formik.errors.additionalRequirements}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.additionalRequirements}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div className="recruiting-form__title">
            <h5>Условия</h5>
            <hr />
          </div>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Тип трудовых отношений *</Form.Label>

                <FormikSelect
                  name="relations"
                  value={formik.values.relations}
                  form={formik}
                  options={[
                    { value: 'PARTTIME', label: 'Срочный трудовой договор' },
                    { value: 'TIMELESS', label: 'Бессрочный трудовой договор' },
                  ]}
                  placeholder="Выберите тип трудовых отношений"
                  isError={formik.errors.relations}
                />

                {formik.errors.relations && (
                  <div className="invalid-feedback d-block">{formik.errors.relations}</div>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>График работы *</Form.Label>

                <FormikSelect
                  name="workType"
                  value={formik.values.workType}
                  form={formik}
                  options={[
                    { value: 'SHIFT', label: 'Сменный график' },
                    { value: 'FLEXIBLE', label: 'Гибкий график' },
                    { value: 'REMOTE', label: 'Удаленная работа' },
                    { value: 'TOUR', label: 'Вахтовый метод' },
                    { value: 'FULLTIME', label: 'Полный день' },
                  ]}
                  placeholder="Выберите график работы"
                  isError={formik.errors.workType}
                />

                {formik.errors.workType && (
                  <div className="invalid-feedback d-block">{formik.errors.workType}</div>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group controlId="recruitinglLiving">
                <Form.Label>Проживание *</Form.Label>
                <Form.Control
                  name="living"
                  onChange={formik.handleChange}
                  value={formik.values.living}
                  isValid={formik.touched.living && !formik.errors.living}
                  isInvalid={!!formik.errors.living}
                />

                <Form.Control.Feedback type="invalid">{formik.errors.living}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group>
            <Form.Check
              id="supply"
              required
              name="supply"
              label="Питание"
              onChange={formik.handleChange}
              value={formik.values.supply}
              checked={formik.values.supply}
            />
          </Form.Group>

          <Form.Group>
            <Form.Check
              id="compensation"
              required
              name="compensation"
              label="Компенсация проезда до места работы и обратно"
              onChange={formik.handleChange}
              value={formik.values.compensation}
              checked={formik.values.compensation}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              id="relocationHelp"
              required
              name="relocationHelp"
              label="Организация переезда с семьёй"
              onChange={formik.handleChange}
              value={formik.values.relocationHelp}
              checked={formik.values.relocationHelp}
            />
          </Form.Group>

          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="recruitingSalaryFrom" className="mb-3">
                <Form.Label>Окладная часть от (до вычета налогов) *</Form.Label>
                <Form.Control
                  type="number"
                  name="salaryFrom"
                  onChange={formik.handleChange}
                  value={formik.values.salaryFrom}
                  isValid={formik.touched.salaryFrom && !formik.errors.salaryFrom}
                  isInvalid={!!formik.errors.salaryFrom}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.salaryFrom}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <Form.Group controlId="recruitingSalaryTo">
                <Form.Label>Окладная часть до (до вычета налогов) *</Form.Label>
                <Form.Control
                  type="number"
                  name="salaryTo"
                  onChange={formik.handleChange}
                  value={formik.values.salaryTo}
                  isValid={formik.touched.salaryTo && !formik.errors.salaryTo}
                  isInvalid={!!formik.errors.salaryTo}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.salaryTo}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={12}>
              <Form.Group controlId="recruitingSocial">
                <Form.Label>Базовый соц. пакет *</Form.Label>
                <Form.Control
                  as="textarea"
                  name="social"
                  onChange={formik.handleChange}
                  value={formik.values.social}
                  isValid={formik.touched.social && !formik.errors.social}
                  isInvalid={!!formik.errors.social}
                />

                <Form.Control.Feedback type="invalid">{formik.errors.social}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={12}>
              <Form.Group controlId="recruitingGuarantee">
                <Form.Label>Дополнительные льготы и гарантии *</Form.Label>
                <Form.Control
                  as="textarea"
                  name="guarantee"
                  onChange={formik.handleChange}
                  value={formik.values.guarantee}
                  isValid={formik.touched.guarantee && !formik.errors.guarantee}
                  isInvalid={!!formik.errors.guarantee}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.guarantee}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div className="recruiting-form__title">
            <h5>Процедура принятия решения по кандидату</h5>
            <hr />
          </div>

          <Form.Group controlId="recruitingInterviewer" className="mb-3">
            <Form.Label>Лицо, проводящее собеседование (ФИО, должность) *</Form.Label>
            <Form.Control
              name="interviewer"
              onChange={formik.handleChange}
              value={formik.values.interviewer}
              isValid={formik.touched.interviewer && !formik.errors.interviewer}
              isInvalid={!!formik.errors.interviewer}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.interviewer}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="recruitingEmployer" className="mb-3">
            <Form.Label>Лицо, принимающее решение о найме кандидата (ФИО, должность) *</Form.Label>
            <Form.Control
              name="employer"
              onChange={formik.handleChange}
              value={formik.values.employer}
              isValid={formik.touched.employer && !formik.errors.employer}
              isInvalid={!!formik.errors.employer}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.employer}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="recruitingContacts" className="mb-3">
            <Form.Label>Контактное лицо по заявке (ФИО, контактные данные) *</Form.Label>
            <Form.Control
              name="contacts"
              onChange={formik.handleChange}
              value={formik.values.contacts}
              isValid={formik.touched.contacts && !formik.errors.contacts}
              isInvalid={!!formik.errors.contacts}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.contacts}</Form.Control.Feedback>
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditRecruiting;
