import React from 'react';

import { Table, Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetTargetGroupsQuery,
  useCreateTargetGroupMutation,
  useUpdateTargetGroupMutation,
  useDeleteTargetGroupMutation,
} from '../../../../services/targetGroups';

import CreateModal from '../../../../components/Modals/TargetGroups/Create';
import DeleteModal from '../../../../components/Modals/TargetGroups/Delete';
import EditModal from '../../../../components/Modals/TargetGroups/Edit';

import AlertMessage from '../../../../components/AlertMessage';

import './TargetGroups.scss';

const TargetGroups = () => {
  const { data: targetGroups, isLoading } = useGetTargetGroupsQuery();
  const [createTargetGroup, { isError: isErrorCreate }] = useCreateTargetGroupMutation();
  const [updateTargetGroup, { isError: isErrorUpdate }] = useUpdateTargetGroupMutation();
  const [deleteTargetGroup, { isError: isErrorDelete }] = useDeleteTargetGroupMutation();

  const [target, setTarget] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createTargetGroup(values)
      .unwrap()
      .then((response) => {
        setMessage(`Целевая аудитория "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить целевую аудиторию. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (target) => {
    setTarget(target);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateTargetGroup({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Целевая аудитория "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить целевую аудиторию. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (target) => {
    setTarget(target);
    setDeleteMessage(
      `Вы уверены, что хотите удалить целевую аудиторию ${
        targetGroups.content.find((x) => x.id === target.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteTargetGroup(id)
      .unwrap()
      .then(() => {
        setMessage(
          `Целевая аудитория "${
            targetGroups.content.find((x) => x.id === id).name
          }" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить целевую аудиторию "${
            targetGroups.content.find((x) => x.id === id).name
          }".  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Целевые аудитории';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        target={target}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        target={target}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-target-groups my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Целевая аудитория</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
            Добавить целевую аудиторию
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <Table bordered responsive className="text-center">
              <thead className="align-middle">
                <tr>
                  <th>Название</th>
                  <th>Действия</th>
                </tr>
              </thead>

              <tbody className="align-middle">
                {targetGroups.content.map((target) => (
                  <tr key={target.id}>
                    <td>{target.name}</td>
                    <td>
                      <Button
                        variant="link"
                        className="p-0 mx-2"
                        onClick={() => modalShowEdit(target)}>
                        <FaEdit size="1.1em" />
                      </Button>

                      <Button
                        variant="link"
                        className="p-0 mx-2"
                        onClick={() => modalShowDelete(target)}>
                        <FaTrashAlt size="1.1em" color="red" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </section>
    </>
  );
};

export default TargetGroups;
