import React from 'react';

import { useGetResumesQuery } from '../../../services/resumes';
import { Link } from 'react-router-dom';

const EmployerResumes = () => {
  const { data: resumes, isLoadingResumes } = useGetResumesQuery();

  return (
    <>
      {!isLoadingResumes && (
        <div className="resumes mb-3">
          {resumes?.content?.map((x) => (
            <div className="resumes-item" key={x.id}>
              <div className="resumes-item__author">
                От {x.firstName} {x.lastName} {x.secondName}
              </div>

              <div className="resumes-item__educations">
                <h6>Образование - {x.educationLevel}</h6>

                {resumes?.content?.educations?.map((education) => (
                  <p>
                    {education?.organization}, {education?.name}, {education?.endDate}
                  </p>
                ))}
              </div>

              <Link to={`/resumes/${x.id}`} className="btn btn-outline-primary w-100">
                Подробнее
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default EmployerResumes;
