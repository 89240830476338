import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from 'react-bootstrap';
import { FaBurn, FaFireAlt, FaRegFrown, FaRubleSign } from 'react-icons/fa';

import { removeProgram, clearProgram } from '../../redux/slices/selectProgramsSlice';

import useAuth from '../../hooks/useAuth';
import axios from '../../axios';

import logo from '../../assets/img/logo.jpg';

const SelectModules = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuth } = useAuth();

  const selectedPrograms = useSelector((state) => state.selectPrograms.items);
  const onClose = () => setShow(false);

  const onSubmit = () => {
    if (!isAuth) {
      onClose();
      navigate('/login', { replace: true, state: { from: location } });
      return;
    }

    try {
      axios
        .post(
          '/constructor',
          JSON.stringify({
            modules: selectedPrograms,
          }),
        )
        .then((response) => {
          if (response.status === 201) {
            onClose();
            dispatch(clearProgram());
            //setMessage(`Запрос успешно сформирован и отправлен.`)
          }
        })
        .catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered={true}
      size="xl"
      backdrop="static"
      scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title>Выбранные модули</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {selectedPrograms && selectedPrograms.length > 0 ? (
          <div className="selected-programs">
            {selectedPrograms.map((obj) => (
              <div className="selected-programs__item" key={obj.id}>
                <img className="selected-programs__item-img" src={logo} alt="COPP logo" />

                <div className="selected-programs__item-content">
                  <h5 className="selected-programs__item-name">{obj.name}</h5>
                  <p className="selected-programs__item-competention">
                    <FaBurn />
                    {obj.competentionName}
                  </p>
                  <p className="selected-programs__item-profoblast">
                    <FaFireAlt />
                    {obj.profoblast}
                  </p>
                  <p className="selected-programs__item-price">
                    <FaRubleSign /> {obj?.themes?.reduce((a, b) => a + (b['price'] || 0), 0)}
                  </p>
                </div>

                <Button
                  variant="default"
                  className="btn-close position-absolute end-0"
                  onClick={() => dispatch(removeProgram(obj.name))}
                />
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="text-center display-1">{<FaRegFrown />}</div>
            <p className="text-center display-6 mt-3">Вы не выбрали модули.</p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={onClose}>
          Отмена
        </Button>

        <Button variant="outline-primary" onClick={onSubmit}>
          Сформировать запрос на обучение
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectModules;
