import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import AlertMessage from '../../components/AlertMessage';

import axios from '../../axios';

import './Teaching.scss';

function Teaching() {
  const [message, setMessage] = React.useState(null);
  const [isError, setIsError] = React.useState(false);

  const submitCreate = (values) => {
    try {
      axios
        .post('/request-form', JSON.stringify(values))
        .then(() => setMessage(`Заявка успешно отправлена.`))
        .catch((err) => {
          setIsError(true);
          setMessage(
            `Не удалось отправить заявку. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
          );
        });
    } catch (error) {
      setIsError(true);
      console.log(error);
    }
  };

  const initialValues = {
    direction: '',
    quantity: 1,
    terms: false,
  };

  const validationSchema = Yup.object({
    direction: Yup.string().required('Укажите желаемое направление обучения'),
    quantity: Yup.number().moreThan(0, 'Должно быть больше 0').required('Обязательное поле'),
    terms: Yup.boolean().oneOf([true], 'Подтвердите согласие'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  React.useEffect(() => {
    document.title = 'ЦОПП - Заявка на обучение';
  }, []);

  return (
    <section className="section section-teaching my-5">
      <div className="container">
        <div className="section-title">
          <h1>Заявка на обучение</h1>
          <hr />
        </div>

        {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

        <div className="teaching">
          <Form onSubmit={formik.handleSubmit} noValidate>
            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group controlId="teachingDirection">
                  <Form.Label>Желаемое направление обучения</Form.Label>
                  <Form.Control
                    name="direction"
                    onChange={formik.handleChange}
                    value={formik.values.direction}
                    isValid={formik.touched.direction && !formik.errors.direction}
                    isInvalid={!!formik.errors.direction}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.direction}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={12} md={6}>
                <Form.Group controlId="teachingQuantity">
                  <Form.Label>Количество участников</Form.Label>
                  <Form.Control
                    name="quantity"
                    type="number"
                    min="1"
                    onChange={formik.handleChange}
                    value={formik.values.quantity}
                    isValid={formik.touched.quantity && !formik.errors.quantity}
                    isInvalid={!!formik.errors.quantity}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.quantity}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Check
                id="terms"
                required
                name="terms"
                label="Я подтверждаю свое согласие на сбор, обработку и хранение персональных данных"
                onChange={formik.handleChange}
                value={formik.values.terms}
                isInvalid={!!formik.errors.terms}
                feedback={formik.errors.terms}
                feedbackType="invalid"
              />
            </Form.Group>

            <button className="btn btn-outline-primary w-100">Подать заявку</button>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default Teaching;
