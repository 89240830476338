import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormikSelect } from '../../FormikSelect';

const EditModal = ({ module, themes, competentions, showEdit, submitEdit, modalCloseEdit }) => {
  const { id, name, competentionName, availableInConstructor } = module;

  const initialValues = {
    name: name || '',
    competention: competentionName
      ? competentions.find((x) => x.name === competentionName).id
      : -1 || -1,
    themes: module.themes ? module.themes.map((x) => x.id ?? x) : [] || [],
    availableInConstructor: availableInConstructor || false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательное поле'),
    competention: Yup.number().moreThan(-1, 'Выберите компетенцию').required('Обязательное поле'),
    themes: Yup.array().min(1, 'Выберите темы').required('Выберите темы'),
    availableInConstructor: Yup.boolean().required(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование модуля</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="moduleName" className="mb-3">
              <Form.Label>Наименование *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="moduleCompetention" className="mb-3">
              <Form.Label>Компетенция *</Form.Label>
              <FormikSelect
                name="competention"
                value={formik.values.competention}
                form={formik}
                options={competentions.map((x) => ({ value: x.id, label: x.name }))}
                placeholder="Выберите компетенцию"
                isError={formik.errors.competention}
              />

              {formik.errors.competention && (
                <div className="invalid-feedback d-block">{formik.errors.themes}</div>
              )}
            </Form.Group>
          </Row>

          <Row className="align-items-center">
            <Form.Group as={Col} controlId="moduleThemes" className="mb-3">
              <Form.Label>Темы *</Form.Label>
              <FormikSelect
                name="themes"
                value={formik.values.themes}
                form={formik}
                options={themes.map((x) => ({ value: x.id, label: x.name }))}
                isMulti
                placeholder="Выберите темы"
                isError={formik.errors.themes}
              />

              {formik.errors.themes && (
                <div className="invalid-feedback d-block">{formik.errors.themes}</div>
              )}
            </Form.Group>

            <Form.Group as={Col} className="mt-3">
              <Form.Check
                type="switch"
                id="switch-availableInConstructor"
                name="availableInConstructor"
                onChange={formik.handleChange}
                value={formik.values.availableInConstructor}
                checked={formik.values.availableInConstructor}
                label="Доступно в кострукторе"
              />
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
