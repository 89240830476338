import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormikSelect } from '../../FormikSelect';

const CreateModal = ({ programs, submitCreate, showCreate, modalCloseCreate }) => {
  const initialValues = {
    name: '',
    endDate: '',
    startDate: '',
    program: -1,
    users: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле'),
    endDate: Yup.date().required('Обязательно поле'),
    startDate: Yup.date().required('Обязательно поле'),
    program: Yup.number().moreThan(-1, 'Выберите программу'),
    users: Yup.array(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление учебной группы</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group className="mb-3">
            <Form.Label>Наименование *</Form.Label>
            <Form.Control
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Программа *</Form.Label>
            <FormikSelect
              name="program"
              value={formik.values.program}
              form={formik}
              options={programs.map((x) => ({ value: x.id, label: x.name }))}
              placeholder="Выберите программу"
              isError={formik.errors.program}
            />

            {formik.errors.program && (
              <div className="invalid-feedback d-block">{formik.errors.program}</div>
            )}
          </Form.Group>

          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>Дата начала *</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                onChange={formik.handleChange}
                value={formik.values.startDate}
                isValid={formik.touched.startDate && !formik.errors.startDate}
                isInvalid={!!formik.errors.startDate}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} className="mb-3">
              <Form.Label>Дата окончания *</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                onChange={formik.handleChange}
                value={formik.values.endDate}
                isValid={formik.touched.endDate && !formik.errors.endDate}
                isInvalid={!!formik.errors.endDate}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.endDate}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
