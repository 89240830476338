import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const BookModal = ({ submitBook, showBook, modalCloseBook }) => {
  const initialValues = {
    date: '',
    hours: 1,
  };

  const validationSchema = Yup.object({
    date: Yup.date().required('Обязательно поле'),
    hours: Yup.number().min(1, 'Минимальная продолжительность 1 час').required('Обязательно поле'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitBook(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showBook}
      onHide={modalCloseBook}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Бронирование аудитории</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="bookingDate">
              <Form.Label>Дата бронирования *</Form.Label>
              <Form.Control
                type="datetime-local"
                name="date"
                onChange={formik.handleChange}
                value={formik.values.date}
                isValid={formik.touched.date && !formik.errors.date}
                isInvalid={!!formik.errors.date}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="bookingDuration">
              <Form.Label>Продолжительность (часов)</Form.Label>
              <Form.Control
                type="number"
                min="1"
                name="hours"
                onChange={formik.handleChange}
                value={formik.values.hours}
                isValid={formik.touched.hours && !formik.errors.hours}
                isInvalid={!!formik.errors.hours}
              />
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Забронировать
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BookModal;
