import React from 'react';

import { Modal, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateModal = ({ submitCreate, showCreate, modalCloseCreate }) => {
  const [cities, setСities] = React.useState([]);
  const [city, setCity] = React.useState({ name: '' });

  const initialValues = {
    name: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле'),
    // cities: Yup.array().min(1, 'Введите города').required('Введите города'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate({ ...values, cities });
      resetForm({});
    },
  });

  return (
    <Modal show={showCreate} onHide={modalCloseCreate} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Добавление района</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>
              Наименование <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              name="name"
              placeholder="Наименование"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="city">
            <Form.Label>
              Города <span className="text-danger">*</span>
            </Form.Label>
            <FormikSelect
              name="cities"
              value={formik.values.cities}
              form={formik}
              options={cities?.content?.map((city) => ({
                value: city.id,
                label: city.name,
              }))}
              isMulti
              placeholder="Выберите города"
              isError={formik.errors.cities}
            />

            {formik.errors.cities && (
              <div className="invalid-feedback d-block">{formik.errors.cities}</div>
            )}
          </Form.Group> */}

          <Row className="align-items-center mb-3">
            <Col xs={'auto'}>
              <h5 className="mb-0">Города</h5>
            </Col>

            <Col>
              <InputGroup>
                <Form.Control
                  value={city.name}
                  onChange={(e) => setCity({ name: e.target.value })}
                  placeholder="Город"
                  aria-label="Город"
                />
                <Button
                  variant="success"
                  onClick={() => {
                    if (
                      city.name.trim() &&
                      cities.findIndex((x) => x.name === city.name.trim()) === -1
                    ) {
                      setСities([...cities, city]);
                      setCity({ name: '' });
                    }
                  }}>
                  +
                </Button>
              </InputGroup>
            </Col>
          </Row>

          {cities.length > 0 && (
            <ul>
              {cities.map((x, i) => (
                <li key={i} className="mb-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{x.name}</div>
                    <Button
                      variant="danger"
                      onClick={() => setСities(cities.filter((_) => _.name !== x.name))}>
                      x
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          )}

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
