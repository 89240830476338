import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const requestsAPI = createApi({
  reducerPath: 'requestsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Requests'],
  endpoints: (builder) => ({
    getRequests: builder.query({
      query: (query) => `admin/requests/?page=${query.page}&requestFrom=${query.from}`,
      providesTags: () => ['Requests'],
    }),
    updateRequest: builder.mutation({
      query: ({ id, status }) => ({
        url: `admin/requests/${id}`,
        method: 'PUT',
        body: status,
      }),
      invalidatesTags: () => ['Requests'],
    }),

    getEmployeeRequests: builder.query({
      query: ({ page, role }) => `${role}/employee-requests/?page=${page}&size=20`,
      providesTags: () => ['EmployeeRequests'],
    }),
    getEmployeeRequestById: builder.query({
      query: (id) => `admin/employee-requests/${id}`,
      providesTags: () => ['EmployeeRequests'],
    }),
    addEmployeeRequests: builder.mutation({
      query: (body) => ({
        url: `employer/employee-requests/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['EmployeeRequests'],
    }),
    updateEmployeeRequests: builder.mutation({
      query: ({ role, id, body }) => ({
        url: `${role}/employee-requests/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['EmployeeRequests'],
    }),
  }),
});

export const {
  useGetRequestsQuery,
  useUpdateRequestMutation,
  useGetEmployeeRequestsQuery,
  useGetEmployeeRequestByIdQuery,
  useAddEmployeeRequestsMutation,
  useUpdateEmployeeRequestsMutation,
} = requestsAPI;
