import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormikSelect } from '../../FormikSelect';
import { fileResizeAndUpload } from '../../../fileResizeAndUpload';

const CreateModal = ({
  setImage,
  roomTypes,
  buildings,
  teachers,
  competentions,
  submitCreate,
  showCreate,
  modalCloseCreate,
}) => {
  const initialValues = {
    name: '',
    hire: false,
    seats: 1,
    roomType: -1,
    teachers: [],
    building: -1,
    equipments: [],
    competentions: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле'),
    hire: Yup.boolean(),
    seats: Yup.number().moreThan(0, 'Должно быть больше 0').required('Обязательно поле'),
    roomType: Yup.number().moreThan(-1, 'Выберите тип площадки'),
    teachers: Yup.array(),
    building: Yup.number().moreThan(-1, 'Выберите здание').required('Обязательно поле'),
    equipments: Yup.array(),
    competentions: Yup.array(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    await fileResizeAndUpload(file, setImage);
  };

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление площадки</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="roomsName" className="mb-3">
              <Form.Label>Наименование *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="roomsRoomType" className="mb-3">
              <Form.Label>Тип площадки *</Form.Label>
              <Form.Select
                name="roomType"
                onChange={formik.handleChange}
                value={formik.values.roomType}
                isValid={formik.touched.roomType && !formik.errors.roomType}
                isInvalid={!!formik.errors.roomType}>
                <option value="-1">Выберите тип площадки</option>
                {roomTypes.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.roomType}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="roomsBuilding" className="mb-3">
              <Form.Label>Здание *</Form.Label>
              <Form.Select
                name="building"
                onChange={formik.handleChange}
                value={formik.values.building}
                isValid={formik.touched.building && !formik.errors.building}
                isInvalid={!!formik.errors.building}>
                <option value="-1">Выберите здание</option>
                {buildings.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.building}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="roomsName" className="mb-3">
              <Form.Label>Количество оснащенных рабочих мест *</Form.Label>
              <Form.Control
                type="number"
                min="0"
                name="seats"
                onChange={formik.handleChange}
                value={formik.values.seats}
                isValid={formik.touched.seats && !formik.errors.seats}
                isInvalid={!!formik.errors.seats}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.seats}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="roomsCompetentions" className="mb-3">
              <Form.Label>Компетенции</Form.Label>

              <FormikSelect
                name="competentions"
                value={formik.values.competentions}
                form={formik}
                options={competentions.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                isMulti={true}
                placeholder="Выберите компетенции"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="organizationPhone" className="mb-3">
              <Form.Label>Учителя-эксперты площадки</Form.Label>
              <FormikSelect
                name="teachers"
                value={formik.values.teachers}
                form={formik}
                options={teachers.map((x) => ({
                  value: x.id,
                  label: `${x.lastName} ${x.firstName} ${x.secondName}`,
                }))}
                isMulti={true}
                placeholder="Выберите пользователей"
              />
            </Form.Group>
          </Row>

          <Form.Group as={Col} className="mb-3">
            <Form.Check
              type="switch"
              id="switch-hire"
              name="hire"
              onChange={formik.handleChange}
              value={formik.values.hire}
              label="Доступно для бронирования"
            />
          </Form.Group>

          <Form.Group controlId="roomsImage" className="mb-3">
            <Form.Label>Изображение</Form.Label>
            <Form.Control type="file" name="image" onChange={handleFileUpload} />
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
