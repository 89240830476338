import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderEventsDetail = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={635}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="50" />
    <rect x="0" y="70" rx="5" ry="5" width="100%" height="120" />
    <rect x="0" y="210" rx="5" ry="5" width="100%" height="80" />
    <rect x="0" y="310" rx="5" ry="5" width="60%" height="70" />
    <rect x="0" y="400" rx="5" ry="5" width="15%" height="70" />
    <rect x="0" y="490" rx="5" ry="5" width="30%" height="70" />
    <rect x="0" y="580" rx="5" ry="5" width="100%" height="50" />
  </ContentLoader>
);

export default LoaderEventsDetail;
