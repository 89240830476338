import React from 'react';

import { Table, Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetRegionsQuery,
  useCreateRegionMutation,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
} from '../../../../services/regions';

import CreateModal from '../../../../components/Modals/Regions/Create';
import DeleteModal from '../../../../components/Modals/Regions/Delete';
import EditModal from '../../../../components/Modals/Regions/Edit';

import AlertMessage from '../../../../components/AlertMessage';

import './Regions.scss';

const Regions = () => {
  const { data: regions, isLoadingRegions } = useGetRegionsQuery();
  const [createRegion, { isError: isErrorCreate }] = useCreateRegionMutation();
  const [updateRegion, { isError: isErrorUpdate }] = useUpdateRegionMutation();
  const [deleteRegion, { isError: isErrorDelete }] = useDeleteRegionMutation();

  const [type, setType] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createRegion(values)
      .unwrap()
      .then((response) => {
        setMessage(`Район успешно добавлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить район.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (type) => {
    setType(type);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateRegion({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Район успешно изменен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить район.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (type) => {
    setType(type);
    setDeleteMessage(
      `Вы уверены, что хотите удалить район ${regions.content.find((x) => x.id === type.id).name}?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteRegion(id)
      .unwrap()
      .then(() => {
        setMessage(`Район "${regions.content.find((x) => x.id === id).name}" успешно удалён.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить район "${regions.content.find((x) => x.id === id).name}".  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Районы';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      {/* <EditModal
        type={type}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        type={type}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      /> */}

      <section className="section section-program-types my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Районы</h1>
            <hr />

            <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
              Добавить район
            </Button>
          </div>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoadingRegions ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <Table bordered responsive className="text-center">
              <thead className="align-middle">
                <tr>
                  <th>Наименование</th>
                  <th>Города и т.д</th>
                  <th>Действия</th>
                </tr>
              </thead>

              <tbody className="align-middle">
                {regions?.content?.map((obj) => (
                  <tr key={obj.id}>
                    <td>{obj.name}</td>
                    <td>{obj.cities?.map((x) => x.name).join(', ')}</td>
                    <td>
                      <Button
                        variant="link"
                        className="p-0 mx-2"
                        onClick={() => modalShowEdit(obj)}>
                        <FaEdit size="1.1em" />
                      </Button>

                      <Button
                        variant="link"
                        className="p-0 mx-2"
                        onClick={() => modalShowDelete(obj)}>
                        <FaTrashAlt size="1.1em" color="red" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </section>
    </>
  );
};

export default Regions;
