import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import { Col, Form, Row } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { fetchRegister } from '../../redux/slices/authSlice';
import { useGetUserTypesQuery } from '../../services/userTypes';

import { FormikSelect } from '../../components/FormikSelect';
import AlertMessage from '../../components/AlertMessage';

import useAuth from '../../hooks/useAuth';

import soglasie from '../../assets/docs/soglasie.pdf';

import './Registration.scss';
import PhoneInput from 'react-phone-input-2';

import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';

const Registration = () => {
  const dispatch = useDispatch();

  const { isAuth } = useAuth();

  const [message, setMessage] = React.useState(null);

  const { data: userTypes } = useGetUserTypesQuery();

  const initialValues = {
    firstName: '',
    lastName: '',
    secondName: '',
    phone: '',
    birthdate: '',
    email: '',
    sex: '',
    city: '',
    region: '',
    type: -1,
    password: '',
    confirmPassword: '',
    confirm: false,
  };

  const onCityChange = (target) => {
    formik.setFieldValue('city', `${target.data.city_type}. ${target.data.city}`);
    if (target.data.area_with_type) {
      formik.setFieldValue('region', target.data.area_with_type);
    }
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Укажите имя'),
    lastName: yup.string().required('Укажите фамилию'),
    secondName: yup.string(),
    phone: yup.string().required('Укажите телефон'),
    city: yup.string().required('Укажите город'),
    region: yup.string().required('Укажите муниципальный район'),
    birthdate: yup.string().required('Укажите дату рождения'),
    sex: yup.string().oneOf(['MEN', 'WOMEN'], 'Выберите пол').required('Выберите пол'),
    type: yup
      .number()
      .moreThan(-1, 'Укажите тип пользователя')
      .required('Укажите тип пользователя'),
    email: yup.string().required('Укажите email'),
    password: yup.string().required('Укажите пароль').min(6, 'Минимальная длина 6'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Укажите пароль'),
    confirm: yup.bool().required().oneOf([true], 'Подтвердите согласие'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      values.type = { id: values.type };

      const data = await dispatch(fetchRegister(values));
      if (!data.payload) {
        setMessage('При регистрации что-то пошло не так, пожалуйста, попробуйте ещё раз.');
      }

      resetForm({});

      if (data.payload.access) {
        localStorage.setItem('accessToken', data.payload.access);
        localStorage.setItem('refreshToken', data.payload.refresh);
      }
    },
  });

  React.useEffect(() => {
    document.title = 'ЦОПП - Регистрация';
  }, []);

  if (isAuth) {
    return <Navigate to="/profile" replace />;
  }

  return (
    <section className="section section-registration my-5">
      <div className="container">
        <div className="section-title">
          <h1>Регистрация</h1>
          <hr />
        </div>

        {message && <AlertMessage message={message} setMessage={setMessage} isError={true} />}

        <Form noValidate onSubmit={formik.handleSubmit} className="form-registration">
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="lastName">
              <Form.Label>Фамилия *</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                isValid={formik.touched.lastName && !formik.errors.lastName}
                isInvalid={!!formik.errors.lastName}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="firstName">
              <Form.Label>Имя *</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isValid={formik.touched.firstName && !formik.errors.firstName}
                isInvalid={!!formik.errors.firstName}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="secondName">
              <Form.Label>Отчество</Form.Label>
              <Form.Control
                type="text"
                name="secondName"
                value={formik.values.secondName}
                onChange={formik.handleChange}
                isValid={formik.touched.secondName && !formik.errors.secondName}
                isInvalid={!!formik.errors.secondName}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.secondName}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="3" controlId="email">
              <Form.Label>EMail *</Form.Label>
              <Form.Control
                autoComplete="off"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={!!formik.errors.email}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="phone">
              <Form.Label>Телефон *</Form.Label>
              <PhoneInput
                inputProps={{
                  name: 'phone',
                }}
                country="ru"
                value={formik.values.phone}
                specialLabel={null}
                placeholder="7 (xxx) xxx-xx-xx"
                onChange={(value, country, e, formattedValue) => formik.handleChange(e)}
              />
              {/* <Form.Control
                type="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                isValid={formik.touched.phone && !formik.errors.phone}
                isInvalid={!!formik.errors.phone}
              /> */}

              <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="birthdate">
              <Form.Label>Дата рождения *</Form.Label>
              <Form.Control
                type="date"
                name="birthdate"
                value={formik.values.birthdate}
                onChange={formik.handleChange}
                isValid={formik.touched.birthdate && !formik.errors.birthdate}
                isInvalid={!!formik.errors.birthdate}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.birthdate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="sex">
              <Form.Label>Пол *</Form.Label>
              <FormikSelect
                name="sex"
                value={formik.values.sex}
                form={formik}
                options={[
                  { value: 'MEN', label: 'Мужской' },
                  { value: 'WOMEN', label: 'Женский' },
                ]}
                placeholder="Пол"
                isError={formik.errors.sex}
              />

              {formik.errors.sex && (
                <div className="invalid-feedback d-block">{formik.errors.sex}</div>
              )}
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="city">
              <Form.Label>Город *</Form.Label>

              <AddressSuggestions
                delay={300}
                uid="city"
                token="31be362f27c2a488e22048fb8e00082cb6743da3"
                value={formik.values.city}
                onChange={onCityChange}
              />

              {formik.errors.city && (
                <div className="invalid-feedback d-block">{formik.errors.city}</div>
              )}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="region">
              <Form.Label>Муниципальный район *</Form.Label>
              <Form.Control
                autoComplete="off"
                name="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                isValid={formik.touched.region && !formik.errors.region}
                isInvalid={!!formik.errors.region}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.region}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="password">
              <Form.Label>Пароль *</Form.Label>
              <Form.Control
                autoComplete="off"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                isValid={formik.touched.password && !formik.errors.password}
                isInvalid={!!formik.errors.password}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="confirmPassword">
              <Form.Label>Подтверждение пароля *</Form.Label>
              <Form.Control
                autoComplete="off"
                type="password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                isValid={formik.touched.confirmPassword && !formik.errors.confirmPassword}
                isInvalid={!!formik.errors.confirmPassword}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="type">
            <Form.Label>Тип пользователя *</Form.Label>
            <FormikSelect
              name="type"
              value={formik.values.type}
              form={formik}
              options={userTypes?.content?.map((x) => ({ value: x.id, label: x.name }))}
              placeholder="Укажите тип пользователя"
              isError={formik.errors.type}
            />

            {formik.errors.type && (
              <div className="invalid-feedback d-block">{formik.errors.type}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              required
              name="confirm"
              label={
                <a href={soglasie} target="_blank" rel="noopener noreferrer">
                  Я подтверждаю свое согласие на сбор, обработку и хранение персональных данных
                </a>
              }
              onChange={formik.handleChange}
              isInvalid={!!formik.errors.confirm}
              feedback={formik.errors.confirm}
              feedbackType="invalid"
              id="confirm"
            />
          </Form.Group>

          <button type="submit" className="btn btn-outline-primary w-100">
            Зарегистрироваться
          </button>

          <p className="text-center m-0 mt-3">
            Уже есть аккаунт? <Link to="/login">Авторизуйтесь</Link>
          </p>
        </Form>
      </div>
    </section>
  );
};

export default Registration;
