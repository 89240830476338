import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const eventTypesAPI = createApi({
  reducerPath: 'eventTypesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['EventTypes'],
  endpoints: (builder) => ({
    getEventTypes: builder.query({
      query: (size = 20) => `services/event-types/?size=${size}`,
      providesTags: () => ['EventTypes'],
    }),

    createEventType: builder.mutation({
      query: (body) => ({
        url: '/admin/event-types/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['EventTypes'],
    }),

    updateEventType: builder.mutation({
      query: (body) => ({
        url: `admin/event-types/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['EventTypes'],
    }),

    deleteEventType: builder.mutation({
      query: (id) => ({
        url: `admin/event-types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['EventTypes'],
    }),
  }),
});

export const {
  useGetEventTypesQuery,
  useCreateEventTypeMutation,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation,
} = eventTypesAPI;
