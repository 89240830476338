import React from 'react';
import { useSearchParams } from 'react-router-dom';

import debounce from 'lodash.debounce';

import { FaCheck, FaEdit, FaMinusCircle, FaSearch, FaTrashAlt } from 'react-icons/fa';

import { Table, Button, InputGroup, Form } from 'react-bootstrap';

import {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from '../../../../services/users';

import { useGetUserTypesQuery } from '../../../../services/userTypes';
import { useGetRolesQuery } from '../../../../services/roles';
import { useGetOrganizationsQuery } from '../../../../services/organizations';

import CreateModal from '../../../../components/Modals/Users/Create';
import EditModal from '../../../../components/Modals/Users/Edit';
import DeleteModal from '../../../../components/Modals/Users/Delete';

import AlertMessage from '../../../../components/AlertMessage';
import PaginationCustom from '../../../../components/PaginationCustom';

const Users = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const [search, setSearch] = React.useState('');
  const setSearchText = (text) => {
    setSearch(text);
  };

  const deferredSearch = React.useDeferredValue(search);

  const usersQuery = {
    size: 20,
    page: page,
    type: 0,
    name: deferredSearch,
    role: 'admin',
  };

  const organizationsQuery = {
    role: 'admin',
    page: 0,
    size: 300,
  };

  const { data: users, isLoadingUsers } = useGetUsersQuery(usersQuery);
  const [createUser, { isError: isErrorCreate }] = useCreateUserMutation();
  const [updateUser, { isError: isErrorUpdate }] = useUpdateUserMutation();
  const [deleteUser, { isError: isErrorDelete }] = useDeleteUserMutation();

  const { data: userTypes, isLoadingUserTypes } = useGetUserTypesQuery();
  const { data: roles } = useGetRolesQuery('admin');
  const { data: organizations } = useGetOrganizationsQuery(organizationsQuery);

  const [user, setUser] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.organization = values.organization > 0 ? { id: values.organization } : null;
    values.type = values.type > 0 ? { id: values.type } : null;
    values.role = values.role > 0 ? { id: values.role } : null;

    createUser({ role: 'admin', body: values })
      .unwrap()
      .then((response) => {
        setMessage(
          `Пользователь "${response.lastName} ${response.firstName} ${response.secondName}" успешно добавлен.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить пользователя. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setUser(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.organization = values.organization > 0 ? { id: values.organization } : null;
    values.type = values.type > 0 ? { id: values.type } : null;
    values.role = values.role > 0 ? { id: values.role } : null;

    updateUser({ role: 'admin', body: { id, ...values } })
      .unwrap()
      .then((response) => {
        setMessage(
          `Пользователь "${response.lastName} ${response.firstName} ${response.secondName}" успешно изменен.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить пользователя. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setUser(obj);
    setDeleteMessage(
      `Вы уверены, что хотите пользователя ${obj?.lastName} ${obj?.firstName} ${obj?.secondName}?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteUser({ role: 'admin', id })
      .unwrap()
      .then((response) => {
        const deleteUser = users.content.find((x) => x.id === response);
        setMessage(
          `Пользователь "${deleteUser.lastName} ${deleteUser.firstName} ${deleteUser.secondName}" успешно удален.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить пользователя. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Пользователи';
  }, []);

  return (
    <>
      <CreateModal
        userTypes={userTypes?.content ?? []}
        roles={roles?.content ?? []}
        organizations={organizations?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
        isAdmin={true}
      />

      <EditModal
        userTypes={userTypes?.content ?? []}
        roles={roles?.content ?? []}
        organizations={organizations?.content ?? []}
        user={user}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        user={user}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-user-types my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Пользователи</h1>
            <hr />

            <InputGroup className="mb-3">
              <Form.Control
                className="py-2"
                placeholder="Поиск..."
                aria-label="Поиск..."
                value={search}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Button variant="danger" onClick={() => setSearchText('')}>
                <FaMinusCircle />
              </Button>

              {/* <Button variant="danger" onClick={() => setSearchText(search)}>
                <FaCheck />
              </Button> */}
            </InputGroup>

            {deferredSearch}

            <Button variant="outline-primary" className="w-100 mb-2" onClick={modalShowCreate}>
              Добавить пользователя
            </Button>

            <Button
              variant="outline-primary"
              className="w-100 mb-4"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL}/statistics/users/`)
              }>
              Выгрузить статистику
            </Button>
          </div>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoadingUsers || isLoadingUserTypes ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    <th>ФИО</th>
                    <th>Тип пользователя</th>
                    <th>Действия</th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {users?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>
                        {obj.lastName} {obj.firstName} {obj.secondName}
                      </td>

                      <td>
                        {obj.type?.name ??
                          userTypes?.content?.find((x) => x.id === obj.type)?.name ??
                          'отсутствует'}
                      </td>
                      <td>
                        <Button variant="link" onClick={() => modalShowEdit(obj)}>
                          <FaEdit />
                        </Button>

                        <Button variant="link" onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={users?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Users;
