import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

import logo from '../../assets/img/logo.jpg';
import useAuth from '../../hooks/useAuth';

import './Header.scss';

const Header = () => {
  const [show, setShow] = React.useState(false);

  const { isAuth, isUser, isAdmin, isManager, isEmployer } = useAuth();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Управление</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="navbar-light">
              <h6>Планирование</h6>
              <Nav className="navbar-nav">
                {isAdmin && (
                  <Link to="/timetable-lessons" className="nav-link px-0" onClick={handleClose}>
                    Расписание занятий
                  </Link>
                )}

                <Link to="/programs-table" className="nav-link px-0" onClick={handleClose}>
                  Программы
                </Link>
                <Link to="/modules" className="nav-link px-0" onClick={handleClose}>
                  Модули
                </Link>
                <Link to="/themes" className="nav-link px-0" onClick={handleClose}>
                  Темы
                </Link>

                {isAdmin && (
                  <Link to="/study-groups" className="nav-link px-0" onClick={handleClose}>
                    Учебные группы
                  </Link>
                )}

                {(isAdmin || isManager) && (
                  <Link to="/events" className="nav-link px-0" onClick={handleClose}>
                    Мероприятия
                  </Link>
                )}
              </Nav>
            </Col>

            <Col className="navbar-light">
              <h6>Аккаунты</h6>
              <Nav className="navbar-nav">
                {isAdmin ? (
                  <>
                    <Link to="/users" className="nav-link px-0" onClick={handleClose}>
                      Пользователи
                    </Link>
                    <Link to="/teachers" className="nav-link px-0" onClick={handleClose}>
                      Преподаватели
                    </Link>
                    <Link to="/employers" className="nav-link px-0" onClick={handleClose}>
                      Работодатели
                    </Link>
                    <Link to="/user-types" className="nav-link px-0" onClick={handleClose}>
                      Типы пользователей
                    </Link>
                    <Link to="/roles" className="nav-link px-0" onClick={handleClose}>
                      Роли
                    </Link>
                  </>
                ) : (
                  <Link to="/teachers" className="nav-link px-0" onClick={handleClose}>
                    Преподаватели
                  </Link>
                )}
              </Nav>
            </Col>

            {isAdmin && (
              <Col className="navbar-light">
                <h6>Запросы</h6>
                <Nav className="navbar-nav">
                  <Link to="/requests-constructor" className="nav-link px-0" onClick={handleClose}>
                    Запросы из конструктора
                  </Link>

                  <Link to="/requests-program" className="nav-link px-0" onClick={handleClose}>
                    Запросы из программ
                  </Link>

                  <Link to="/requests-teaching" className="nav-link px-0" onClick={handleClose}>
                    Запросы на обучение
                  </Link>

                  <Link to="/requests-recruiting" className="nav-link px-0" onClick={handleClose}>
                    Запросы на подбор персонала
                  </Link>

                  <Link to="/requests-booking" className="nav-link px-0" onClick={handleClose}>
                    Запросы на бронирование
                  </Link>
                </Nav>
              </Col>
            )}

            <Col className="navbar-light">
              <h6>Организация</h6>
              <Nav className="navbar-nav">
                <Link to="/organizations" className="nav-link px-0" onClick={handleClose}>
                  Организации
                </Link>

                {isAdmin && (
                  <Link to="/managers" className="nav-link px-0" onClick={handleClose}>
                    Менеджеры организаций
                  </Link>
                )}

                <Link to="/buildings" className="nav-link px-0" onClick={handleClose}>
                  Здания
                </Link>
                <Link to="/rooms" className="nav-link px-0" onClick={handleClose}>
                  Площадки
                </Link>
                <Link to="/equipments" className="nav-link px-0" onClick={handleClose}>
                  Средства обучения
                </Link>
              </Nav>
            </Col>

            {isAdmin && (
              <Col className="navbar-light">
                <h6>Прочее</h6>
                <Nav className="navbar-nav">
                  <Link to="/program-types" className="nav-link px-0" onClick={handleClose}>
                    Вид программ
                  </Link>
                  <Link to="/program-subspecies" className="nav-link px-0" onClick={handleClose}>
                    Подвид программ
                  </Link>
                  <Link to="/target-groups" className="nav-link px-0" onClick={handleClose}>
                    Целевая аудитория
                  </Link>
                  <Link to="/study-formats" className="nav-link px-0" onClick={handleClose}>
                    Форма проведения
                  </Link>
                  <Link to="/professional-objects" className="nav-link px-0" onClick={handleClose}>
                    Профессиональные области
                  </Link>
                  <Link to="/competentions" className="nav-link px-0" onClick={handleClose}>
                    Компетенции
                  </Link>
                  <Link to="/event-types" className="nav-link px-0" onClick={handleClose}>
                    Тип мероприятий
                  </Link>
                  <Link to="/room-types" className="nav-link px-0" onClick={handleClose}>
                    Тип площадки
                  </Link>

                  {/* <Link to="/cities" className="nav-link px-0" onClick={handleClose}>
                    Города
                  </Link> */}

                  <Link to="/regions" className="nav-link px-0" onClick={handleClose}>
                    Районы
                  </Link>
                </Nav>
              </Col>
            )}
          </Row>
        </Modal.Body>
      </Modal>

      <Navbar expand="lg">
        <Container fluid className="justify-content-center">
          <Link to="/" className="navbar-brand py-0">
            <img src={logo} alt="" className="logo" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="flex-grow-0">
            <Nav>
              <Link to="/vacancy" className="nav-link">
                Вакансии
              </Link>

              {isManager && (
                <>
                  <Link to="/resumes" className="nav-link">
                    Резюме
                  </Link>
                </>
              )}

              {isEmployer && (
                <>
                  <Link to="/resumes" className="nav-link">
                    Резюме
                  </Link>
                  <Link to="/responses" className="nav-link">
                    Отклики
                  </Link>
                </>
              )}

              {isUser && (
                <Link to="/responses" className="nav-link">
                  Отклики
                </Link>
              )}

              <Link to="/success-story" className="nav-link">
                Истории успеха
              </Link>

              <Link to="/educations" className="nav-link">
                Конструктор программ
              </Link>
              {!isAdmin && !isManager && (
                <>
                  <Link to="/programs" className="nav-link">
                    Программы
                  </Link>
                  <Link to="/events" className="nav-link">
                    Мероприятия
                  </Link>
                  <Link to="/booking" className="nav-link">
                    Бронирование
                  </Link>
                  <Link to="/teaching" className="nav-link">
                    Подать заявку на обучение
                  </Link>
                </>
              )}

              {isEmployer && (
                <Link to="/recruiting" className="nav-link">
                  Подать заявку на подбор персонала
                </Link>
              )}

              {(isAdmin || isManager) && (
                <button className="btn btn-link nav-link text-left" onClick={handleShow}>
                  Управление
                </button>
              )}
            </Nav>
            <Nav>
              {isAuth ? (
                <Link to="/profile" className="nav-link">
                  Профиль
                </Link>
              ) : (
                <Link to="/login" className="nav-link">
                  Войти
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
