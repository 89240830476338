import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const statsAPI = createApi({
  reducerPath: 'statsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Stats'],
  endpoints: (builder) => ({
    getStats: builder.query({
      query: () => `stats`,
      providesTags: () => ['Stats'],
    }),

    createStats: builder.mutation({
      query: (body) => ({
        url: `admin/stats/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Stats'],
    }),

    updateStats: builder.mutation({
      query: (body) => ({
        url: `admin/stats/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Stats'],
    }),

    deleteStats: builder.mutation({
      query: (id) => ({
        url: `admin/stats/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Stats'],
    }),
  }),
});

export const {
  useGetStatsQuery,
  useCreateStatsMutation,
  useUpdateStatsMutation,
  useDeleteStatsMutation,
} = statsAPI;
