import React from 'react';

import { Modal, Form } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormikSelect } from '../../FormikSelect';
import { fileResizeAndUpload } from '../../../fileResizeAndUpload';

const CreateModal = ({ setImage, competentions, submitCreate, showCreate, modalCloseCreate }) => {
  const initialValues = {
    name: '',
    competentions: [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Обязательно поле'),
      competentions: Yup.array().min(1, 'Выберите компетенции'),
    }),
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    await fileResizeAndUpload(file, setImage);
  };

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление профессиональной области</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group controlId="professionalObjectName" className="mb-3">
            <Form.Label>Наименование *</Form.Label>
            <Form.Control
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Компетенции *</Form.Label>

            {
              <FormikSelect
                name="competentions"
                value={formik.values.competentions}
                form={formik}
                options={competentions.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                isMulti
                placeholder="Выберите компетенции"
                isError={formik.errors.competentions}
              />
            }

            {formik.errors.competentions && (
              <div className="invalid-feedback d-block">{formik.errors.competentions}</div>
            )}
          </Form.Group>

          <Form.Group controlId="professionalObjectImage" className="mb-3">
            <Form.Label>Изображение *</Form.Label>
            <Form.Control
              type="file"
              name="image"
              onChange={handleFileUpload}
              isInvalid={!!formik.errors.image}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.image}</Form.Control.Feedback>
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
