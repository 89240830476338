import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const programSubspeciesAPI = createApi({
  reducerPath: 'programSubspeciesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['ProgramSubspecies'],
  endpoints: (builder) => ({
    getProgramSubspecies: builder.query({
      query: () => 'services/subspecies/',
      providesTags: () => ['ProgramSubspecies'],
    }),

    createProgramSubspecies: builder.mutation({
      query: (body) => ({
        url: '/admin/subspecies/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['ProgramSubspecies'],
    }),

    updateProgramSubspecies: builder.mutation({
      query: (body) => ({
        url: `admin/subspecies/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['ProgramSubspecies'],
    }),

    deleteProgramSubspecies: builder.mutation({
      query: (id) => ({
        url: `admin/subspecies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['ProgramSubspecies'],
    }),
  }),
});

export const {
  useGetProgramSubspeciesQuery,
  useCreateProgramSubspeciesMutation,
  useUpdateProgramSubspeciesMutation,
  useDeleteProgramSubspeciesMutation,
} = programSubspeciesAPI;
