import React from 'react';

import { Modal, Form } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateModal = ({ submitCreate, showCreate, modalCloseCreate }) => {
  const initialValues = {
    name: '',
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Обязательно поле'),
    }),
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal show={showCreate} onHide={modalCloseCreate} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Добавление формы проведения</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group className="mb-3">
            <Form.Control
              name="name"
              placeholder="Название"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
