import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

const selectProgramsSlice = createSlice({
  name: 'selectPrograms',
  initialState,
  reducers: {
    addProgram(state, action) {
      state.items.push(action.payload);
    },
    removeProgram(state, action) {
      state.items = state.items.filter((item) => item.name !== action.payload);
    },
    clearProgram(state) {
      state.items = [];
    },
  },
});

export const { addProgram, removeProgram, clearProgram } = selectProgramsSlice.actions;

export default selectProgramsSlice.reducer;
