import React from 'react';

import { Modal, Form } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const Feedback = ({ submitFeedback, showFeedback, modalCloseFeedback }) => {
  const initialValues = {
    name: '',
    contact: '',
    text: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Укажите ФИО'),
    contact: Yup.string().required('Укажите контактные данные'),
    text: Yup.string().required('Укажите текст'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitFeedback(values);
      resetForm({});
    },
  });

  return (
    <>
      <Modal
        show={showFeedback}
        onHide={modalCloseFeedback}
        backdrop="static"
        keyboard={false}
        centered
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Форма обратной связи</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit} noValidate>
            <Form.Group controlId="managerLastName" className="mb-3">
              <Form.Label>ФИО *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="userEmail" className="mb-3">
              <Form.Label>EMail или телефон *</Form.Label>
              <Form.Control
                name="contact"
                onChange={formik.handleChange}
                value={formik.values.contact}
                isValid={formik.touched.contact && !formik.errors.contact}
                isInvalid={!!formik.errors.contact}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.contact}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="userPassword" className="mb-3">
              <Form.Label>Текст *</Form.Label>
              <Form.Control
                as="textarea"
                name="text"
                onChange={formik.handleChange}
                value={formik.values.text}
                isValid={formik.touched.text && !formik.errors.text}
                isInvalid={!!formik.errors.text}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.text}</Form.Control.Feedback>
            </Form.Group>

            <button type="submit" className="btn btn-outline-primary w-100">
              Отправить
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Feedback;
