import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateModal = ({ organizations, submitCreate, showCreate, modalCloseCreate }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    secondName: '',
    sex: '',
    birthdate: '',
    email: '',
    phone: '',
    password: '',
    organization: -1,
    confirm: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Укажите имя'),
    lastName: Yup.string().required('Укажите фамилию'),
    secondName: Yup.string(),
    sex: Yup.string().oneOf(['MEN', 'WOMEN'], 'Выберите пол').required('Выберите пол'),
    birthdate: Yup.date().required('Укажите дату рождения'),
    email: Yup.string().email().required('Укажите email'),
    phone: Yup.string().required('Укажите телефон'),
    password: Yup.string().required('Укажите пароль').min(6),
    organization: Yup.number(-1, 'Выберите организацию'),
    confirm: Yup.bool().required().oneOf([true], 'Подтвердите согласие'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление менеджера организации</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="managerLastName" className="mb-3">
              <Form.Label>Фамилия *</Form.Label>
              <Form.Control
                name="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                isValid={formik.touched.lastName && !formik.errors.lastName}
                isInvalid={!!formik.errors.lastName}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerFirstName" className="mb-3">
              <Form.Label>Имя *</Form.Label>
              <Form.Control
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                isValid={formik.touched.firstName && !formik.errors.firstName}
                isInvalid={!!formik.errors.firstName}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerSecondName" className="mb-3">
              <Form.Label>Отчество</Form.Label>
              <Form.Control
                name="secondName"
                onChange={formik.handleChange}
                value={formik.values.secondName}
                isValid={formik.touched.secondName && !formik.errors.secondName}
                isInvalid={!!formik.errors.secondName}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.secondName}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="managerEmail" className="mb-3">
              <Form.Label>Электронная почта *</Form.Label>
              <Form.Control
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={!!formik.errors.email}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerPhone" className="mb-3">
              <Form.Label>Телефон *</Form.Label>
              <Form.Control
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                isValid={formik.touched.phone && !formik.errors.phone}
                isInvalid={!!formik.errors.phone}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerSex" className="mb-3">
              <Form.Label>Пол *</Form.Label>
              <Form.Select
                name="sex"
                onChange={formik.handleChange}
                value={formik.values.sex}
                isValid={formik.touched.sex && !formik.errors.sex}
                isInvalid={!!formik.errors.sex}>
                <option value="nosex">Выберите пол</option>
                <option value="MEN">Мужской</option>
                <option value="WOMEN">Женский</option>
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.sex}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerBirthDate" className="mb-3">
              <Form.Label>Дата рождения *</Form.Label>
              <Form.Control
                type="date"
                name="birthdate"
                onChange={formik.handleChange}
                value={formik.values.birthdate}
                isValid={formik.touched.birthdate && !formik.errors.birthdate}
                isInvalid={!!formik.errors.birthdate}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.birthdate}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group controlId="managerPassword" className="mb-3">
            <Form.Label>Пароль *</Form.Label>
            <Form.Control
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              isValid={formik.touched.password && !formik.errors.password}
              isInvalid={!!formik.errors.password}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="managerOrganization" className="mb-3">
            <Form.Label>Организация *</Form.Label>
            <Form.Select
              name="organization"
              onChange={formik.handleChange}
              value={formik.values.organization}
              isValid={formik.touched.organization && !formik.errors.organization}
              isInvalid={!!formik.errors.organization}>
              <option value="-1">Выберите организацию</option>
              {organizations.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </Form.Select>

            <Form.Control.Feedback type="invalid">
              {formik.errors.organization}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} className="mb-3">
            <Form.Check
              name="confirm"
              label="Я подтверждаю свое согласие на сбор, обработку и хранение персональных данных"
              onChange={formik.handleChange}
              isInvalid={!!formik.errors.confirm}
              feedback={formik.errors.confirm}
              feedbackType="invalid"
              id="confirm"
            />
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
