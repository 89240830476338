import React from 'react';

import Education from '../../components/Education';
import LoaderEducations from '../../components/Loaders/Educations';
import SelectModules from '../../components/Modals/SelectModules';

import axios from '../../axios';

import './Education.scss';

const Educations = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [educations, setEducations] = React.useState([]);

  const [show, setShow] = React.useState(false);
  const onModalOpen = () => setShow(true);

  React.useEffect(() => {
    setIsLoading(true);

    document.title = 'ЦОПП - Заявка на обучение';

    try {
      axios
        .get('/constructor/')
        .then((response) => {
          setEducations(response.data.content);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  }, []);

  return (
    <>
      <SelectModules show={show} setShow={setShow} />

      <section className="section section-educations my-5">
        <div className="container">
          <div className="section-title">
            <h1>Заявка на обучение</h1>
            <hr />
          </div>

          <div className="education-wrapper">
            <button className="education-request" onClick={onModalOpen}>
              Сформировать запрос
            </button>

            <div className="educations">
              {isLoading
                ? [...new Array(6)].map((_, index) => <LoaderEducations key={index} />)
                : educations.map((obj) => <Education key={obj.id} {...obj} />)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Educations;
