import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const responsesAPI = createApi({
  reducerPath: 'responsesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Responses'],
  endpoints: (builder) => ({
    getResponses: builder.query({
      query: (role) => `${role}/responses/`,
      providesTags: () => ['Responses'],
    }),

    inviteFromResume: builder.mutation({
      query: (body) => ({
        url: `employer/responses/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['Responses'],
    }),
    respondToVacancy: builder.mutation({
      query: (body) => ({
        url: `user/responses/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['Responses'],
    }),

    changeStatusResponse: builder.mutation({
      query: (body) => ({
        url: `employer/responses/${body.id}`,
        method: 'PUT',
        body: body.status,
      }),
      invalidatesTags: () => ['Responses'],
    }),
  }),
});

export const {
  useGetResponsesQuery,
  useInviteFromResumeMutation,
  useRespondToVacancyMutation,
  useChangeStatusResponseMutation,
} = responsesAPI;
