import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const resumesAPI = createApi({
  reducerPath: 'resumesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Resumes'],
  endpoints: (builder) => ({
    getResumes: builder.query({
      query: () => 'resumes',
      providesTags: () => ['Resumes'],
    }),
    getResumeById: builder.query({
      query: (id) => `resumes/${id}`,
      providesTags: () => ['Resumes'],
    }),
  }),
});

export const { useGetResumesQuery, useGetResumeByIdQuery } = resumesAPI;
