import React from 'react';

import { FaCubes } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { useGetProgramByIdQuery, useSignUpProgramMutation } from '../../../services/programs';

import LoaderProgramsDetail from '../../../components/Loaders/ProgramsDetail';
import ModuleItem from '../../../components/Programm/Module/ModuleItem';

import AlertMessage from '../../../components/AlertMessage';

import useAuth from '../../../hooks/useAuth';

import './Detail.scss';

const ProgramsDetail = () => {
  const { isAuth } = useAuth();
  const { id } = useParams();

  const { data: program, isLoading } = useGetProgramByIdQuery({ id });
  const [signUpProgram, { isError }] = useSignUpProgramMutation();

  const [message, setMessage] = React.useState(null);

  const handleSignUpProgram = (program) => {
    signUpProgram(program)
      .unwrap()
      .then(() => setMessage('Вы успешно записались на программу.'))
      .catch((err) => {
        setMessage('При записи произошла ошибка, пожалуйста, попробуйте позже.');
      });
  };

  return (
    <section className="section section-programms__detail my-5">
      <div className="container">
        {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

        {isLoading ? (
          <LoaderProgramsDetail />
        ) : (
          <div className="programm-detail">
            <h4 className="programm-detail__title">{program?.name}</h4>

            <div className="programm-detail__annotation">
              <span>Аннотация:</span> {program?.description}
            </div>

            <div className="programm-detail__type">
              <span>Вид программы:</span> {program?.type?.name}
            </div>

            <div className="programm-detail__audience">
              <span>Целевая аудитория:</span> {program?.target ? program?.target?.name : ''}
            </div>

            <hr />

            <div className="programm-detail__modules-wrapper">
              <h5>{<FaCubes />} Модули</h5>
              <div className="programm-detail__modules">
                {program?.modules?.map((obj) => (
                  <ModuleItem key={obj.id} {...obj} />
                ))}
              </div>
            </div>

            {isAuth && (
              <Button
                variant="outline-primary mt-4"
                className="w-100"
                onClick={() => handleSignUpProgram(program)}>
                Записаться
              </Button>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default ProgramsDetail;
