import { Alert } from 'react-bootstrap';

const AlertMessage = ({ message, setMessage, isError }) => {
  return (
    <Alert
      variant={isError ? 'danger' : 'success'}
      className="text-center"
      onClose={() => setMessage(null)}
      dismissible>
      {message}
    </Alert>
  );
};

export default AlertMessage;
