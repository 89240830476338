import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const programTypesAPI = createApi({
  reducerPath: 'programTypesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['ProgramTypes'],
  endpoints: (builder) => ({
    getProgramTypes: builder.query({
      query: () => 'services/program-types/',
      providesTags: () => ['ProgramTypes'],
    }),

    createProgramType: builder.mutation({
      query: (body) => ({
        url: '/admin/program-types/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['ProgramTypes'],
    }),

    updateProgramType: builder.mutation({
      query: (body) => ({
        url: `admin/program-types/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['ProgramTypes'],
    }),

    deleteProgramType: builder.mutation({
      query: (id) => ({
        url: `admin/program-types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['ProgramTypes'],
    }),
  }),
});

export const {
  useGetProgramTypesQuery,
  useCreateProgramTypeMutation,
  useUpdateProgramTypeMutation,
  useDeleteProgramTypeMutation,
} = programTypesAPI;
