import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const profileAPI = createApi({
  reducerPath: 'profileAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Profile'],
  endpoints: (builder) => ({
    getUserRequests: builder.query({
      query: () => `user/requests`,
      providesTags: () => ['Profile'],
    }),

    getUserGroups: builder.query({
      query: () => `user/groups/`,
      providesTags: () => ['Profile'],
    }),

    getUserEvents: builder.query({
      query: () => `user/events/`,
      providesTags: () => ['Profile'],
    }),
  }),
});

export const { useGetUserRequestsQuery, useGetUserGroupsQuery, useGetUserEventsQuery } = profileAPI;
