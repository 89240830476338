import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Form, Row, Col } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Logo from '../../assets/img/logo.jpg';

import AlertMessage from '../../components/AlertMessage';

import axios from 'axios';

import './ResetPassword.scss';

const ResetPassword = () => {
  const navigate = useNavigate();

  const { token } = useParams();

  const [message, setMessage] = React.useState(null);
  const [isError, setIsError] = React.useState(false);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string().required('Укажите пароль').min(6, 'Минимальная длина 6'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Укажите пароль'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetPassword(values);
      resetForm({});
    },
  });

  const resetPassword = async (values) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/reset-password/${token}`,
      values.password,
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      },
    );

    if (response.status === 200) {
      navigate('/login', { replace: true });
    } else {
      setMessage(`Что-то пошло не так, попробуйте чуть позже.`);
      setIsError(true);
    }
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Сброс пароля';
  }, []);

  return (
    <>
      <section className="section section-login">
        <div className="container">
          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          <div className="auth">
            <Row className="align-items-center">
              <Col className="text-center">
                <img src={Logo} alt="Logo COPP" />
              </Col>

              <Col>
                <Form className="auth-form" noValidate onSubmit={formik.handleSubmit}>
                  <Form.Group className="auth-form__group mb-3">
                    <FaLock />
                    <Form.Control
                      type="password"
                      placeholder="Пароль"
                      name="password"
                      autoComplete="off"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      isValid={formik.touched.password && !formik.errors.password}
                      isInvalid={formik.errors.password}
                    />

                    <Form.Control.Feedback type="invalid">
                      {formik.errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="auth-form__group mb-3">
                    <FaLock />
                    <Form.Control
                      type="password"
                      placeholder="Повтор пароля"
                      name="confirmPassword"
                      autoComplete="off"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      isValid={formik.touched.confirmPassword && !formik.errors.confirmPassword}
                      isInvalid={formik.errors.confirmPassword}
                    />

                    <Form.Control.Feedback type="invalid">
                      {formik.errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button variant="outline-success" type="submit" className="w-100">
                    Обновить
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
