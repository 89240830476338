import { FaAngleRight, FaChevronRight, FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import './News.scss';

const News = ({ obj, modalShowEdit, modalShowDelete, isAdmin = false }) => {
  const { id, image, name, description } = obj;
  return (
    <div className="news-item">
      {image && (
        <div className="news-img">
          <img src={`${process.env.REACT_APP_API_URL}${image.src}`} alt={name} />
        </div>
      )}

      <div className="news-content">
        <h4 className="news-title">{name}</h4>
        <p className="news-description">{description.slice(0, 500) + '...'}</p>

        <Link to={`/news/${id}`} className="news-btn mt-3 text-center">
          Подробнее <FaChevronRight className="ms-1" size="0.75em" />
        </Link>
      </div>

      {isAdmin && (
        <div className="news-btns d-flex align-items">
          <Link to={`/news/${id}`} className="btn btn-outline-success w-100">
            <FaEye size="3em" />
          </Link>

          <button className="btn btn-outline-primary w-100" onClick={() => modalShowEdit(obj)}>
            <FaEdit size="3em" />
          </button>

          <button className="btn btn-outline-danger w-100" onClick={() => modalShowDelete(obj)}>
            <FaTrashAlt size="3em" />
          </button>
        </div>
      )}
    </div>
  );
};

export default News;
