import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const organizationsAPI = createApi({
  reducerPath: 'organizationsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Organizations'],
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: ({ role, page = 0, size = 20 }) =>
        role === 'admin'
          ? `${role}/organizations/?page=${page}&size=${size}`
          : `${role}/organization/`,
      providesTags: () => ['Organizations'],
    }),

    createOrganization: builder.mutation({
      query: ({ role, body }) => ({
        url: role === 'admin' ? `${role}/organizations/` : `${role}/organization/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Organizations'],
    }),

    updateOrganization: builder.mutation({
      query: ({ role, body }) => ({
        url: role === 'admin' ? `${role}/organizations/${body.id}` : `${role}/organization`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Organizations'],
    }),

    deleteOrganization: builder.mutation({
      query: ({ role, id }) => ({
        url: role === 'admin' ? `${role}/organizations/${id}` : `${role}/organization/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Organizations'],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
} = organizationsAPI;
