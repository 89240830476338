import React from 'react';

import './NotFound.scss';

const NotFound = () => {
  React.useEffect(() => {
    document.title = 'ЦОПП - 404';
  }, []);

  return (
    <section className="section section-not-found">
      <div className="not-found">
        <div className="container">
          <h2 className="title">404</h2>
          <p className="description">Страница не найдена.</p>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
