import Resizer from 'react-image-file-resizer';

export const fileResizeAndUpload = (file, setImage) => {
  try {
    Resizer.imageFileResizer(
      file,
      1280,
      720,
      'WEBP',
      90,
      0,
      (uri) => {
        setImage({ name: file.name, file: uri });
      },
      'base64',
    );
  } catch (err) {
    console.log(err);
  }
};
