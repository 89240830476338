import { Container, Row, Col } from 'react-bootstrap';

import { FaVk, FaFacebookF, FaInstagram } from 'react-icons/fa';

import logoCopp from '../../assets/img/logo.jpg';
import logoKuzbass from '../../assets/img/logo_kuzbass.png';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col className="text-center">
            <div className="mb-3">
              <p>+7 (3842) 57-11-20</p>
              <p>Телефон поддержки</p>
            </div>
            <div className="mb-3">
              <p>copp42@yandex.ru</p>
              <p>E-MAIL</p>
            </div>
            {/* <div className="mb-3">
              <p>
                <a href="http://copp42.ru/" target="_blank">
                  http://copp42.ru/
                </a>
              </p>
              <p>Сайт</p>
            </div> */}
          </Col>
          <Col className="text-center">
            <img src={logoKuzbass} alt="" className="logo-kuzbass" />
          </Col>
          <Col className="text-center">
            <img src={logoCopp} alt="" className="logo-copp" />
          </Col>
          <Col className="text-center">
            <p className="mb-3">Информация</p>
            <a href="https://vk.com/copp42kuzbass" target="_blank" className="footer-btn vk">
              <FaVk /> VK
            </a>
            {/* <a href="#" target="_blank" className="footer-btn fb">
              <FaFacebookF /> Facebook
            </a> */}
            {/* <a href="#" target="_blank" className="footer-btn inst">
              <FaInstagram /> Instagram
            </a> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
