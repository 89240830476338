import React from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetEmployeeRequestByIdQuery,
  useUpdateEmployeeRequestsMutation,
} from '../../../../services/requests';
import { FaEdit } from 'react-icons/fa';
import EditRecruiting from '../../../../components/Modals/Requests/EditRecruiting';
import AlertMessage from '../../../../components/AlertMessage';

const STATUS = {
  WAITING: 'На рассмотрении',
  ACCEPTED: 'Одобрена',
  DECLINE: 'Отклонена',
  SEARCHING: 'Идёт набор на обучение специалистов',
  TEACHING: 'Готовятся специалисты',
};

const RequestRecruitingById = () => {
  const { id } = useParams();

  const { data: request, isLoading } = useGetEmployeeRequestByIdQuery(id);
  const [updateRequest, { isError }] = useUpdateEmployeeRequestsMutation();

  const [message, setMessage] = React.useState(null);
  const [showEdit, setShowEdit] = React.useState(false);

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = () => setShowEdit(true);

  const submitEdit = (id, body) => {
    body.organization = { id: body.organization };
    updateRequest({ role: 'admin', id, body: { id, ...body } })
      .unwrap()
      .then((response) => {
        setMessage(`Заявка на подбор персонала успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось изменить заявку. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
    modalCloseEdit();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Запрос на подбор персонала';
  }, []);

  return (
    <>
      <section className="section section-requests__recruiting min-height my-5">
        <div className="container">
          <div className="section-title">
            <h1>Запрос на подбор персонала</h1>
            <hr />
          </div>

          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <EditRecruiting
                request={request}
                showEdit={showEdit}
                submitEdit={submitEdit}
                modalCloseEdit={modalCloseEdit}
                isAdmin
              />

              <p className="mb-3">
                <span className="text-uppercase text-secondary fs-7">Статус заявки: </span>
                {STATUS[request?.requestStatus]}
              </p>

              <p className="mb-0">
                <span className="text-uppercase text-secondary fs-7">Наименование должности: </span>
                {request?.position}
              </p>
              <p className="mb-0">
                <span className="text-uppercase text-secondary fs-7">
                  Количество штатных единиц:{' '}
                </span>
                {request?.quantity}
              </p>
              <p className="mb-3">
                <span className="text-uppercase text-secondary fs-7">
                  Место работы (субъект, область, город, улица, дом):{' '}
                </span>
                {request?.city}
              </p>

              <p className="mb-0">
                <span className="text-uppercase text-secondary fs-7">
                  Наименование структурного подразделения (Юр.лицо/Трест/; Служба/Управление;
                  Отдел/Сектор):{' '}
                </span>
                {request?.subdivision}
              </p>
              <p className="mb-0">
                <span className="text-uppercase text-secondary fs-7">
                  Наличие открытой ставки в штатном расписании:{' '}
                </span>
                {request?.openState ? 'Да' : 'Нет'}
              </p>
              <p className="mb-3">
                <span className="text-uppercase text-secondary fs-7">Трудовая мобилизация: </span>
                {request?.workMobilisation ? 'Да' : 'Нет'}
              </p>

              <p className="mb-0">
                <span className="text-uppercase text-secondary fs-7">
                  Функциональные обязанности :{' '}
                </span>
                {request?.charge}
              </p>
              <p className="mb-0">
                <span className="text-uppercase text-secondary fs-7">Гражданство : </span>
                {request?.nationality}
              </p>
              <p className="mb-0">
                <span className="text-uppercase text-secondary fs-7">
                  Образование(уровень, квалификация):{' '}
                </span>
                {request?.educationLevel}
              </p>
              <p className="mb-0">
                <span className="text-uppercase text-secondary fs-7">Опыт работы: </span>
                {request?.experience}
              </p>
              <p className="mb-3">
                <span className="text-uppercase text-secondary fs-7">
                  Необходимые проф. знания и навыки:{' '}
                </span>
                {request?.requiredSkills}
              </p>

              <p className="mb-3">
                <span className="text-uppercase text-secondary fs-7">
                  Контактное лицо по заявке:{' '}
                </span>
                {request?.contacts}
              </p>

              <button
                className="btn btn-primary w-100 text-uppercase"
                onClick={() => modalShowEdit(request)}>
                <FaEdit size="1.1em" /> Изменить
              </button>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default RequestRecruitingById;
