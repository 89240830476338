import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Table, Button, Alert } from 'react-bootstrap';

import { FaEdit, FaTrashAlt, FaMinus } from 'react-icons/fa';

import {
  useGetThemesQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation,
  useDeleteThemeMutation,
} from '../../../../services/themes';

import { useGetStudyFormatsQuery } from '../../../../services/studyFormats';

import CreateModal from '../../../../components/Modals/Themes/Create';
import DeleteModal from '../../../../components/Modals/Themes/Delete';
import EditModal from '../../../../components/Modals/Themes/Edit';

import PaginationCustom from '../../../../components/PaginationCustom';

import useAuth from '../../../../hooks/useAuth';

import './Themes.scss';

const Themes = () => {
  const { isAdmin, data } = useAuth();

  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: themes, isLoading } = useGetThemesQuery({
    size: 20,
    page,
    role: isAdmin ? 'admin' : 'manager',
  });
  const [createTheme, { isError: isErrorCreate }] = useCreateThemeMutation();
  const [updateTheme, { isError: isErrorUpdate }] = useUpdateThemeMutation();
  const [deleteTheme, { isError: isErrorDelete }] = useDeleteThemeMutation();

  const { data: studyFormats } = useGetStudyFormatsQuery();

  const [theme, setTheme] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.format = { id: values.format };
    createTheme({
      role: isAdmin ? 'admin' : 'manager',
      body: { author: { id: data.id ? data.id : data.user.id }, ...values },
    })
      .unwrap()
      .then((response) => {
        setMessage(`Тема "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить тему.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (theme) => {
    setTheme(theme);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.format = { id: values.format };

    updateTheme({
      role: isAdmin ? 'admin' : 'manager',
      body: { id, author: { id: data.id ? data.id : data.user.id }, ...values },
    })
      .unwrap()
      .then((response) => {
        setMessage(`Тема "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить тему.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (theme) => {
    setTheme(theme);
    setDeleteMessage(
      `Вы уверены, что хотите удалить тему ${themes.content.find((x) => x.id === theme.id).name}?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteTheme({ role: isAdmin ? 'admin' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(`Тема "${themes.content.find((x) => x.id === response).name}" успешно удалёна.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить тему.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Темы';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
        studyFormats={studyFormats?.content ?? []}
      />

      <EditModal
        theme={theme}
        studyFormats={studyFormats?.content ?? []}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        theme={theme}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-themes my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Темы</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
            Добавить тему
          </Button>

          {message && (
            <Alert
              variant={isErrorCreate || isErrorUpdate || isErrorDelete ? 'danger' : 'success'}
              className="text-center"
              onClose={() => setMessage(null)}
              dismissible>
              {message}
            </Alert>
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    {/* <th>№</th> */}
                    <th>Наименование темы</th>
                    <th>Содержание темы</th>
                    <th>Продолжительность в часах</th>
                    <th>Форма проведения</th>
                    <th>Стоимость</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {themes.content.map((theme) => (
                    <tr key={theme.id}>
                      {/* <td>{theme.id}</td> */}
                      <td>{theme.name}</td>
                      <td>{theme.description ? theme.description : <FaMinus color="green" />}</td>
                      <td>{theme.duration}</td>
                      <td>{theme.format ? theme.format.name : ''}</td>
                      <td>{theme.price}</td>
                      <td>
                        <Button variant="link" onClick={() => modalShowEdit(theme)}>
                          <FaEdit />
                        </Button>
                      </td>
                      <td>
                        <Button variant="link" onClick={() => modalShowDelete(theme)}>
                          <FaTrashAlt color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={themes.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Themes;
