import { configureStore } from '@reduxjs/toolkit';

import auth from './slices/authSlice';

import selectPrograms from './slices/selectProgramsSlice';

import { newsAPI } from '../services/news';
import { statsAPI } from '../services/stats';
import { usersAPI } from '../services/users';
import { roomsAPI } from '../services/rooms';
import { rolesAPI } from '../services/roles';
import { citiesAPI } from '../services/cities';
import { eventsAPI } from '../services/events';
import { themesAPI } from '../services/themes';
import { groupsAPI } from '../services/groups';
import { resumesAPI } from '../services/resumes';
import { vacancyAPI } from '../services/vacancy';
import { bookingAPI } from '../services/booking';
import { profileAPI } from '../services/profile';
import { modulesAPI } from '../services/modules';
import { regionsAPI } from '../services/regions';
import { programsAPI } from '../services/programs';
import { managersAPI } from '../services/managers';
import { requestsAPI } from '../services/requests';
import { partnersAPI } from '../services/partners';
import { feedbackAPI } from '../services/feedback';
import { buildingsAPI } from '../services/buildings';
import { roomTypesAPI } from '../services/roomTypes';
import { userTypesAPI } from '../services/userTypes';
import { responsesAPI } from '../services/responses';
import { equipmentsAPI } from '../services/equipments';
import { eventTypesAPI } from '../services/eventTypes';
import { constructorAPI } from '../services/constructor';
import { successStoryAPI } from '../services/successStory';
import { studyFormatsAPI } from '../services/studyFormats';
import { programTypesAPI } from '../services/programTypes';
import { targetGroupsAPI } from '../services/targetGroups';
import { organizationsAPI } from '../services/organizations';
import { competentionsAPI } from '../services/competentions';
import { programSubspeciesAPI } from '../services/programSubspecies';
import { professionalObjectsAPI } from '../services/professionalObjects';

export const store = configureStore({
  reducer: {
    auth,
    selectPrograms,

    [newsAPI.reducerPath]: newsAPI.reducer,
    [statsAPI.reducerPath]: statsAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [roomsAPI.reducerPath]: roomsAPI.reducer,
    [rolesAPI.reducerPath]: rolesAPI.reducer,
    [citiesAPI.reducerPath]: citiesAPI.reducer,
    [eventsAPI.reducerPath]: eventsAPI.reducer,
    [themesAPI.reducerPath]: themesAPI.reducer,
    [groupsAPI.reducerPath]: groupsAPI.reducer,
    [resumesAPI.reducerPath]: resumesAPI.reducer,
    [vacancyAPI.reducerPath]: vacancyAPI.reducer,
    [bookingAPI.reducerPath]: bookingAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [modulesAPI.reducerPath]: modulesAPI.reducer,
    [regionsAPI.reducerPath]: regionsAPI.reducer,
    [programsAPI.reducerPath]: programsAPI.reducer,
    [managersAPI.reducerPath]: managersAPI.reducer,
    [requestsAPI.reducerPath]: requestsAPI.reducer,
    [partnersAPI.reducerPath]: partnersAPI.reducer,
    [feedbackAPI.reducerPath]: feedbackAPI.reducer,
    [buildingsAPI.reducerPath]: buildingsAPI.reducer,
    [roomTypesAPI.reducerPath]: roomTypesAPI.reducer,
    [userTypesAPI.reducerPath]: userTypesAPI.reducer,
    [responsesAPI.reducerPath]: responsesAPI.reducer,
    [equipmentsAPI.reducerPath]: equipmentsAPI.reducer,
    [eventTypesAPI.reducerPath]: eventTypesAPI.reducer,
    [constructorAPI.reducerPath]: constructorAPI.reducer,
    [successStoryAPI.reducerPath]: successStoryAPI.reducer,
    [studyFormatsAPI.reducerPath]: studyFormatsAPI.reducer,
    [programTypesAPI.reducerPath]: programTypesAPI.reducer,
    [targetGroupsAPI.reducerPath]: targetGroupsAPI.reducer,
    [organizationsAPI.reducerPath]: organizationsAPI.reducer,
    [competentionsAPI.reducerPath]: competentionsAPI.reducer,
    [programSubspeciesAPI.reducerPath]: programSubspeciesAPI.reducer,
    [professionalObjectsAPI.reducerPath]: professionalObjectsAPI.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      newsAPI.middleware,
      statsAPI.middleware,
      usersAPI.middleware,
      roomsAPI.middleware,
      rolesAPI.middleware,
      citiesAPI.middleware,
      eventsAPI.middleware,
      themesAPI.middleware,
      groupsAPI.middleware,
      resumesAPI.middleware,
      vacancyAPI.middleware,
      bookingAPI.middleware,
      profileAPI.middleware,
      modulesAPI.middleware,
      regionsAPI.middleware,
      programsAPI.middleware,
      managersAPI.middleware,
      requestsAPI.middleware,
      partnersAPI.middleware,
      feedbackAPI.middleware,
      buildingsAPI.middleware,
      roomTypesAPI.middleware,
      userTypesAPI.middleware,
      responsesAPI.middleware,
      equipmentsAPI.middleware,
      eventTypesAPI.middleware,
      constructorAPI.middleware,
      successStoryAPI.middleware,
      studyFormatsAPI.middleware,
      programTypesAPI.middleware,
      targetGroupsAPI.middleware,
      organizationsAPI.middleware,
      competentionsAPI.middleware,
      programSubspeciesAPI.middleware,
      professionalObjectsAPI.middleware,
    ),

  devTools: process.env.NODE_ENV === 'development',
});
