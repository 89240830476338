import React from 'react';

import { Link } from 'react-router-dom';

import {
  FaMapMarkerAlt,
  FaUsers,
  FaBuilding,
  FaCalendarAlt,
  FaRegClock,
  FaClock,
} from 'react-icons/fa';

import { useBookSignUpMutation } from '../../services/booking';

import BookModal from '../Modals/Booking/Book';

import logo from '../../assets/img/logo.jpg';

const formatDate = (date) => {
  const year = date[0];
  const month = date[1] < 10 ? '0' + date[1] : date[1];
  const days = date[2] < 10 ? '0' + date[2] : date[2];
  const hours = date[3] < 10 ? '0' + date[3] : date[3];
  const minutes = date[4] < 10 ? '0' + date[4] : date[4];

  return `${days}.${month}.${year}  ${hours}:${minutes}`;
};

function BookingItem({ id, name, type, image, address, seats, hours, date, isProfile = false }) {
  const [bookSignUp, { isError }] = useBookSignUpMutation(id);

  const [message, setMessage] = React.useState(null);
  const [showBook, setShowBook] = React.useState(false);

  const modalCloseBook = () => setShowBook(false);
  const modalShowBook = () => setShowBook(true);
  const submitBook = (values) => {
    bookSignUp({ room: { id: id }, ...values })
      .unwrap()
      .then(() => {
        setMessage(`Бронирование прошло успешно.`);
      })
      .catch((err) => {
        setMessage(
          `При бронировани что-то пошло не так, попробуйте позже. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseBook();
  };

  return (
    <>
      <BookModal submitBook={submitBook} showBook={showBook} modalCloseBook={modalCloseBook} />

      <div className="booking-item">
        <Link className="booking-item__wrapper" to={`/booking/${id}`}>
          {!isProfile && (
            <img
              src={image ? process.env.REACT_APP_API_URL + image.src : logo}
              alt={name}
              className="booking-img"
            />
          )}

          <div className="booking-content">
            <div className="booking-title">{name}</div>

            <div className="booking-address">
              <FaMapMarkerAlt /> Адрес: {address}
            </div>

            <div className="booking-places">
              <FaUsers /> Рабочих мест: {seats}
            </div>

            <div className="booking-type">
              <FaBuilding /> Тип площадки: {type ?? ''}
            </div>

            {isProfile && (
              <>
                <div className="booking-type">
                  <FaCalendarAlt /> Дата бронирования: {formatDate(date)}
                </div>

                <div className="booking-type">
                  <FaClock /> Продолжительноость: {hours} ч.
                </div>
              </>
            )}
          </div>
        </Link>

        {!isProfile && (
          <button className="btn btn-primary w-100" onClick={modalShowBook}>
            Забронировать
          </button>
        )}
      </div>
    </>
  );
}

export default BookingItem;
