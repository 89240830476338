import React from 'react';

import { Modal, Button } from 'react-bootstrap';

const DeleteModal = ({ target, showDelete, modalCloseDelete, submitDelete, deleteMessage }) => {
  const { id } = target;
  return (
    <Modal show={showDelete} onHide={modalCloseDelete} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Подтверждение</Modal.Title>
      </Modal.Header>
      <Modal.Body>{deleteMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={modalCloseDelete}>
          Нет
        </Button>
        <Button variant="danger" onClick={() => submitDelete(id)}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
