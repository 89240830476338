import { FaBurn } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const EducationSkillItem = ({ slug, name }) => {
  return (
    <Link className="skill-item" to={`${slug}/`}>
      <div className="skill-title">{name}</div>
      <div className="skill-type">
        <FaBurn /> Компетенция
      </div>
    </Link>
  );
};

export default EducationSkillItem;
