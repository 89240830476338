import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Table } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} from '../../../../services/groups';

import { useGetUsersQuery } from '../../../../services/users';
import { useGetProgramsQuery } from '../../../../services/programs';

import CreateModal from '../../../../components/Modals/Groups/Create';
import DeleteModal from '../../../../components/Modals/Groups/Delete';
import EditModal from '../../../../components/Modals/Groups/Edit';

import PaginationCustom from '../../../../components/PaginationCustom';
import AlertMessage from '../../../../components/AlertMessage';

import './Groups.scss';

const StudyGroups = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: groups, isLoading } = useGetGroupsQuery({
    size: 20,
    page,
  });
  const [createGroup, { isError: isErrorCreate }] = useCreateGroupMutation();
  const [updateGroup, { isError: isErrorUpdate }] = useUpdateGroupMutation();
  const [deleteGroup, { isError: isErrorDelete }] = useDeleteGroupMutation();

  const { data: users } = useGetUsersQuery({ size: 200, page: 0, role: 'admin' });
  const { data: programs } = useGetProgramsQuery({ size: 200, page: 0 });

  const [group, setGroup] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.program = { id: values.program };
    createGroup(values)
      .unwrap()
      .then((response) => {
        setMessage(`Учебная группа "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить учебную группу. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setGroup(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.program = { id: values.program };
    values.users = values.users.map((x) => ({
      id: x,
    }));

    updateGroup({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Учебная группа "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить учебную группу.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setGroup(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить учебную группу ${
        groups.content.find((x) => x.id === obj.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteGroup(id)
      .unwrap()
      .then((response) => {
        setMessage(
          `Учебная группа "${groups.content.find((x) => x.id === response).name}" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить учебную группу.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Учебные группы';
  }, []);

  return (
    <>
      <CreateModal
        programs={programs?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        group={group}
        programs={programs?.content ?? []}
        users={users?.content ?? []}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        group={group}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-groups my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Учебные группы</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
            Добавить учебную группу
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    <th>Наименование</th>
                    <th>Программа</th>
                    <th>Дата начала</th>
                    <th>Дата окончания</th>
                    <th>Обучающиеся</th>
                    <th>Действия</th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {groups.content.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.name}</td>
                      <td>{obj.program.name}</td>
                      <td>
                        {obj.startDate[2] < 10 ? '0' + obj.startDate[2] : obj.startDate[2]}.
                        {obj.startDate[1] < 10 ? '0' + obj.startDate[1] : obj.startDate[1]}.
                        {obj.startDate[0]}
                      </td>
                      <td>
                        {obj.endDate[2] < 10 ? '0' + obj.endDate[2] : obj.endDate[2]}.
                        {obj.endDate[1] < 10 ? '0' + obj.endDate[1] : obj.endDate[1]}.
                        {obj.endDate[0]}
                      </td>
                      <td>{obj.users?.map((x) => x.lastName + ' ' + x.firstName + ', ')}</td>
                      <td>
                        <Button variant="link" onClick={() => modalShowEdit(obj)}>
                          <FaEdit />
                        </Button>

                        <Button variant="link" onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={groups.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default StudyGroups;
