import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditModal = ({ equipment, rooms, showEdit, submitEdit, modalCloseEdit }) => {
  const { id, name, room, quantity, invNumber } = equipment;

  const initialValues = {
    name: name || '',
    room: room ? room.id : -1 || -1,
    quantity: quantity || 1,
    invNumber: invNumber || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле'),
    room: Yup.number().moreThan(-1, 'Выберите площадку'),
    quantity: Yup.number().moreThan(0, 'Должно быть больше 0').required('Обязательно поле'),
    invNumber: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование средства обучения</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="equipmentName" className="mb-3">
              <Form.Label>Наименование *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="equipmentRoom" className="mb-3">
              <Form.Label>Площадка *</Form.Label>
              <Form.Select
                name="room"
                onChange={formik.handleChange}
                value={formik.values.room}
                isValid={formik.touched.room && !formik.errors.room}
                isInvalid={!!formik.errors.room}>
                <option value="-1">Выберите площадку</option>
                {rooms.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.room}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="equipmentQuantity" className="mb-3">
              <Form.Label>Количество *</Form.Label>
              <Form.Control
                type="number"
                min="0"
                name="quantity"
                onChange={formik.handleChange}
                value={formik.values.quantity}
                isValid={formik.touched.quantity && !formik.errors.quantity}
                isInvalid={!!formik.errors.quantity}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.quantity}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="equipmentInvNumber" className="mb-3">
              <Form.Label>Инвентарный номер</Form.Label>
              <Form.Control
                name="invNumber"
                onChange={formik.handleChange}
                value={formik.values.invNumber}
                isValid={formik.touched.invNumber && !formik.errors.invNumber}
                isInvalid={!!formik.errors.invNumber}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.invNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
