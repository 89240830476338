import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const studyFormatsAPI = createApi({
  reducerPath: 'studyFormatsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['StudyFormats'],
  endpoints: (builder) => ({
    getStudyFormats: builder.query({
      query: () => 'services/study-formats/',
      providesTags: () => ['StudyFormats'],
    }),

    createStudyFormat: builder.mutation({
      query: (body) => ({
        url: '/admin/study-formats/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['StudyFormats'],
    }),

    updateStudyFormat: builder.mutation({
      query: (body) => ({
        url: `admin/study-formats/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['StudyFormats'],
    }),

    deleteStudyFormat: builder.mutation({
      query: (id) => ({
        url: `admin/study-formats/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['StudyFormats'],
    }),
  }),
});

export const {
  useGetStudyFormatsQuery,
  useCreateStudyFormatMutation,
  useUpdateStudyFormatMutation,
  useDeleteStudyFormatMutation,
} = studyFormatsAPI;
