import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditModal = ({
  userTypes,
  roles,
  organizations,
  user,
  showEdit,
  submitEdit,
  modalCloseEdit,
  isManager = false,
}) => {
  const {
    id,
    firstName,
    lastName,
    secondName,
    sex,
    birthdate,
    email,
    phone,
    organization,
    type,
    role,
  } = user;

  const initialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    secondName: secondName || '',
    sex: sex || '',
    birthdate: birthdate
      ? `${birthdate[0]}-${birthdate[1] < 10 ? '0' + birthdate[1] : birthdate[1]}-${
          birthdate[2] < 10 ? '0' + birthdate[2] : birthdate[2]
        }`
      : '' || '',
    email: email || '',
    phone: phone || '',
    organization: organization ? (organization.id ? organization.id : organization) : -1 || -1,
    type: type ? (type.id ? type.id : type) : -1 || -1,
    role: role ? (role.id ? role.id : role) : -1 || -1,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Укажите имя'),
    lastName: Yup.string().required('Укажите фамилию'),
    secondName: Yup.string(),
    sex: Yup.string().oneOf(['MEN', 'WOMEN'], 'Выберите пол').required('Выберите пол'),
    birthdate: Yup.date().required('Укажите дату рождения'),
    email: Yup.string().email().required('Укажите email'),
    phone: Yup.string().required('Укажите телефон'),
    // organization: Yup.number().moreThan(-1, 'Выберите организацию'),
    // type: Yup.number().moreThan(-1, 'Выберите тип пользователя'),
    // role: Yup.number().moreThan(-1, 'Выберите тип пользователя'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование пользователя</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="managerEditLastName" className="mb-3">
              <Form.Label>Фамилия *</Form.Label>
              <Form.Control
                name="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                isValid={formik.touched.lastName && !formik.errors.lastName}
                isInvalid={!!formik.errors.lastName}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerEditFirstName" className="mb-3">
              <Form.Label>Имя *</Form.Label>
              <Form.Control
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                isValid={formik.touched.firstName && !formik.errors.firstName}
                isInvalid={!!formik.errors.firstName}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerEditSecondName" className="mb-3">
              <Form.Label>Отчество</Form.Label>
              <Form.Control
                name="secondName"
                onChange={formik.handleChange}
                value={formik.values.secondName}
                isValid={formik.touched.secondName && !formik.errors.secondName}
                isInvalid={!!formik.errors.secondName}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.secondName}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="managerEditEmail" className="mb-3">
              <Form.Label>Электронная почта *</Form.Label>
              <Form.Control
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={!!formik.errors.email}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerEditPhone" className="mb-3">
              <Form.Label>Телефон *</Form.Label>
              <Form.Control
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                isValid={formik.touched.phone && !formik.errors.phone}
                isInvalid={!!formik.errors.phone}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerEditSex" className="mb-3">
              <Form.Label>Пол *</Form.Label>
              <Form.Select
                name="sex"
                onChange={formik.handleChange}
                value={formik.values.sex}
                isValid={formik.touched.sex && !formik.errors.sex}
                isInvalid={!!formik.errors.sex}>
                <option value="nosex">Выберите пол</option>
                <option value="MEN">Мужской</option>
                <option value="WOMEN">Женский</option>
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.sex}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="managerEditBirthDate" className="mb-3">
              <Form.Label>Дата рождения *</Form.Label>
              <Form.Control
                type="date"
                name="birthdate"
                onChange={formik.handleChange}
                value={formik.values.birthdate}
                isValid={formik.touched.birthdate && !formik.errors.birthdate}
                isInvalid={!!formik.errors.birthdate}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.birthdate}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Form.Group controlId="userOrganization" className="mb-3">
            <Form.Label>Организация</Form.Label>
            <Form.Select
              name="organization"
              onChange={formik.handleChange}
              value={formik.values.organization}
              isValid={formik.touched.organization && !formik.errors.organization}
              isInvalid={!!formik.errors.organization}>
              <option value="-1">Выберите организацию</option>
              {organizations?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </Form.Select>

            <Form.Control.Feedback type="invalid">
              {formik.errors.organization}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="userType" className="mb-3">
            <Form.Label>Тип пользователя</Form.Label>
            <Form.Select
              name="type"
              onChange={formik.handleChange}
              value={formik.values.type}
              isValid={formik.touched.type && !formik.errors.type}
              isInvalid={!!formik.errors.type}>
              <option value="-1">Выберите тип пользователя</option>
              {userTypes?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </Form.Select>

            <Form.Control.Feedback type="invalid">{formik.errors.type}</Form.Control.Feedback>
          </Form.Group>

          {!isManager && (
            <Form.Group controlId="userRole" className="mb-3">
              <Form.Label>Роль пользователя</Form.Label>
              <Form.Select
                name="role"
                onChange={formik.handleChange}
                value={formik.values.role}
                isValid={formik.touched.role && !formik.errors.role}
                isInvalid={!!formik.errors.role}>
                <option value="-1">Выберите роль пользователя</option>
                {roles?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.role}</Form.Control.Feedback>
            </Form.Group>
          )}

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
