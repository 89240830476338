import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const targetGroupsAPI = createApi({
  reducerPath: 'targetGroupsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['TargetGroups'],
  endpoints: (builder) => ({
    getTargetGroups: builder.query({
      query: () => 'services/target-groups/',
      providesTags: () => ['TargetGroups'],
    }),

    createTargetGroup: builder.mutation({
      query: (body) => ({
        url: '/admin/target-groups/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['TargetGroups'],
    }),

    updateTargetGroup: builder.mutation({
      query: (body) => ({
        url: `admin/target-groups/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['TargetGroups'],
    }),

    deleteTargetGroup: builder.mutation({
      query: (id) => ({
        url: `admin/target-groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['TargetGroups'],
    }),
  }),
});

export const {
  useGetTargetGroupsQuery,
  useCreateTargetGroupMutation,
  useUpdateTargetGroupMutation,
  useDeleteTargetGroupMutation,
} = targetGroupsAPI;
