import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const competentionsAPI = createApi({
  reducerPath: 'competentionsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Competentions'],
  endpoints: (builder) => ({
    getCompetentions: builder.query({
      query: ({ size = 20, page = 0 }) => `services/competentions/?size=${size}&page=${page}`,
      providesTags: () => ['Competentions'],
    }),

    createCompetention: builder.mutation({
      query: (body) => ({
        url: '/admin/competentions/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Competentions'],
    }),

    updateCompetention: builder.mutation({
      query: (body) => ({
        url: `admin/competentions/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Competentions'],
    }),

    deleteCompetention: builder.mutation({
      query: (id) => ({
        url: `admin/competentions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Competentions'],
    }),
  }),
});

export const {
  useGetCompetentionsQuery,
  useCreateCompetentionMutation,
  useUpdateCompetentionMutation,
  useDeleteCompetentionMutation,
} = competentionsAPI;
