import React from 'react';
import { Form, Modal } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Resizer from 'react-image-file-resizer';

const EditModal = ({ data, setImage, setImages, showEdit, submitEdit, modalCloseEdit }) => {
  const { id, name, text, videoSrc, image, images } = data;

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          file,
          1280,
          720,
          'WEBP',
          90,
          0,
          (uri) => {
            resolve(uri);
          },
          'base64',
        );
      } catch (err) {
        reject(err);
      }
    });

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    try {
      const result = await toBase64(file);
      setImage({ name: file.name, file: result });
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilesUpload = async (event) => {
    const files = event.target.files;
    const fileArr = await Promise.all(
      Array.from(files).map(async (file) => {
        try {
          const result = await toBase64(file);
          return { name: file.name, file: result };
        } catch (error) {
          console.error(error);
        }
      }),
    );

    setImages(fileArr);
  };

  const initialValues = {
    name: name || '',
    text: text || '',
    videoSrc: videoSrc || '',
    image: image || null,
    images: images || [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле!'),
    text: Yup.string().required('Обязательно поле!'),
    videoSrc: Yup.string(),
  });

  let formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование истории успеха</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group controlId="successStoryName" className="mb-3">
            <Form.Label>
              Заголовок <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="successStoryDescription" className="mb-3">
            <Form.Label>
              Текст новости <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              name="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.text}
              isValid={formik.touched.text && !formik.errors.text}
              isInvalid={!!formik.errors.text}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.text}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="successStoryVideoSrc" className="mb-3">
            <Form.Label>Ссылка на видео</Form.Label>
            <Form.Control
              name="videoSrc"
              onChange={formik.handleChange}
              value={formik.values.videoSrc}
              isValid={formik.touched.videoSrc && !formik.errors.videoSrc}
              isInvalid={!!formik.errors.videoSrc}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.videoSrc}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="successStoryImage" className="mb-3">
            <Form.Label>Главное изображение</Form.Label>
            <Form.Control type="file" name="image" onChange={handleFileUpload} />
          </Form.Group>

          <Form.Group controlId="successStoryImages" className="mb-3">
            <Form.Label>Дополнительные изображения</Form.Label>
            <Form.Control type="file" name="images" multiple onChange={handleFilesUpload} />
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
