import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../axios';

export const fetchLogin = createAsyncThunk('auth/fetchLogin', async (body) => {
  const { data } = await axios.post('/login/', body);
  return data;
});

export const fetchRegister = createAsyncThunk('auth/fetchRegister', async (body) => {
  const { data } = await axios.post('/register/', body);
  return data;
});

export const fetchUser = createAsyncThunk('auth/fetchUser', async () => {
  const { data } = await axios.get('/user/');
  return data;
});

export const fetchUserUpdate = createAsyncThunk('auth/fetchUserUpdate', async (body) => {
  const { data } = await axios.put('/user/', body);
  return data;
});

const initialState = {
  data: null,
  status: 'loading',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.data = null;
    },
  },
  extraReducers: {
    [fetchLogin.pending]: (state) => {
      state.data = null;
      state.status = 'loading';
    },
    [fetchLogin.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'loaded';
    },
    [fetchLogin.rejected]: (state) => {
      state.data = null;
      state.status = 'error';
    },

    [fetchRegister.pending]: (state) => {
      state.data = null;
      state.status = 'loading';
    },
    [fetchRegister.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'loaded';
    },
    [fetchRegister.rejected]: (state) => {
      state.data = null;
      state.status = 'error';
    },

    [fetchUser.pending]: (state) => {
      state.data = null;
      state.status = 'loading';
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'loaded';
    },
    [fetchUser.rejected]: (state) => {
      state.data = null;
      state.status = 'error';
    },

    [fetchUserUpdate.pending]: (state) => {
      state.data = null;
      state.status = 'loading';
    },
    [fetchUserUpdate.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'loaded';
    },
    [fetchUserUpdate.rejected]: (state) => {
      state.data = null;
      state.status = 'error';
    },
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
