import React from 'react';
import Select from 'react-select';

export const FormikSelect = ({
  name,
  value,
  form,
  options,
  isMulti = false,
  placeholder = 'Выберите...',
  isError = false,
}) => {
  const onChange = (option) => {
    form.setFieldValue(name, isMulti ? option.map((item) => item.value) : option.value);
  };

  const onBlur = () => {
    form.setTouched({ name: true });
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => Array.from(value).indexOf(option.value) >= 0)
        : options.find((option) => option.value === value);
    } else {
      return isMulti ? [] : '';
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: isError ? '#dc3545' : '#cccccc',
    }),
  };

  return (
    <Select
      styles={customStyles}
      name={name}
      placeholder={placeholder}
      value={getValue()}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      noOptionsMessage={() => 'Элементы отсутствуют'}
    />
  );
};
