import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const userTypesAPI = createApi({
  reducerPath: 'userTypesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['UserTypes'],
  endpoints: (builder) => ({
    getUserTypes: builder.query({
      query: () => `services/user-types/`,
      providesTags: () => ['UserTypes'],
    }),

    createUserType: builder.mutation({
      query: (body) => ({
        url: 'admin/user-types/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['UserTypes'],
    }),

    updateUserType: builder.mutation({
      query: (body) => ({
        url: `admin/user-types/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['UserTypes'],
    }),

    deleteUserType: builder.mutation({
      query: (id) => ({
        url: `admin/user-types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['UserTypes'],
    }),
  }),
});

export const {
  useGetUserTypesQuery,
  useCreateUserTypeMutation,
  useUpdateUserTypeMutation,
  useDeleteUserTypeMutation,
} = userTypesAPI;
