import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormikSelect } from '../../FormikSelect';

const EditModal = ({ group, programs, users, showEdit, submitEdit, modalCloseEdit }) => {
  const { id, name, endDate, startDate, program } = group;

  const initialValues = {
    name: name || '',
    endDate: endDate
      ? `${endDate[0]}-${endDate[1] < 10 ? '0' + endDate[1] : endDate[1]}-${
          endDate[2] < 10 ? '0' + endDate[2] : endDate[2]
        }`
      : '' || '',
    startDate: startDate
      ? `${startDate[0]}-${startDate[1] < 10 ? '0' + startDate[1] : startDate[1]}-${
          startDate[2] < 10 ? '0' + startDate[2] : startDate[2]
        }`
      : '' || '',
    program: program ? program.id : -1 || -1,
    users: group.users ? group.users.map((x) => x.id) : [] || [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле'),
    endDate: Yup.date().required('Обязательно поле'),
    startDate: Yup.date().required('Обязательно поле'),
    program: Yup.number().moreThan(-1, 'Выберите программу'),
    users: Yup.array().min(1),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование учебной группы</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group className="mb-3">
            <Form.Label>Наименование *</Form.Label>
            <Form.Control
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Программа *</Form.Label>
            <FormikSelect
              name="program"
              value={formik.values.program}
              form={formik}
              options={programs.map((x) => ({ value: x.id, label: x.name }))}
              placeholder="Выберите программу"
              isError={formik.errors.program}
            />

            {formik.errors.program && (
              <div className="invalid-feedback d-block">{formik.errors.program}</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Обучающиеся *</Form.Label>
            <FormikSelect
              name="users"
              value={formik.values.users}
              form={formik}
              options={users.map((x) => ({ value: x.id, label: `${x.lastName} ${x.firstName}` }))}
              isMulti
              placeholder="Выберите обучающихся"
              isError={formik.errors.users}
            />

            {formik.errors.users && (
              <div className="invalid-feedback d-block">{formik.errors.users}</div>
            )}
          </Form.Group>

          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>Дата начала *</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                onChange={formik.handleChange}
                value={formik.values.startDate}
                isValid={formik.touched.startDate && !formik.errors.startDate}
                isInvalid={!!formik.errors.startDate}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} className="mb-3">
              <Form.Label>Дата окончания *</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                onChange={formik.handleChange}
                value={formik.values.endDate}
                isValid={formik.touched.endDate && !formik.errors.endDate}
                isInvalid={!!formik.errors.endDate}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.endDate}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
