import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateModal = ({ organizations, submitCreate, showCreate, modalCloseCreate }) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      organization: -1,
      flats: '',
      square: '',
      fax: '',
      address: '',
      phone: '',
      email: '',
      rooms: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().min(3).required('Укажите наименование'),
      organization: Yup.number().moreThan(-1, 'Выберите организацию').required(),
      square: Yup.number().positive('Должно быть целым числом'),
    }),
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление здания</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="buildingName" className="mb-3">
              <Form.Label>Наименование *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="buildingOrganization" className="mb-3">
              <Form.Label>Организация *</Form.Label>
              <Form.Select
                name="organization"
                onChange={formik.handleChange}
                value={formik.values.organization}
                isValid={formik.touched.organization && !formik.errors.organization}
                isInvalid={!!formik.errors.organization}>
                <option value="-1">Выберите организацию</option>
                {organizations?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {formik.errors.organization}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="buildingAddress" className="mb-3">
              <Form.Label>Адрес</Form.Label>
              <Form.Control
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                isValid={formik.touched.address && !formik.errors.address}
                isInvalid={!!formik.errors.address}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.address}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="buildingFlats" className="mb-3">
              <Form.Label>Количество этажей</Form.Label>
              <Form.Control
                name="flats"
                onChange={formik.handleChange}
                value={formik.values.flats}
                isValid={formik.touched.flats && !formik.errors.flats}
                isInvalid={!!formik.errors.flats}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.flats}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="buildingSquare" className="mb-3">
              <Form.Label>Площадь общая, м²</Form.Label>
              <Form.Control
                name="square"
                onChange={formik.handleChange}
                value={formik.values.square}
                isValid={formik.touched.square && !formik.errors.square}
                isInvalid={!!formik.errors.square}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.square}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="buildingPhone" className="mb-3">
              <Form.Label>Телефон</Form.Label>
              <Form.Control
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                isValid={formik.touched.phone && !formik.errors.phone}
                isInvalid={!!formik.errors.phone}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="buildingFax" className="mb-3">
              <Form.Label>Факс</Form.Label>
              <Form.Control
                name="fax"
                onChange={formik.handleChange}
                value={formik.values.fax}
                isValid={formik.touched.fax && !formik.errors.fax}
                isInvalid={!!formik.errors.fax}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.fax}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="buildingEmail" className="mb-3">
              <Form.Label>Электронная почта</Form.Label>
              <Form.Control
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={!!formik.errors.email}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
