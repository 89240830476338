import React from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Row } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  useBookSignUpMutation,
  useGetBookingByUserQuery,
  useGetBookingByIdQuery,
} from '../../../services/booking';

import LoaderBookingDetail from '../../../components/Loaders/BookingDetail';
import AlertMessage from '../../../components/AlertMessage';

import logo from '../../../assets/img/logo.jpg';

import './Detail.scss';

function BookingDetail() {
  const { id } = useParams();

  const { data: booking, isLoading } = useGetBookingByIdQuery(id);
  const { data: bookingByUser } = useGetBookingByUserQuery({ size: 100, page: 0 });

  const [bookSignUp, { isError }] = useBookSignUpMutation();

  const [message, setMessage] = React.useState(null);

  const initialValues = {
    date: '',
    hours: 1,
  };

  const validationSchema = Yup.object({
    date: Yup.date().required('Обязательно поле!'),
    hours: Yup.number().min(1, 'Минимальная продолжительность 1 час').required('Обязательно поле!'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      signUpBooking(values);
      resetForm({});
    },
  });

  const signUpBooking = (values) => {
    bookSignUp({ room: { id: id }, ...values })
      .unwrap()
      .then(() => {
        setMessage(`Бронирование прошло успешно.`);
      })
      .catch((err) => {
        setMessage(
          `При бронировани что-то пошло не так, попробуйте позже. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });
  };

  return (
    <section className="section section-booking-detail my-5">
      <div className="container">
        {isLoading ? (
          <LoaderBookingDetail />
        ) : (
          <>
            {message && (
              <AlertMessage message={message} setMessage={setMessage} isError={isError} />
            )}

            <div className="section-title">
              <h1>{booking?.name}</h1>
              <hr />
            </div>

            <div className="booking-wrapper">
              <div className="booking-detail">
                <img
                  src={booking?.image ? process.env.REACT_APP_API_URL + booking.image.src : logo}
                  alt={booking?.name}
                />

                <div className="booking-detail__content">
                  <div className="booking-detail__type">
                    Тип площадки: <span>{booking?.roomType.name}</span>
                  </div>

                  <div className="booking-detail__places">
                    Рабочих мест: <span>{booking?.seats}</span>
                  </div>

                  <div className="booking-detail__competation">
                    Компетенции: <span>{booking?.competention?.map((x) => x.name + ', ')}</span>
                  </div>

                  <div className="booking-detail__address">
                    Адрес: <span>{booking?.building?.address}</span>
                  </div>
                </div>
              </div>

              {/* <div className="booking-user">
                {bookingByUser.content
                  ?.filter((x) => x.id === id)
                  ?.map((x) => (
                    <div className="booking-user__info">{x.room.name}</div>
                  ))}
              </div> */}

              <div className="booking-info">
                <Form onSubmit={formik.handleSubmit} noValidate>
                  <Row className="mb-2">
                    <Col>
                      <Form.Group controlId="bookingDate">
                        <Form.Label>Дата бронирования *</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          name="date"
                          onChange={formik.handleChange}
                          value={formik.values.date}
                          isValid={formik.touched.date && !formik.errors.date}
                          isInvalid={!!formik.errors.date}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="bookingDuration">
                        <Form.Label>Продолжительность (часов) *</Form.Label>
                        <Form.Control
                          type="number"
                          min="1"
                          name="hours"
                          onChange={formik.handleChange}
                          value={formik.values.hours}
                          isValid={formik.touched.hours && !formik.errors.hours}
                          isInvalid={!!formik.errors.hours}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="booking-equipment">
                    <h5 className="text-center">Перечень доступного оборудования</h5>

                    <div className="booking-equipment_info">
                      <h6>Оборудование</h6>
                      <h6>Количество</h6>
                    </div>

                    {booking?.equipments?.map((x) => (
                      <div className="booking-equipment_item" key={x.id}>
                        <div className="booking-item__name">{x.name}</div>

                        <div className="booking-item__count">{x.quantity}</div>
                      </div>
                    ))}
                  </div>

                  <button type="submit" className="btn btn-primary w-100">
                    Забронировать
                  </button>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default BookingDetail;
