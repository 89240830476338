import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Modal, Table } from 'react-bootstrap';

import { useChangeStatusResponseMutation, useGetResponsesQuery } from '../../services/responses';

import { FaFilter, FaWindowClose } from 'react-icons/fa';

import AlertMessage from '../../components/AlertMessage';
import FilterResponses from '../../components/Modals/FilterResponses';

import useAuth from '../../hooks/useAuth';

import './Responses.scss';

const Responses = () => {
  const { isEmployer } = useAuth();

  const { data: responses, isLoading } = useGetResponsesQuery(isEmployer ? 'employer' : 'user');

  const [changeStatusResponse, { isError }] = useChangeStatusResponseMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const positionOfFilter = searchParams.get('position') ?? null;

  const isFilters = () => positionOfFilter;

  const clearFilters = () => setSearchParams({});

  const [filterIsOpen, setFilterIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const handleDetailShow = (id) => {
    if (responses?.content?.find((response) => response.id === id)?.status === 'WAITING') {
      changeStatusResponse({
        id: id,
        status: 'VIEWED',
      })
        .unwrap()
        .then()
        .catch();
    }
  };

  const handleAcceptResponse = (id) => {
    changeStatusResponse({
      id: id,
      status: 'INVITE',
    })
      .unwrap()
      .then(setMessage('Отклик успешно принят.'))
      .catch((err) => {
        setMessage(
          `Не удалось принять отклик. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Отклики на вакансии';
  }, []);

  return (
    <>
      {/* <FilterResponses
        filterIsOpen={filterIsOpen}
        setFilterIsOpen={setFilterIsOpen}
        positions={[]}
      /> */}

      <section className="section section-programs my-5">
        <div className="container">
          <div className="section-title">
            <h1>Отклики и прилашения на вакансии</h1>
            <hr />

            {/* <div className="filters-wrapper" onClick={() => setFilterIsOpen(true)}>
              <div className="filters">
                <FaFilter /> Фильтр
              </div>
              <span>{isLoading ? '...' : responses?.content?.length}</span>
            </div>

            {isFilters() && (
              <button className="btn btn-primary mb-3 fw-bold" onClick={clearFilters}>
                <FaWindowClose size={36} className="me-2" /> Очистить фильтры
              </button>
            )} */}
          </div>

          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          {isLoading ? (
            <h1 className="text-center">Загрузка ...</h1>
          ) : (
            <>
              {isEmployer &&
                responses?.content?.map((response, index) => (
                  <div
                    key={index}
                    className="modal fade"
                    id={`resumeDetail${response?.id}`}
                    tabIndex="-1"
                    aria-labelledby={`resumeDetail${response?.id}Label`}
                    aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id={`resumeDetail${response?.id}Label`}>
                            Подробная информация
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                          {response?.resume?.educations && (
                            <div className="mb-3">
                              <h5 className="mb-2">Образование</h5>
                              {response?.resume?.educations?.map((education) => (
                                <div key={education?.id}>
                                  <p className="mb-1">
                                    <span className="text-uppercase text-secondary">
                                      Учреждение:
                                    </span>{' '}
                                    {education?.organization ?? ''}
                                  </p>
                                  <p className="mb-1">
                                    <span className="text-uppercase text-secondary">
                                      Специальность:
                                    </span>{' '}
                                    {education?.name ?? ''}
                                  </p>
                                  <p className="mb-2">
                                    <span className="text-uppercase text-secondary">
                                      Дата окончания:
                                    </span>{' '}
                                    {new Date(education?.endDate).toLocaleDateString() ?? ''}
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}

                          {response?.resume?.additionalEducations && (
                            <div className="mb-3">
                              <h5>Дополнительное образование</h5>

                              {response?.resume?.additionalEducations?.map(
                                (additionalEducation) => (
                                  <div key={additionalEducation?.id}>
                                    <p className="mb-1">
                                      <span className="text-uppercase text-secondary">
                                        Учреждение:
                                      </span>{' '}
                                      {additionalEducation?.organization ?? ''}
                                    </p>
                                    <p className="mb-1">
                                      <span className="text-uppercase text-secondary">
                                        Специальность:
                                      </span>{' '}
                                      {additionalEducation?.name ?? ''}
                                    </p>
                                    <p className="mb-2">
                                      <span className="text-uppercase text-secondary">
                                        Дата окончания:
                                      </span>{' '}
                                      {new Date(
                                        additionalEducation?.endDate,
                                      ).toLocaleDateString() ?? ''}
                                    </p>
                                  </div>
                                ),
                              )}
                            </div>
                          )}

                          {response?.resume?.perks && (
                            <div className="mb-3">
                              <h5>Навыки</h5>

                              {response?.resume?.perks?.map((perk) => (
                                <p className="mb-1" key={perk?.id}>
                                  {perk?.text ?? ''}
                                </p>
                              ))}
                            </div>
                          )}

                          {response?.resume?.independenceScore && (
                            <div className="mb-3">
                              <h5>Независимая оценка квалификаций</h5>

                              {response?.resume?.independenceScore?.map((score) => (
                                <p className="mb-1" key={score?.id}>
                                  {score?.text ?? ''}
                                </p>
                              ))}
                            </div>
                          )}

                          {response?.resume?.softSkills && (
                            <div className="mb-3">
                              <h5>SoftSkills</h5>

                              {response?.resume?.softSkills?.map((softSkill) => (
                                <p className="mb-1" key={softSkill?.id}>
                                  {softSkill?.text ?? ''}
                                </p>
                              ))}
                            </div>
                          )}

                          <h4>Контактная информация</h4>
                          <p className="mb-1">
                            <span className="text-uppercase text-secondary">ФИО:</span>{' '}
                            {`${response?.resume?.lastName} ${response?.resume?.firstName} ${
                              response?.resume?.secondName ?? ''
                            }`}
                          </p>
                          <p className="mb-1">
                            <span className="text-uppercase text-secondary">Дата рождения:</span>{' '}
                            {[...response?.resume?.birthdate].reverse().join('.')}
                          </p>
                          <p className="mb-1">
                            <span className="text-uppercase text-secondary">Телефон:</span>{' '}
                            {response?.resume?.phone}
                          </p>
                          <p className="mb-0">
                            <span className="text-uppercase text-secondary">EMail:</span>{' '}
                            {response?.resume?.email}
                          </p>

                          <Button
                            variant="success"
                            className="mt-3 w-100 fw-bold text-uppercase"
                            data-bs-dismiss="modal"
                            onClick={() => handleAcceptResponse(response?.id)}>
                            Принять отклик
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <Table responsive>
                <thead className="align-middle">
                  <tr>
                    <th>#</th>
                    <th>{isEmployer ? 'Позиция' : 'Должность'}</th>
                    <th>{isEmployer ? 'Образование' : 'Организация'}</th>
                    <th>Статус</th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody className="align-middle">
                  {responses?.content?.map((response, index) => (
                    <tr key={response?.id}>
                      <td>{response?.id}</td>
                      <td>{response?.vacancy?.position}</td>
                      <td>
                        {isEmployer
                          ? response?.resume?.educations?.map(
                              (education) =>
                                `${education?.organization ?? ''}, ${education?.name ?? ''}, ${
                                  new Date(education?.endDate).toLocaleDateString() ?? ''
                                }`,
                            )
                          : response?.vacancy?.organization?.name}
                      </td>
                      <td>
                        {response?.status === 'WAITING'
                          ? 'Не просмотрено'
                          : response?.status === 'VIEWED'
                          ? 'Просмотрено'
                          : isEmployer
                          ? 'Принято'
                          : 'Приглашены'}
                      </td>
                      <td>
                        {isEmployer ? (
                          <button
                            type="button"
                            className="btn btn-primary w-100 fw-bold text-uppercase"
                            data-bs-toggle="modal"
                            data-bs-target={`#resumeDetail${response?.id}`}
                            onClick={() => handleDetailShow(response?.id)}>
                            Подробнее
                          </button>
                        ) : (
                          <Link
                            to={`/vacancy/${response?.vacancy?.id}`}
                            className="btn btn-primary w-100">
                            Подробнее про вакансию
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Responses;
