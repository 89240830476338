import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const citiesAPI = createApi({
  reducerPath: 'citiesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Cities'],
  endpoints: (builder) => ({
    getCities: builder.query({
      query: ({ size = 20, page = 0 }) => `admin/cities/?size=${size}&page=${page}`,
      providesTags: () => ['Cities'],
    }),

    createCity: builder.mutation({
      query: (body) => ({
        url: `admin/cities/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Cities'],
    }),

    updateCity: builder.mutation({
      query: (body) => ({
        url: `admin/cities/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Cities'],
    }),

    deleteCity: builder.mutation({
      query: (id) => ({
        url: `admin/cities/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Cities'],
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useCreateCityMutation,
  useUpdateCityMutation,
  useDeleteCityMutation,
} = citiesAPI;
