import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';

import { useGetRequestsQuery, useUpdateRequestMutation } from '../../../../services/requests';

import PaginationCustom from '../../../../components/PaginationCustom';

import STATUS from '../../../../statusRequest';

const RequestsProgram = () => {
  const [page, setPage] = React.useState(0);
  const query = {
    page: page,
    from: 'PROGRAM',
  };
  const { data: requests, isLoading } = useGetRequestsQuery(query);
  const [updateRequests, { isError }] = useUpdateRequestMutation();

  React.useEffect(() => {
    document.title = 'ЦОПП - Запросы на программы';
  }, []);

  return (
    <section className="section section-requests__program min-height my-5">
      <div className="container">
        <div className="section-title ">
          <h1>Запросы на программы</h1>
          <hr />
        </div>

        {isLoading ? (
          <h1 className="text-center">Загрузка...</h1>
        ) : (
          <>
            <Table bordered responsive className="text-center">
              <thead className="align-middle">
                <tr>
                  <th>Программа</th>
                  <th>Обучающиеся</th>
                  <th>EMail</th>
                  <th>Телефон</th>
                  <th>Дата рождения</th>
                  <th>Дата заявки</th>
                  <th>Статус</th>
                  {/* <th></th> */}
                </tr>
              </thead>

              <tbody className="align-middle">
                {requests?.content?.map((obj) => (
                  <tr key={obj.id}>
                    <td>
                      <Link to={'/programs/' + obj.program.id}>{obj.program.name}</Link>
                    </td>
                    <td>{obj.users.map((x) => `${x.lastName} ${x.firstName} ${x.secondName}`)}</td>
                    <td>{obj.author.email}</td>
                    <td>{obj.author.phone}</td>
                    <td>{new Date(obj.author?.birthdate?.join('-')).toLocaleDateString()}</td>
                    <td>{new Date(obj.created).toLocaleString().slice(0, -3)}</td>
                    <td>{STATUS[obj?.requestStatus]}</td>
                    {/* <td>
                  <Button variant="link" className="p-0 mx-2">
                    <FaEdit size="1.1em" />
                  </Button>
                </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>

            <PaginationCustom totalPages={requests?.totalPages} page={page} setPage={setPage} />
          </>
        )}
      </div>
    </section>
  );
};

export default RequestsProgram;
