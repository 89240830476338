import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const themesAPI = createApi({
  reducerPath: 'themesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Themes'],
  endpoints: (builder) => ({
    getThemes: builder.query({
      query: ({ size = 20, page = 0, role, name = null }) =>
        `${role}/themes/?size=${size}&page=${page}${name ? `&name=${name}` : ''}`,
      providesTags: () => ['Themes'],
    }),

    createTheme: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/themes/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Themes'],
    }),

    updateTheme: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/themes/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Themes'],
    }),

    deleteTheme: builder.mutation({
      query: ({ role, id }) => ({
        url: `${role}/themes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Themes'],
    }),
  }),
});

export const {
  useGetThemesQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation,
  useDeleteThemeMutation,
} = themesAPI;
