import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const managersAPI = createApi({
  reducerPath: 'managersAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Managers'],
  endpoints: (builder) => ({
    getManagers: builder.query({
      query: ({ size = 20, page = 0 }) => `admin/managers/?size=${size}&page=${page}`,
      providesTags: () => ['Managers'],
    }),

    createManager: builder.mutation({
      query: (body) => ({
        url: `admin/managers/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Managers'],
    }),

    updateManager: builder.mutation({
      query: (body) => ({
        url: `admin/managers/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Managers'],
    }),

    deleteManager: builder.mutation({
      query: (id) => ({
        url: `admin/managers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Managers'],
    }),
  }),
});

export const {
  useGetManagersQuery,
  useCreateManagerMutation,
  useUpdateManagerMutation,
  useDeleteManagerMutation,
} = managersAPI;
