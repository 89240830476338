import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const successStoryAPI = createApi({
  reducerPath: 'successStoryAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Success'],
  endpoints: (builder) => ({
    getSuccess: builder.query({
      query: ({ size = 20, page = 0}) => `success/?page=${page}&size=${size}`,
      providesTags: () => ['Success'],
    }),
    getSuccessById: builder.query({
      query: (id) => `success/${id}`,
      providesTags: () => ['Success'],
    }),

    createSuccess: builder.mutation({
      query: (body) => ({
        url: 'admin/success/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Success'],
    }),

    updateSuccess: builder.mutation({
      query: (body) => ({
        url: `admin/success/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Success'],
    }),

    deleteSuccess: builder.mutation({
      query: (id) => ({
        url: `admin/success/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Success'],
    }),
  }),
});

export const {
  useGetSuccessQuery,
  useGetSuccessByIdQuery,
  useCreateSuccessMutation,
  useUpdateSuccessMutation,
  useDeleteSuccessMutation,
} = successStoryAPI;
