import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const regionsAPI = createApi({
  reducerPath: 'regionsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Regions'],
  endpoints: (builder) => ({
    getRegions: builder.query({
      query: () => `admin/regions-cities/?size=10000`,
      providesTags: () => ['Regions'],
    }),

    createRegion: builder.mutation({
      query: (body) => ({
        url: `admin/regions-cities/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Regions'],
    }),

    updateRegion: builder.mutation({
      query: (body) => ({
        url: `admin/regions-cities/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Regions'],
    }),

    deleteRegion: builder.mutation({
      query: (id) => ({
        url: `admin/regions-cities/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Regions'],
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useCreateRegionMutation,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
} = regionsAPI;
