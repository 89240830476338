import React from 'react';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FaChartLine, FaCheck, FaEdit } from 'react-icons/fa';

import { useGetRequestsQuery, useUpdateRequestMutation } from '../../../../services/requests';
import { useCreateProgramMutation } from '../../../../services/programs';
import { useGetTargetGroupsQuery } from '../../../../services/targetGroups';
import { useGetProgramTypesQuery } from '../../../../services/programTypes';
import { useGetOrganizationsQuery } from '../../../../services/organizations';
import { useGetProgramSubspeciesQuery } from '../../../../services/programSubspecies';

import EditConstructor from '../../../../components/Modals/Requests/EditConstructor';
import CreateModal from '../../../../components/Modals/Programs/Create';

import PaginationCustom from '../../../../components/PaginationCustom';

import AlertMessage from '../../../../components/AlertMessage';

import STATUS from '../../../../statusRequest';

const RequestsConstructor = () => {
  const [page, setPage] = React.useState(0);
  const query = {
    page: page,
    from: 'CONSTRUCTOR',
  };
  const { data: requests, isLoading } = useGetRequestsQuery(query);
  const [updateRequest, { isError }] = useUpdateRequestMutation();

  const [createProgram, { isError: isErrorCreate }] = useCreateProgramMutation();

  const organizationsQuery = {
    role: 'admin',
    page: 0,
    size: 300,
  };

  const { data: types } = useGetProgramTypesQuery();
  const { data: targets } = useGetTargetGroupsQuery();
  const { data: subspecies } = useGetProgramSubspeciesQuery();
  const { data: organizations } = useGetOrganizationsQuery(organizationsQuery);

  const [request, setRequest] = React.useState({});

  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);

  const [image, setImage] = React.useState({ name: '', file: '' });

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = (obj) => {
    setRequest(obj);
    setShowCreate(true);
  };
  const submitCreate = (values) => {
    values.type = { id: values.type };
    values.subspecies = { id: values.subspecies };
    values.target = { id: values.target };
    values.modules = values.modules.map((x) => ({ id: x }));
    values.organization = values.organization !== -1 ? { id: values.organization } : null;

    createProgram({ role: 'admin', body: image.file ? { ...values, image } : values })
      .unwrap()
      .then((response) => {
        setMessage(`Программа "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить программу. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setRequest(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, status) => {
    updateRequest({ id, status })
      .unwrap()
      .then((response) => {
        setMessage(
          `Заявка от пользователя "${response.users.map(
            (user) => `${user.lastName} ${user.firstName} ${user.secondName}`,
          )}" успешно изменена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось изменить заявку. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
    modalCloseEdit();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Запросы из конструктора';
  }, []);

  return (
    <>
      <EditConstructor
        request={request}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <CreateModal
        isAdmin={true}
        isRequests={true}
        types={types?.content ?? []}
        targets={targets?.content ?? []}
        modules={request?.program?.modules ?? []}
        subspecies={subspecies?.content ?? []}
        organizations={organizations?.content ?? []}
        setImage={setImage}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <section className="section section-requests__constructor min-height my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Запросы из конструктора</h1>
            <hr />
          </div>

          <Button
            variant="outline-primary"
            className="mb-4 d-block mx-auto"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_API_URL}/statistics/requests/`)
            }>
            <FaChartLine size="1.1em" /> Выгрузить статистику
          </Button>

          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    <th>Модули</th>
                    <th>ФИО</th>
                    <th>Телефон / EMail</th>
                    <th>Дата заявки</th>
                    <th>Статус запроса</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {requests?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.program.modules.map((module) => module.name + '; ')}</td>
                      <td>
                        {obj.users.map(
                          (user) => `${user.lastName} ${user.firstName} ${user.secondName}`,
                        )}
                      </td>
                      <td>{obj.users.map((user) => `${user.phone} / ${user.email}`)}</td>
                      <td>{new Date(obj.created).toLocaleString().slice(0, -3)}</td>
                      <td>{STATUS[obj.requestStatus]}</td>
                      <td>
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id={'button-edit-tooltip'}>Редактировать запрос</Tooltip>
                          }>
                          <Button
                            variant="link"
                            className="p-0 mx-2"
                            onClick={() => modalShowEdit(obj)}>
                            <FaEdit size="1.1em" />
                          </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id={'button-success-tooltip'}>Сформировать программу</Tooltip>
                          }>
                          <Button
                            variant="link"
                            className="p-0 mx-2"
                            onClick={() => modalShowCreate(obj)}>
                            <FaCheck size="1.1em" color="green" />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={requests?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default RequestsConstructor;
