import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { logout } from '../src/redux/slices/authSlice';

import axios from 'axios';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('Authorization', 'Bearer_' + token);
    }

    headers.set('Content-Type', 'application/json');

    return headers;
  },
});

export const baseQueryWithAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && (result.error?.originalStatus === 401 || result.error?.status === 401)) {
    try {
      const refreshResult = await axios.post(
        `${process.env.REACT_APP_API_URL}/refresh`,
        localStorage.getItem('refreshToken'),
        {
          headers: {
            'Content-Type': 'text/plain',
          },
        },
      );

      if (refreshResult.data.access) {
        localStorage.setItem('accessToken', refreshResult.data.access);
        result = await baseQuery(args, api, extraOptions);
      }
    } catch (err) {
      api.dispatch(logout());
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('refreshToken');
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
