import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Table, Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from '../../../../services/users';

import { useGetUserTypesQuery } from '../../../../services/userTypes';
import { useGetRolesQuery } from '../../../../services/roles';
import { useGetOrganizationsQuery } from '../../../../services/organizations';

import CreateModal from '../../../../components/Modals/Users/Create';
import EditModal from '../../../../components/Modals/Users/Edit';
import DeleteModal from '../../../../components/Modals/Users/Delete';

import AlertMessage from '../../../../components/AlertMessage';
import PaginationCustom from '../../../../components/PaginationCustom';

import useAuth from '../../../../hooks/useAuth';

import './Teachers.scss';

const Teachers = () => {
  const { isAdmin, isManager } = useAuth();

  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: users, isLoading } = useGetUsersQuery({
    size: 18,
    page: page,
    type: 3,
    role: isAdmin ? 'admin' : 'manager',
  });
  const [createUser, { isError: isErrorCreate }] = useCreateUserMutation();
  const [updateUser, { isError: isErrorUpdate }] = useUpdateUserMutation();
  const [deleteUser, { isError: isErrorDelete }] = useDeleteUserMutation();

  const organizationsQuery = {
    role: isAdmin ? 'admin' : 'manager',
    page: 0,
    size: 300,
  };

  const { data: userTypes } = useGetUserTypesQuery();
  const { data: roles } = useGetRolesQuery(isAdmin ? 'admin' : 'manager');
  const { data: organizations } = useGetOrganizationsQuery(organizationsQuery);

  const [teacher, setTeacher] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.organization = values.organization > 0 ? { id: values.organization } : null;
    values.type = values.type > 0 ? { id: values.type } : null;
    values.role = values.role > 0 ? { id: values.role } : null;

    createUser({ role: isAdmin ? 'admin' : 'manager', body: values })
      .unwrap()
      .then((response) => {
        setMessage(
          `Преподаватель "${response.lastName} ${response.firstName} ${response.secondName}" успешно добавлен.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить преподавателя. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setTeacher(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.organization = values.organization > 0 ? { id: values.organization } : null;
    values.type = values.type > 0 ? { id: values.type } : null;
    values.role = values.role > 0 ? { id: values.role } : null;

    updateUser({ role: isAdmin ? 'admin' : 'manager', body: { id, ...values } })
      .unwrap()
      .then((response) => {
        setMessage(
          `Преподаватель "${response.lastName} ${response.firstName} ${response.secondName}" успешно отредактирован.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось отредактировать преподавателя. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    const teacher = users.content.find((x) => x.id === obj.id);
    setTeacher(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить преподавателя ${teacher.lastName} ${teacher.firstName}?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteUser({ role: isAdmin ? 'admin' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Преподаватель "${users.content.find((x) => x.id === response).lastName} ${
            users.content.find((x) => x.id === response).firstName
          }" успешно удален.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить преподавателя. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Преподаватели';
  }, []);

  return (
    <>
      <CreateModal
        userTypes={userTypes?.content ?? []}
        roles={roles?.content ?? []}
        organizations={organizations?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
        isAdmin={isAdmin}
        isManager={isManager}
      />

      <EditModal
        userTypes={userTypes?.content ?? []}
        roles={roles?.content ?? []}
        organizations={organizations?.content ?? []}
        user={teacher}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
        isManager={isManager}
      />

      <DeleteModal
        user={teacher}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-teachers my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Преподаватели</h1>
            <hr />

            <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
              Добавить преподавателя
            </Button>
          </div>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    <th>ФИО</th>
                    <th>EMail</th>
                    <th>Телефон</th>
                    <th>Действия</th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {users?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>
                        {obj.lastName} {obj.firstName} {obj.secondName}
                      </td>
                      <td>{obj.email}</td>
                      <td>{obj.phone}</td>
                      <td>
                        <Button variant="link" onClick={() => modalShowEdit(obj)}>
                          <FaEdit />
                        </Button>

                        <Button variant="link" onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={users?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Teachers;
