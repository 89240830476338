import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const bookingAPI = createApi({
  reducerPath: 'bookingAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Booking'],
  endpoints: (builder) => ({
    getBooking: builder.query({
      query: ({ size = 20, page = 0 }) => `booking/?page=${page}&size=${size}`,
      providesTags: () => ['Booking'],
    }),

    getBookingRequests: builder.query({
      query: ({ size = 20, page = 0 }) => `admin/booking-requests?page=${page}&size=${size}`,
      providesTags: () => ['Booking'],
    }),

    getBookingByUser: builder.query({
      query: ({ size = 20, page = 0 }) => `booking/user-requests?page=${page}&size=${size}`,
      providesTags: () => ['Booking'],
    }),

    getBookingById: builder.query({
      query: (id) => `booking/${id}`,
      providesTags: () => ['Booking'],
    }),

    bookSignUp: builder.mutation({
      query: (body) => ({
        url: `booking/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Booking'],
    }),

    bookingRequestUpdate: builder.mutation({
      query: ({ id, status }) => ({
        url: `admin/booking-requests/${id}`,
        method: 'PUT',
        body: status,
      }),
      invalidatesTags: ['Booking'],
    }),
  }),
});

export const {
  useGetBookingQuery,
  useGetBookingRequestsQuery,
  useGetBookingByUserQuery,
  useGetBookingByIdQuery,
  useBookSignUpMutation,
  useBookingRequestUpdateMutation,
} = bookingAPI;
