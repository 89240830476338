import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';

import { useGetRequestsQuery, useUpdateRequestMutation } from '../../../../services/requests';

import EditTeaching from '../../../../components/Modals/Requests/EditTeaching';
import PaginationCustom from '../../../../components/PaginationCustom';
import AlertMessage from '../../../../components/AlertMessage';

import STATUS from '../../../../statusRequest';

const RequestsTeaching = () => {
  const [page, setPage] = React.useState(0);
  const query = {
    page: page,
    from: 'FORM',
  };
  const { data: requests, isLoading } = useGetRequestsQuery(query);
  const [updateRequest, { isError }] = useUpdateRequestMutation();

  const [request, setRequest] = React.useState({});

  const [message, setMessage] = React.useState(null);
  const [showEdit, setShowEdit] = React.useState(false);

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setRequest(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, status) => {
    updateRequest({ id, status })
      .unwrap()
      .then((response) => {
        setMessage(
          `Заявка от пользователя "${response.users.map(
            (user) => `${user.lastName} ${user.firstName} ${user.secondName}`,
          )}" успешно изменена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось изменить заявку. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
    modalCloseEdit();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Запросы на обучение';
  }, []);

  return (
    <>
      <EditTeaching
        request={request}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <section className="section section-requests__teaching min-height my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Запросы на обучение</h1>
            <hr />
          </div>

          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center">
                <thead className="align-middle">
                  <tr>
                    <th>Желаемое направление обучения</th>
                    <th>Количество участников</th>
                    <th>Дата заявки</th>
                    <th>Обучающийся</th>
                    <th>Телефон</th>
                    <th>Статус запроса</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {requests?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj?.direction}</td>
                      <td>{obj?.quantity}</td>
                      <td>{new Date(obj?.created).toLocaleString().slice(0, -3)}</td>
                      <td>
                        {obj?.users?.map(
                          (user) => `${user.lastName} ${user.firstName} ${user.secondName}`,
                        )}
                      </td>
                      <td>{obj?.users[0]?.phone}</td>
                      <td>{STATUS[obj?.requestStatus]}</td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowEdit(obj)}>
                          <FaEdit size="1.1em" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={requests?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default RequestsTeaching;
