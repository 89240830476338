import React from 'react';

import './TimetableLessons.scss';

const TimetableLessons = () => {
  React.useEffect(() => {
    document.title = 'ЦОПП - Расписание занятий';
  }, []);

  return (
    <section className="section section-lessons my-5">
      <div className="container">
        <div className="section-title ">
          <h1>Расписание занятий</h1>
          <hr />
        </div>
      </div>
    </section>
  );
};

export default TimetableLessons;
