import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const newsAPI = createApi({
  reducerPath: 'newsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['News'],
  endpoints: (builder) => ({
    getNews: builder.query({
      query: ({ size = 5, page = 0 }) => `news/?size=${size}&page=${page}`,
      providesTags: () => ['News'],
    }),

    getNewsById: builder.query({
      query: (id) => `news/${id}`,
      providesTags: () => ['News'],
    }),

    createNews: builder.mutation({
      query: (body) => ({
        url: 'admin/news/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['News'],
    }),

    updateNews: builder.mutation({
      query: (body) => ({
        url: `admin/news/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['News'],
    }),

    deleteNews: builder.mutation({
      query: (id) => ({
        url: `admin/news/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['News'],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetNewsByIdQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation,
} = newsAPI;
