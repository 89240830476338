import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const partnersAPI = createApi({
  reducerPath: 'partnersAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Partners'],
  endpoints: (builder) => ({
    getPartners: builder.query({
      query: () => `partners/`,
      providesTags: () => ['Partners'],
    }),

    createPartners: builder.mutation({
      query: (body) => ({
        url: 'admin/partners/',
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Partners'],
    }),

    updatePartners: builder.mutation({
      query: (body) => ({
        url: `admin/partners`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Partners'],
    }),

    deletePartners: builder.mutation({
      query: (id) => ({
        url: `admin/partners/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Partners'],
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useCreatePartnersMutation,
  useUpdatePartnersMutation,
  useDeletePartnersMutation,
} = partnersAPI;
