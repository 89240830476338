import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import {
  FaEdit,
  FaTrashAlt,
  FaVenus,
  FaMars,
  FaRegCalendarAlt,
  FaPhoneAlt,
  FaRegEnvelope,
} from 'react-icons/fa';

import {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from '../../../../services/users';

import { useGetUserTypesQuery } from '../../../../services/userTypes';
import { useGetRolesQuery } from '../../../../services/roles';
import { useGetOrganizationsQuery } from '../../../../services/organizations';

import CreateModal from '../../../../components/Modals/Users/Create';
import EditModal from '../../../../components/Modals/Users/Edit';
import DeleteModal from '../../../../components/Modals/Users/Delete';

import AlertMessage from '../../../../components/AlertMessage';
import PaginationCustom from '../../../../components/PaginationCustom';

import useAuth from '../../../../hooks/useAuth';

import './Employers.scss';

const Employers = () => {
  const { isAdmin } = useAuth();

  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: users, isLoading } = useGetUsersQuery({
    size: 18,
    page: page,
    type: 2,
    role: 'admin',
  });
  const [createUser, { isError: isErrorCreate }] = useCreateUserMutation();
  const [updateUser, { isError: isErrorUpdate }] = useUpdateUserMutation();
  const [deleteUser, { isError: isErrorDelete }] = useDeleteUserMutation();

  const organizationsQuery = {
    role: 'admin',
    page: 0,
    size: 300,
  };

  const { data: userTypes } = useGetUserTypesQuery();
  const { data: roles } = useGetRolesQuery();
  const { data: organizations } = useGetOrganizationsQuery(organizationsQuery);

  const [employer, setEmployer] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.organization = values.organization > 0 ? { id: values.organization } : null;
    values.type = values.type > 0 ? { id: values.type } : null;
    values.role = values.role > 0 ? { id: values.role } : null;

    createUser({ role: isAdmin ? 'admin' : 'manager', body: values })
      .unwrap()
      .then((response) => {
        setMessage(`Работодатель "${response.name}" успешно добавлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить работодателя. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setEmployer(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.organization = values.organization > 0 ? { id: values.organization } : null;
    values.type = values.type > 0 ? { id: values.type } : null;
    values.role = values.role > 0 ? { id: values.role } : null;

    updateUser({ role: isAdmin ? 'admin' : 'manager', body: { id, ...values } })
      .unwrap()
      .then((response) => {
        setMessage(`Работодатель "${response.name}" успешно отредактирован.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось отредактировать работодателя. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    const employer = users.content.find((x) => x.id === obj.id);
    setEmployer(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить работодателя ${employer.lastName} ${employer.firstName}?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteUser({ role: isAdmin ? 'admin' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Работодатель "${users.content.find((x) => x.id === response).name}" успешно удален.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить работодателя. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Работодатели';
  }, []);

  return (
    <>
      <CreateModal
        userTypes={userTypes?.content ?? []}
        roles={roles?.content ?? []}
        organizations={organizations?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
        isAdmin={isAdmin}
      />

      <EditModal
        userTypes={userTypes?.content ?? []}
        roles={roles?.content ?? []}
        organizations={organizations?.content ?? []}
        user={employer}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        user={employer}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-employers my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Работодатели</h1>
            <hr />

            <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
              Добавить работодателя
            </Button>
          </div>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <div className="employers mb-3">
                {users?.content?.map((obj) => (
                  <div className="employer-item" key={obj.id}>
                    <h4 className="employer-item__fio">
                      {obj.lastName} {obj.firstName}
                    </h4>

                    <p className="mb-1">
                      {obj.sex === 'MEN' ? (
                        <>
                          <FaMars size="1.2em" className="me-1" /> Пол: мужской
                        </>
                      ) : (
                        <>
                          <FaVenus size="1.2em" className="me-1" /> Пол: женский
                        </>
                      )}
                    </p>

                    <p className="mb-1">
                      <FaRegCalendarAlt size="1.2em" className="me-1" /> Дата рождения:{' '}
                      {obj.birthdate}
                    </p>

                    <p className="mb-1">
                      <FaPhoneAlt size="1.2em" className="me-1" /> Телефон: {obj.phone}
                    </p>

                    <p>
                      <FaRegEnvelope size="1.2em" className="me-1" /> EMail: {obj.email}
                    </p>

                    <div className="employer-item__btns">
                      <Button variant="outline-primary" onClick={() => modalShowEdit(obj)}>
                        <FaEdit size="1.2em" className="me-1" /> Редактировать
                      </Button>

                      <Button variant="outline-danger" onClick={() => modalShowDelete(obj)}>
                        <FaTrashAlt size="1.2em" className="me-1" /> Удалить
                      </Button>
                    </div>
                  </div>
                ))}
              </div>

              <PaginationCustom totalPages={users?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Employers;
