import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import PhoneInput from 'react-phone-input-2';
import { FormikSelect } from '../../FormikSelect';

import relationTypes from '../../../relations';
import workTypes from '../../../workType';

import useAuth from '../../../hooks/useAuth';

const EditModal = ({ objects, vacancy, showEdit, submitEdit, modalCloseEdit }) => {
  const { data } = useAuth();

  const {
    id,
    status,
    quantity,
    position,
    city,
    region,
    charge,
    educationLevel,
    requiredSkills,
    experience,
    guarantee,
    social,
    object,
    workType,
    relations,
    salaryFrom,
    salaryTo,
    contact,
    phone,
    email,
  } = vacancy;

  const initialValues = {
    id: id || null,
    status: status || '',
    quantity: quantity || 0, // Кол-во мест
    position: position || '', // Должность
    organization: data ? (data.user ? data.user?.organization?.id : data.organization?.id) : '', // Организация
    city: city || '', // Город
    region: region || '', // Город
    charge: charge || '', // Обязанности
    educationLevel: educationLevel || '', // Образование
    requiredSkills: requiredSkills || '', // Требования
    experience: experience || '', // Опыт работы
    guarantee: guarantee || '', // Доп. льготы и гарантии
    social: social || '', // Базовый соц. пакет
    object: objects?.find((x) => x.name === object)?.id, // Отрасль компании
    workType: workType || '',
    relations: relations || '',
    salaryFrom: salaryFrom || '',
    salaryTo: salaryTo || '',
    contact: contact || '',
    phone: phone || '',
    email: email || '',
  };

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .moreThan(0, 'Количество должно быть больше 0')
      .required('Обязательно поле!'),
    position: Yup.string().required('Обязательно поле!'),
    city: Yup.string().required('Обязательно поле!'),
    region: Yup.string().required('Обязательно поле!'),
    charge: Yup.string().required('Обязательно поле!'),
    educationLevel: Yup.string().required('Обязательно поле!'),
    requiredSkills: Yup.string().required('Обязательно поле!'),
    experience: Yup.string().required('Обязательно поле!'),
    //guarantee: Yup.string().required('Обязательно поле!'),
    //social: Yup.string().required('Обязательно поле!'),
    object: Yup.number().moreThan(-1, 'Выберите отрасль компании').required('Обязательно поле'),
    workType: Yup.string().required('Обязательно поле!'),
    relations: Yup.string().required('Обязательно поле!'),
    salaryFrom: Yup.string().required('Обязательно поле!'),
    salaryTo: Yup.string().required('Обязательно поле!'),
    contact: Yup.string().required('Обязательно поле!'),
    phone: Yup.string().required('Обязательно поле!'),
    email: Yup.string().required('Обязательно поле!'),
  });

  let formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      submitEdit(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование вакансии</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group as={Col} controlId="vacancyStatus" className="mb-3">
            <Form.Label>
              Статус <span className="text-danger">*</span>
            </Form.Label>

            <FormikSelect
              name="status"
              value={formik.values.status}
              form={formik}
              options={[
                { value: 'ACTIVE', label: 'Актуальна' },
                { value: 'NOT_ACTIVE', label: 'Не актуальна' },
              ]}
              placeholder="Статус"
            />

            {formik.errors.status && (
              <div className="invalid-feedback d-block">{formik.errors.status}</div>
            )}
          </Form.Group>

          <Row>
            <Form.Group as={Col} xxl={2} controlId="vacancyQuantity" className="mb-3">
              <Form.Label>
                Кол-во мест <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="quantity"
                onChange={formik.handleChange}
                value={formik.values.quantity}
                isValid={formik.touched.quantity && !formik.errors.quantity}
                isInvalid={!!formik.errors.quantity}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.quantity}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="vacancyPosition" className="mb-3">
              <Form.Label>
                Должность <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="position"
                onChange={formik.handleChange}
                value={formik.values.position}
                isValid={formik.touched.position && !formik.errors.position}
                isInvalid={!!formik.errors.position}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.position}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="vacancyObject" className="mb-3">
              <Form.Label>
                Отрасль компании <span className="text-danger">*</span>
              </Form.Label>
              <FormikSelect
                name="object"
                value={formik.values.object}
                form={formik}
                options={objects?.map((x) => ({ value: x?.id, label: x?.name }))}
                placeholder="Отрасль компании"
                isError={formik.errors.object}
              />

              {formik.errors.object && (
                <div className="invalid-feedback d-block">{formik.errors.object}</div>
              )}
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="vacancyCity" className="mb-3">
              <Form.Label>
                Город <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                isValid={formik.touched.city && !formik.errors.city}
                isInvalid={!!formik.errors.city}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="region" className="mb-3">
              <Form.Label>
                Муниципальный район <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                name="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                isValid={formik.touched.region && !formik.errors.region}
                isInvalid={!!formik.errors.region}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.region}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="vacancyEducationLevel" className="mb-3">
              <Form.Label>
                Образование <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="educationLevel"
                onChange={formik.handleChange}
                value={formik.values.educationLevel}
                isValid={formik.touched.educationLevel && !formik.errors.educationLevel}
                isInvalid={!!formik.errors.educationLevel}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.educationLevel}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="vacancyExperience" className="mb-3">
              <Form.Label>
                Опыт работы <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="experience"
                onChange={formik.handleChange}
                value={formik.values.experience}
                isValid={formik.touched.experience && !formik.errors.experience}
                isInvalid={!!formik.errors.experience}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.experience}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="vacancyWorkType" className="mb-3">
              <Form.Label>
                График работы <span className="text-danger">*</span>
              </Form.Label>

              <FormikSelect
                name="workType"
                value={formik.values.workType}
                form={formik}
                options={Object.entries(workTypes).map((x) => ({ value: x[0], label: x[1] }))}
                placeholder="График работы"
              />

              {formik.errors.workType && (
                <div className="invalid-feedback d-block">{formik.errors.workType}</div>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="vacancyRelations" className="mb-3">
              <Form.Label>
                Тип занятости <span className="text-danger">*</span>
              </Form.Label>

              <FormikSelect
                name="relations"
                value={formik.values.relations}
                form={formik}
                options={Object.entries(relationTypes).map((x) => ({ value: x[0], label: x[1] }))}
                placeholder="Тип занятости"
              />

              {formik.errors.relations && (
                <div className="invalid-feedback d-block">{formik.errors.relations}</div>
              )}
            </Form.Group>
          </Row>

          <Form.Group as={Col} controlId="vacancyCharge" className="mb-3">
            <Form.Label>
              Обязанности (Разделяйте обязанности знаком ;) <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              as="textarea"
              rows={3}
              name="charge"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.charge}
              isValid={formik.touched.charge && !formik.errors.charge}
              isInvalid={!!formik.errors.charge}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.charge}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="vacancyRequiredSkills" className="mb-3">
            <Form.Label>
              Требования (Разделяйте требования знаком ;) <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              as="textarea"
              rows={3}
              name="requiredSkills"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.requiredSkills}
              isValid={formik.touched.requiredSkills && !formik.errors.requiredSkills}
              isInvalid={!!formik.errors.requiredSkills}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.requiredSkills}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Form.Group as={Col} controlId="vacancySalaryFrom" className="mb-3">
              <Form.Label>
                Зарплата от <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="salaryFrom"
                onChange={formik.handleChange}
                value={formik.values.salaryFrom}
                isValid={formik.touched.salaryFrom && !formik.errors.salaryFrom}
                isInvalid={!!formik.errors.salaryFrom}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.salaryFrom}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="vacancySalaryTo" className="mb-3">
              <Form.Label>
                Зарплата до <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="salaryTo"
                onChange={formik.handleChange}
                value={formik.values.salaryTo}
                isValid={formik.touched.salaryTo && !formik.errors.salaryTo}
                isInvalid={!!formik.errors.salaryTo}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.salaryTo}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="vacancySocial" className="mb-3">
              <Form.Label>Базовый соц. пакет</Form.Label>

              <Form.Control
                as="textarea"
                name="social"
                onChange={formik.handleChange}
                value={formik.values.social}
                isValid={formik.touched.social && !formik.errors.social}
                isInvalid={!!formik.errors.social}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.social}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="vacancyGuarantee">
              <Form.Label>Дополнительные льготы и гарантии</Form.Label>
              <Form.Control
                as="textarea"
                name="guarantee"
                onChange={formik.handleChange}
                value={formik.values.guarantee}
                isValid={formik.touched.guarantee && !formik.errors.guarantee}
                isInvalid={!!formik.errors.guarantee}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.guarantee}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="vacancyContact" className="mb-3">
              <Form.Label>
                Контактное лицо <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="contact"
                onChange={formik.handleChange}
                value={formik.values.contact}
                isValid={formik.touched.contact && !formik.errors.contact}
                isInvalid={!!formik.errors.contact}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.contact}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="vacancyPhone" className="mb-3">
              <Form.Label>
                Телефон <span className="text-danger">*</span>
              </Form.Label>
              {/* <Form.Control
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                isValid={formik.touched.phone && !formik.errors.phone}
                isInvalid={!!formik.errors.phone}
              /> */}

              <PhoneInput
                inputProps={{
                  name: 'phone',
                }}
                country="ru"
                value={formik.values.phone}
                specialLabel={null}
                placeholder="7 (xxx) xxx-xx-xx"
                onChange={(value, country, e, formattedValue) => formik.handleChange(e)}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="vacancyEmail" className="mb-3">
              <Form.Label>
                EMail <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={!!formik.errors.email}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
