import { useSelector } from 'react-redux';

const useAuth = () => {
  const { data, status } = useSelector((state) => state.auth);

  return {
    isAuth: !!data,
    isAdmin:
      !!data && (data.user?.role?.name === 'ROLE_ADMIN' || data?.role?.name === 'ROLE_ADMIN'),
    isManager:
      !!data && (data.user?.role?.name === 'ROLE_MANAGER' || data?.role?.name === 'ROLE_MANAGER'),
    isEmployer:
      !!data && (data.user?.role?.name === 'ROLE_EMPLOYER' || data?.role?.name === 'ROLE_EMPLOYER'),
    isUser: !!data && (data.user?.role?.name === 'ROLE_USER' || data?.role?.name === 'ROLE_USER'),
    data,
    isLoaded: status === 'loaded',
    isLoading: status === 'loading',
  };
};

export default useAuth;
