import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Link, useLocation } from 'react-router-dom';

import { Button, Form, Row, Col } from 'react-bootstrap';
import { FaUserAlt, FaLock } from 'react-icons/fa';

import { Formik } from 'formik';
import * as yup from 'yup';

import { fetchLogin } from '../../redux/slices/authSlice';

import useAuth from '../../hooks/useAuth';

import Logo from '../../assets/img/logo.jpg';

import ForgotPassword from '../../components/Modals/ForgotPassword';
import AlertMessage from '../../components/AlertMessage';

import axios from 'axios';

import './Login.scss';

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { isAuth } = useAuth();

  const [showModal, setShowModal] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    document.title = 'ЦОПП - Авторизация';
  }, []);

  if (isAuth) {
    const origin = location.state?.from?.pathname || '/profile';
    return <Navigate to={origin} replace />;
  }

  const modalClose = () => setShowModal(false);
  const modalShow = () => setShowModal(true);
  const resetPassword = async (values) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/reset-password/`,
      values.email,
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      },
    );
    if (response.status === 200) {
      setMessage(`Заявка на сброс пароля успешно отправлена, проверьте почту.`);
    } else {
      setMessage(`Что-то пошло не так, попробуйте чуть позже.`);
      setIsError(true);
    }

    modalClose();
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const schema = yup.object().shape({
    email: yup.string().required('Обязательное поле!').min(6, 'Минимальная длина 6'),
    password: yup.string().required('Обязательное поле!').min(6, 'Минимальная длина 6'),
  });

  const onSubmit = async (values) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    const data = await dispatch(fetchLogin(values));
    if (!data.payload) {
      return alert('Что-то пошло не так, попробуйте ещё раз.');
    }

    if (data.payload.access) {
      localStorage.setItem('accessToken', data.payload.access);
      localStorage.setItem('refreshToken', data.payload.refresh);
    }
  };

  return (
    <>
      <ForgotPassword
        showModal={showModal}
        onModalClose={modalClose}
        resetPassword={resetPassword}
      />

      <section className="section section-login">
        <div className="container">
          {message && <AlertMessage message={message} setMessage={setMessage} isError={isError} />}

          <div className="auth">
            <Row className="align-items-center">
              <Col className="text-center">
                <img src={Logo} alt="Logo COPP" />
              </Col>
              <Col>
                <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                  {({ handleSubmit, handleChange, values, touched, isValid, errors }) => (
                    <Form className="auth-form" noValidate onSubmit={handleSubmit}>
                      <Form.Group className="auth-form__group mb-3">
                        <FaUserAlt />
                        <Form.Control
                          type="email"
                          placeholder="EMail"
                          name="email"
                          autoComplete="off"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          isInvalid={errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="auth-form__group mb-3">
                        <FaLock />
                        <Form.Control
                          type="password"
                          placeholder="Пароль"
                          name="password"
                          autoComplete="off"
                          value={values.password}
                          onChange={handleChange}
                          isValid={touched.password && !errors.password}
                          isInvalid={errors.password}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button variant="outline-success" type="submit" className="w-100 mb-3">
                        Войти
                      </Button>

                      <Link className="w-100 btn btn-outline-primary mb-3" to="/registration">
                        Зарегистрироваться
                      </Link>

                      <Button variant="outline-secondary" className="w-100" onClick={modalShow}>
                        Забыли пароль?
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
