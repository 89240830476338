import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Table, Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetEquipmentsQuery,
  useCreateEquipmentMutation,
  useUpdateEquipmentMutation,
  useDeleteEquipmentMutation,
} from '../../../../services/equipments';

import { useGetRoomsQuery } from '../../../../services/rooms';

import CreateModal from '../../../../components/Modals/Equipments/Create';
import EditModal from '../../../../components/Modals/Equipments/Edit';
import DeleteModal from '../../../../components/Modals/Equipments/Delete';

import PaginationCustom from '../../../../components/PaginationCustom';
import AlertMessage from '../../../../components/AlertMessage';

import useAuth from '../../../../hooks/useAuth';

import './Equipments.scss';

const Equipments = () => {
  const { isAdmin } = useAuth();

  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: equipments, isLoading } = useGetEquipmentsQuery({
    size: 20,
    page,
    role: isAdmin ? 'admin' : 'manager',
  });
  const [createEquipment, { isError: isErrorCreate }] = useCreateEquipmentMutation();
  const [updateEquipment, { isError: isErrorUpdate }] = useUpdateEquipmentMutation();
  const [deleteEquipment, { isError: isErrorDelete }] = useDeleteEquipmentMutation();

  const { data: rooms } = useGetRoomsQuery({
    size: 20,
    page: 0,
    role: isAdmin ? 'admin' : 'manager',
  });

  const [equipment, setEquipment] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.room = { id: values.room };
    createEquipment({ role: isAdmin ? 'admin' : 'manager', body: values })
      .unwrap()
      .then((response) => {
        setMessage(`Средство обучения "${response.name}" успешно добавлено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить средство обучения. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setEquipment(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.room = { id: values.room };
    updateEquipment({ role: isAdmin ? 'admin' : 'manager', body: { id, ...values } })
      .unwrap()
      .then((response) => {
        setMessage(`Средство обучения "${response.name}" успешно изменено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить средство обучения. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setEquipment(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить средство обучения ${
        equipments.content.find((x) => x.id === obj.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteEquipment({ role: isAdmin ? 'admin' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Средство обучения "${
            equipments.content.find((x) => x.id === response).name
          }" успешно удалено.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить средство обучения. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Средства обучения';
  }, []);

  return (
    <>
      <CreateModal
        rooms={rooms?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        equipment={equipment}
        rooms={rooms?.content ?? []}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        equipment={equipment}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-equipments my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Средства обучения</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
            Добавить запись
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <Table bordered responsive className="text-center" size="sm">
                <thead className="align-middle">
                  <tr>
                    <th>Наименование</th>
                    <th>Площадка</th>
                    <th>Количество</th>
                    <th>Действия</th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {equipments?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.name}</td>
                      <td>{obj.room.name}</td>
                      <td>{obj.quantity}</td>
                      <td>
                        <Button variant="link" className="py-0" onClick={() => modalShowEdit(obj)}>
                          <FaEdit />
                        </Button>

                        <Button
                          variant="link"
                          className="py-0"
                          onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={equipments?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Equipments;
