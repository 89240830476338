import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { FaPlus, FaQuestion, FaRegNewspaper } from 'react-icons/fa';

import {
  useGetSuccessQuery,
  useCreateSuccessMutation,
  useUpdateSuccessMutation,
  useDeleteSuccessMutation,
} from '../../services/successStory';

import SuccessStoryItem from '../../components/SuccessStoryItem';

import LoaderNews from '../../components/Loaders/News';

import CreateModal from '../../components/Modals/SuccessStory/Create';
import EditModal from '../../components/Modals/SuccessStory/Edit';
import DeleteModal from '../../components/Modals/SuccessStory/Delete';

import AlertMessage from '../../components/AlertMessage';
import PaginationCustom from '../../components/PaginationCustom';

import useAuth from '../../hooks/useAuth';

import './SuccessStory.scss';

const SuccessStory = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: success, isLoading } = useGetSuccessQuery({ size: 10, page });
  const [createSuccess, { isError: isErrorCreate }] = useCreateSuccessMutation();
  const [updateSuccess, { isError: isErrorUpdate }] = useUpdateSuccessMutation();
  const [deleteSuccess, { isError: isErrorDelete }] = useDeleteSuccessMutation();

  const { isAdmin } = useAuth();

  const [image, setImage] = React.useState({ name: '', file: '' });
  const [images, setImages] = React.useState([]);

  const [data, setData] = React.useState({});

  const [message, setMessage] = React.useState(null);
  const [deleteMessage, setDeleteMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createSuccess({ ...values, image, images, status: 'ACTIVE' })
      .unwrap()
      .then((response) => {
        setMessage(`История успеха успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить историю успеха.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setData(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateSuccess(
      image.file && images.length === 0
        ? { id, ...values, image, status: 'ACTIVE' }
        : images.length > 0 && !image.file
        ? { id, ...values, images, status: 'ACTIVE' }
        : image.file && images.length > 0
        ? { id, ...values, image, images, status: 'ACTIVE' }
        : { id, ...values, status: 'ACTIVE' },
    )
      .unwrap()
      .then((response) => {
        setMessage(`История успеха успешно обновлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить историю успеха. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setData(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить историю успеха ${
        success?.content?.find((x) => x.id === obj.id)?.name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteSuccess(id)
      .unwrap()
      .then((response) => {
        setMessage(
          `История успеха "${
            success?.content?.find((x) => x.id === response)?.name
          }" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить историю успеха. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Истории успеха';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
        setImage={setImage}
        setImages={setImages}
      />

      <EditModal
        data={data}
        setImage={setImage}
        setImages={setImages}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        data={data}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-news my-5">
        <div className="container">
          <div className="section-title">
            <h1 className="text-center">Истории успеха</h1>
            <hr />
          </div>

          {isAdmin && (
            <Button
              variant="default"
              className="btn btn-outline-secondary w-100 mb-4"
              onClick={modalShowCreate}>
              <FaPlus className="me-2" />
              Добавить историю успеха
            </Button>
          )}

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            [...new Array(5)].map((_, index) => <LoaderNews key={index} />)
          ) : (
            <>
              <div className="success">
                {success?.content?.map((obj) => (
                  <SuccessStoryItem
                    obj={obj}
                    key={obj.id}
                    isAdmin={isAdmin}
                    modalShowEdit={modalShowEdit}
                    modalShowDelete={modalShowDelete}
                  />
                ))}
              </div>

              <PaginationCustom totalPages={success?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default SuccessStory;
