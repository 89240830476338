import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { FaBuilding, FaEdit, FaMapMarkerAlt, FaTrashAlt, FaUsers } from 'react-icons/fa';

import {
  useGetRoomsQuery,
  useCreateRoomMutation,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
} from '../../../../services/rooms';

import { useGetRoomTypesQuery } from '../../../../services/roomTypes';
import { useGetBuildingsQuery } from '../../../../services/buildings';
import { useGetCompetentionsQuery } from '../../../../services/competentions';

import CreateModal from '../../../../components/Modals/Rooms/Create';
import EditModal from '../../../../components/Modals/Rooms/Edit';
import DeleteModal from '../../../../components/Modals/Rooms/Delete';

import PaginationCustom from '../../../../components/PaginationCustom';
import AlertMessage from '../../../../components/AlertMessage';

import useAuth from '../../../../hooks/useAuth';

import './Rooms.scss';

const Rooms = () => {
  const { isAdmin } = useAuth();

  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: rooms, isLoading } = useGetRoomsQuery({
    size: 20,
    page,
    role: isAdmin ? 'admin' : 'manager',
  });
  const [createRoom, { isError: isErrorCreate }] = useCreateRoomMutation();
  const [updateRoom, { isError: isErrorUpdate }] = useUpdateRoomMutation();
  const [deleteRoom, { isError: isErrorDelete }] = useDeleteRoomMutation();

  const { data: roomTypes } = useGetRoomTypesQuery(20);
  const { data: buildings } = useGetBuildingsQuery({ role: isAdmin ? 'admin' : 'manager' });
  const { data: competentions } = useGetCompetentionsQuery({ size: 200, page: 0 });

  const [image, setImage] = React.useState({ name: '', file: '' });

  const [room, setRoom] = React.useState({});

  const [teachers, setTeachers] = React.useState([]);

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.roomType = { id: values.roomType };
    values.building = { id: values.building };
    values.teachers = values.teachers.map((x) => ({ id: x }));
    values.competentions = values.competentions.map((x) => ({ id: x }));

    createRoom({ role: isAdmin ? 'admin' : 'manager', body: { image, ...values } })
      .unwrap()
      .then((response) => {
        setMessage(`Площадка "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить площадку.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setRoom(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.roomType = { id: values.roomType };
    values.building = { id: values.building };
    values.teachers = values.teachers.map((x) => ({ id: x }));
    values.competentions = values.competentions.map((x) => ({ id: x }));

    updateRoom(image.name ? { id, image, ...values } : { id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Площадка "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить площадку.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setRoom(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить площадку ${rooms.content.find((x) => x.id === obj.id).name}?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteRoom(id)
      .unwrap()
      .then((response) => {
        setMessage(
          `Площадка "${rooms.content.find((x) => x.id === response).name}" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить площадку.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Площадки';
  }, []);

  return (
    <>
      <CreateModal
        setImage={setImage}
        roomTypes={roomTypes?.content ?? []}
        buildings={buildings?.content ?? []}
        teachers={teachers}
        competentions={competentions?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        setImage={setImage}
        room={room}
        roomTypes={roomTypes?.content ?? []}
        buildings={buildings?.content ?? []}
        teachers={teachers}
        competentions={competentions?.content ?? []}
        submitEdit={submitEdit}
        showEdit={showEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        room={room}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-rooms my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Площадки</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-4" onClick={modalShowCreate}>
            Добавить площадку
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}
          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <div className="rooms">
                {rooms?.content?.map((obj) => (
                  <div key={obj.id} className="room-item">
                    <img
                      src={
                        obj.image
                          ? process.env.REACT_APP_API_URL + obj.image.src
                          : 'https://copp.ruobr.ru/static/copp/img/logo.jpg'
                      }
                      alt={obj.name}
                      className="room-item__img"
                    />

                    <h5 className="room-item__name">{obj?.name}</h5>
                    <div className="room-item__address">
                      <FaMapMarkerAlt /> Адрес: {obj?.building ? obj?.building?.address : ''}
                    </div>
                    <div className="room-item__places">
                      <FaUsers /> Рабочих мест: {obj?.seats}
                    </div>
                    <div className="room-item__type">
                      <FaBuilding /> Тип площадки: {obj?.roomType?.name}
                    </div>

                    <div className="room-item__btns">
                      <Button variant="outline-primary" onClick={() => modalShowEdit(obj)}>
                        <FaEdit /> Редактировать
                      </Button>

                      <Button variant="outline-danger" onClick={() => modalShowDelete(obj)}>
                        <FaTrashAlt /> Удалить
                      </Button>
                    </div>
                  </div>
                ))}
              </div>

              <PaginationCustom totalPages={rooms?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Rooms;
