import React from 'react';
import { Modal, Form } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Resizer from 'react-image-file-resizer';

const CreateModalPartners = ({ submitCreate, showCreate, modalCloseCreate, setLogoPartners }) => {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          file,
          1280,
          720,
          'WEBP',
          90,
          0,
          (uri) => {
            resolve(uri);
          },
          'base64',
        );
      } catch (err) {
        reject(err);
      }
    });

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    try {
      const result = await toBase64(file);
      setLogoPartners({ name: file.name, file: result });
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    name: '',
    link: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле!'),
    link: Yup.string().required('Обязательно поле!'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление партнёра</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group controlId="partnersName" className="mb-3">
            <Form.Label>Наименование</Form.Label>
            <Form.Control
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="partnersLink" className="mb-3">
            <Form.Label>Ссылка</Form.Label>
            <Form.Control
              name="link"
              onChange={formik.handleChange}
              value={formik.values.link}
              isValid={formik.touched.link && !formik.errors.link}
              isInvalid={!!formik.errors.link}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.link}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="partnersImage" className="mb-3">
            <Form.Label>Изображение</Form.Label>
            <Form.Control type="file" name="image" onChange={handleFileUpload} />
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModalPartners;
