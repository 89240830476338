import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderEvents = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={483}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="250" />
    <rect x="0" y="260" rx="5" ry="5" width="100%" height="120" />
    <rect x="0" y="390" rx="5" ry="5" width="100%" height="30" />
    <rect x="0" y="430" rx="5" ry="5" width="100%" height="50" />
  </ContentLoader>
);

export default LoaderEvents;
