import React from 'react';

import { useParams } from 'react-router-dom';

import { useGetResumeByIdQuery } from '../../../services/resumes';
import { useGetResponsesQuery, useInviteFromResumeMutation } from '../../../services/responses';
import { useGetVacancyQuery } from '../../../services/vacancy';

import NotFound from '../../NotFound';
import { dateFormat } from '../../../dateFormat';
import { Button, Form, Modal } from 'react-bootstrap';
import useAuth from '../../../hooks/useAuth';
import AlertMessage from '../../../components/AlertMessage';

const ResumeById = () => {
  const { data } = useAuth();

  const { id } = useParams();
  const { data: resume, isLoading } = useGetResumeByIdQuery(id);
  const { data: vacancies, isLoadingVacancies } = useGetVacancyQuery({
    role: 'employer',
    size: 100,
    page: 0,
  });

  const { data: responses, isLoadingResponses } = useGetResponsesQuery('employer');

  const [inviteFromResume, { isError: isErrorInvite }] = useInviteFromResumeMutation();

  const [isInvite, setIsInvite] = React.useState(false);

  const handleInviteClose = () => setIsInvite(false);
  const handleInviteShow = () => setIsInvite(true);

  const [message, setMessage] = React.useState({ text: '', isError: false });

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    if (!formData.has('vacancy') || !formData.get('vacancy')) {
      setMessage({ text: 'Выберите вакансию', isError: true });
      return;
    }

    let body = {
      organization: { id: data.user ? data.user?.organization?.id : data.organization?.id },
      user: { id: resume.author?.id },
      resume: { id: resume?.id },
      vacancy: { id: +formData.get('vacancy') },
      responseStatus: 'INVITE',
    };

    inviteFromResume(body)
      .unwrap()
      .then((response) => {
        setMessage({ text: `Приглашение успешно отправлено`, isError: false });
      })
      .catch((err) => {
        setMessage({
          text: `Не удалось отправить приглашение. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
          isError: true,
        });
      });

    handleInviteClose();
  };

  if (resume === null) {
    return <NotFound />;
  }

  return (
    <section className="section section-resume-detail my-5">
      <div className="container">
        {message?.text && (
          <AlertMessage
            message={message?.text}
            setMessage={setMessage}
            isError={message?.isError || isErrorInvite}
          />
        )}

        {isLoading || isLoadingResponses ? (
          <h1 className="text-center">Загрузка ...</h1>
        ) : (
          <div className="vacancy-detail">
            <Modal show={isInvite} onHide={handleInviteClose}>
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Приглашение на вакансию</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Form.Select name="vacancy">
                    <option value="">Выберите вакансию</option>
                    {vacancies?.content?.map((vacancy) => (
                      <option value={vacancy?.id} key={vacancy?.id}>
                        {vacancy?.position}
                      </option>
                    ))}
                  </Form.Select>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="success" onClick={handleInviteClose} type="submit">
                    Пригласить
                  </Button>
                  <Button variant="danger" onClick={handleInviteClose}>
                    Отмена
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>

            <div className="vacancy-detail__info mb-3">
              <h4 className="vacancy-detail__info-title text-center">Резюме #{resume?.id}</h4>

              <hr />
              <div className="resume-detail__info-date text-muted text-center">
                Резюме опубликовано {new Date(resume?.created).toLocaleDateString()}
              </div>
            </div>

            <div className="resume-detail__education-level mb-1">
              <strong>Образование: {resume?.educationLevel}</strong>
              <ul>
                {resume?.educations?.map((x, index) => (
                  <div key={x?.id}>
                    <li>Учреждение: {x?.organization ?? ''}</li>
                    <li>Специальность: {x?.name ?? ''}</li>
                    <li>Дата окончания: {new Date(x?.endDate).toLocaleDateString() ?? ''}</li>
                  </div>
                ))}
              </ul>
            </div>

            <div className="resume-detail__perks mb-1">
              <strong>Дополнительное образование:</strong>
              <ul>
                {resume?.additionalEducations?.map((x, index) => (
                  <li key={index}>{x?.name}</li>
                ))}
              </ul>
            </div>

            <div className="resume-detail__perks mb-1">
              <strong>Навыки:</strong>
              <ul>
                {resume?.perks?.map((x, index) => (
                  <li key={index}>{x?.text}</li>
                ))}
              </ul>
            </div>

            <div className="resume-detail__perks mb-1">
              <strong>Независимая оценка квалификаций:</strong>
              <ul>
                {resume?.independenceScore?.map((x, index) => (
                  <li key={index}>{x?.text}</li>
                ))}
              </ul>
            </div>

            <div className="resume-detail__softSkills mb-1">
              <strong>SoftSkills:</strong>
              <ul>
                {resume?.softSkills?.map((x, index) => (
                  <li key={index}>{x?.text}</li>
                ))}
              </ul>
            </div>

            <div className="resume-detail__contacts mb-3">
              <h2>Контактная информация</h2>
              <p className="mb-1">
                <strong>ФИО:</strong> {resume?.firstName} {resume?.lastName} {resume?.secondName}
              </p>
              <p className="mb-1">
                <strong>Дата рождения:</strong> {dateFormat(resume?.birthdate)}
              </p>
              <p className="mb-1">
                <strong>Телефон:</strong> {resume?.phone}
              </p>
              <p className="mb-0">
                <strong>EMail:</strong> {resume?.email}
              </p>
            </div>

            {!isLoadingResponses &&
              (responses?.content?.find((response) => response.resume?.id === resume?.id) ? (
                <h3 className="mt-3 text-center">
                  Вы уже пригласили данное резюме на вакансию{' '}
                  {`"${
                    responses?.content?.find((response) => response.resume?.id === resume?.id)
                      ?.vacancy.position
                  }"`}
                </h3>
              ) : (
                <Button
                  variant="primary"
                  className="w-100 py-2 text-uppercase fw-bold"
                  onClick={handleInviteShow}>
                  Пригласить
                </Button>
              ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default ResumeById;
