import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderEducations = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={364}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="250" />
    <rect x="0" y="260" rx="5" ry="5" width="100%" height="30" />
    <rect x="0" y="305" rx="5" ry="5" width="100%" height="25" />
  </ContentLoader>
);

export default LoaderEducations;
