import { Link } from 'react-router-dom';
import { FaCrosshairs } from 'react-icons/fa';

import useAuth from '../../hooks/useAuth';

const Programm = ({ obj, handleSignUpProgram }) => {
  const { id, name, image, target } = obj;

  const { isAuth } = useAuth();

  return (
    <div className="program-item">
      <div className="program-img">
        <img src={image ? process.env.REACT_APP_API_URL + image.src : ''} alt="" />
      </div>

      <div className="program-content">
        <Link className="program-title" to={`/programs/${id}`}>
          {name}
        </Link>
        <div className="program-audience">
          <span>{<FaCrosshairs />} Целевая аудитория:</span> {target ? target.name : 'Все'}
        </div>
      </div>

      {isAuth ? (
        <button className="program-btn" onClick={() => handleSignUpProgram(obj)}>
          Записаться
        </button>
      ) : (
        <Link className="program-btn" to="/login">
          Записаться <span>Авторизуйтесь или зарегистрируйтесь</span>
        </Link>
      )}
    </div>
  );
};

export default Programm;
