import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { fileResizeAndUpload } from '../../../fileResizeAndUpload';

const CreateModal = ({ types, targets, setImage, submitCreate, showCreate, modalCloseCreate }) => {
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    await fileResizeAndUpload(file, setImage);
  };

  const initialValues = {
    name: '',
    type: -1,
    targetGroup: -1,
    address: '',
    description: '',
    begin: '',
    end: '',
    canFollow: '',
    speakerName: '',
    speakerEmail: '',
    speakerPhone: '',
    seats: 1,
    bookedSeats: 0,
    listeners: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательное поле'),
    type: Yup.number().moreThan(-1, 'Выберите форму проведения').required('Обязательное поле'),
    targetGroup: Yup.number()
      .moreThan(-1, 'Выберите целевую аудиторию')
      .required('Обязательное поле'),
    address: Yup.string().required('Обязательное поле'),
    description: Yup.string().required('Обязательное поле'),
    begin: Yup.date()
      // .min(new Date(), 'Дата должна быть не меньше текущей')
      .required('Обязательное поле'),
    end: Yup.date()
      // .min(new Date(), 'Дата должна быть не меньше текущей')
      .required('Обязательное поле'),
    canFollow: Yup.boolean().required('Обязательное поле'),
    speakerName: Yup.string().required('Обязательное поле'),
    speakerEmail: Yup.string().email('Введите валидный email').required('Обязательное поле'),
    speakerPhone: Yup.string().required('Обязательное поле'),
    seats: Yup.number().min(1, 'Минимальное количество 1').required('Обязательное поле'),
    bookedSeats: Yup.number().min(0, 'Минимальное количество 0').required('Обязательное поле'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление мероприятия</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate className="event-form">
          <Row>
            <Form.Group as={Col} controlId="eventName" className="mb-3">
              <Form.Label>Название мероприятия *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="eventType" className="mb-3">
              <Form.Label>Форма проведения *</Form.Label>
              <Form.Select
                name="type"
                onChange={formik.handleChange}
                value={formik.values.type}
                isValid={formik.touched.type && !formik.errors.type}
                isInvalid={!!formik.errors.type}>
                <option value="-1">Выберите форму проведения</option>

                {types.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.type}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="eventTargetGroup" className="mb-3">
              <Form.Label>Целевая аудитория *</Form.Label>
              <Form.Select
                name="targetGroup"
                onChange={formik.handleChange}
                value={formik.values.targetGroup}
                isValid={formik.touched.targetGroup && !formik.errors.targetGroup}
                isInvalid={!!formik.errors.targetGroup}>
                <option value="-1">Выберите целевую аудиторию</option>
                {targets.map((target) => (
                  <option key={target.id} value={target.id}>
                    {target.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.userType}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="eventAddress" className="mb-3">
              <Form.Label>Место проведения *</Form.Label>
              <Form.Control
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                isValid={formik.touched.address && !formik.errors.address}
                isInvalid={!!formik.errors.address}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.address}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="eventDescription" className="mb-3">
              <Form.Label>Описание мероприятия *</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                isValid={formik.touched.description && !formik.errors.description}
                isInvalid={!!formik.errors.description}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="eventBegin" className="mb-3">
              <Form.Label>Дата начала *</Form.Label>
              <Form.Control
                type="datetime-local"
                name="begin"
                onChange={formik.handleChange}
                value={formik.values.begin}
                isValid={formik.touched.begin && !formik.errors.begin}
                isInvalid={!!formik.errors.begin}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.begin}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="eventEnd" className="mb-3">
              <Form.Label>Дата окончания *</Form.Label>
              <Form.Control
                type="datetime-local"
                name="end"
                onChange={formik.handleChange}
                value={formik.values.end}
                isValid={formik.touched.end && !formik.errors.end}
                isInvalid={!!formik.errors.end}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.end}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="align-items-center">
            <Form.Group as={Col} controlId="eventImage" className="mb-3">
              <Form.Label>Изображение</Form.Label>
              <Form.Control type="file" name="image" onChange={handleFileUpload} />
            </Form.Group>

            <Form.Group as={Col} className="mt-3">
              <Form.Check
                type="switch"
                id="switch-canFollow"
                name="canFollow"
                onChange={formik.handleChange}
                value={formik.values.canFollow}
                label="Данное мероприятие доступно для записи"
              />
            </Form.Group>
          </Row>

          <div className="form-info mt-2">
            <h5 className="text-center">Контактная информация</h5>
            <hr />
          </div>

          <Row>
            <Form.Group as={Col} controlId="eventSpeakerName" className="mb-3">
              <Form.Label>Контактное имя *</Form.Label>
              <Form.Control
                name="speakerName"
                onChange={formik.handleChange}
                value={formik.values.speakerName}
                isValid={formik.touched.speakerName && !formik.errors.speakerName}
                isInvalid={!!formik.errors.speakerName}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.speakerName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="eventSpeakerEmail" className="mb-3">
              <Form.Label>Контактный email *</Form.Label>
              <Form.Control
                type="email"
                name="speakerEmail"
                onChange={formik.handleChange}
                value={formik.values.speakerEmail}
                isValid={formik.touched.speakerEmail && !formik.errors.speakerEmail}
                isInvalid={!!formik.errors.speakerEmail}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.speakerEmail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="eventSpeakerPhone" className="mb-3">
              <Form.Label>Контактный телефон *</Form.Label>
              <Form.Control
                name="speakerPhone"
                onChange={formik.handleChange}
                value={formik.values.speakerPhone}
                isValid={formik.touched.speakerPhone && !formik.errors.speakerPhone}
                isInvalid={!!formik.errors.speakerPhone}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.speakerPhone}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <div className="form-info mt-2">
            <h5 className="text-center">Требования</h5>
            <hr />
          </div>

          <Row>
            <Form.Group as={Col} controlId="eventSeats" className="mb-3">
              <Form.Label>Необходимое количество мест *</Form.Label>
              <Form.Control
                type="number"
                min="1"
                name="seats"
                onChange={formik.handleChange}
                value={formik.values.seats}
                isValid={formik.touched.seats && !formik.errors.seats}
                isInvalid={!!formik.errors.seats}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.seats}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="eventBookedSeats" className="mb-3">
              <Form.Label>Зарезервировано мест *</Form.Label>
              <Form.Control
                name="bookedSeats"
                onChange={formik.handleChange}
                value={formik.values.bookedSeats}
                isValid={formik.touched.bookedSeats && !formik.errors.bookedSeats}
                isInvalid={!!formik.errors.bookedSeats}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.bookedSeats}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
