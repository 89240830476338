import React from 'react';
import { useSearchParams } from 'react-router-dom';

import BookingItem from '../../components/Booking';
import LoaderBooking from '../../components/Loaders/Booking';
import PaginationCustom from '../../components/PaginationCustom';
import { useGetBookingQuery } from '../../services/booking';

import './Booking.scss';

const Booking = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const query = {
    size: 20,
    page,
  };
  const { data: booking, isLoading } = useGetBookingQuery(query);

  React.useEffect(() => {
    document.title = 'ЦОПП - Бронирование';
  }, []);

  return (
    <section className="section section-booking my-5">
      <div className="container">
        <div className="section-title">
          <h1>Бронирование оборудования и аудитории</h1>
          <hr />
        </div>

        <div className="booking">
          {isLoading
            ? [...new Array(6)].map((_, index) => <LoaderBooking key={index} />)
            : booking?.content
                ?.filter((x) => x.hire)
                .map((obj) => <BookingItem key={obj.id} {...obj} />)}
        </div>

        <PaginationCustom totalPages={booking?.totalPages} page={page} setPage={setPage} />
      </div>
    </section>
  );
};

export default Booking;
