import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';

import { FormikSelect } from '../FormikSelect';

import workType from '../../workType.js';
import relations from '../../relations.js';

const FilterVacancy = ({ filterIsOpen, setFilterIsOpen }) => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const onFilterClose = () => setFilterIsOpen(false);

  const initialValues = {
    name: '',
    relations: '',
    workType: '',
    salaryFrom: '',
    salaryTo: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      [...searchParams.keys()].map((x) => {
        searchParams.delete(x);
      });

      setSearchParams(searchParams);

      if (values.name.trim()) {
        searchParams.set('name', values.name);
        setSearchParams(searchParams);
      }

      if (values.relations) {
        searchParams.set('relations', values.relations);
        setSearchParams(searchParams);
      }

      if (values.workType) {
        searchParams.set('workType', values.workType);
        setSearchParams(searchParams);
      }

      if (values.salaryFrom) {
        setSearchParams({ ...searchParams, salaryFrom: values.salaryFrom });
      }

      if (values.salaryTo) {
        setSearchParams({ ...searchParams, salaryTo: values.salaryTo });
      }

      onFilterClose();
    },
  });

  return (
    <>
      <Modal show={filterIsOpen} onHide={onFilterClose} centered={true} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Фильтр</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit} noValidate>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label className="mb-1">Наименование</Form.Label>
              <Form.Control name="name" value={formik.values.name} onChange={formik.handleChange} />
            </Form.Group>

            <Row>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label className="mb-1">Тип занятости</Form.Label>

                <FormikSelect
                  name="relations"
                  value={formik.values.relations}
                  form={formik}
                  options={Object.entries(relations).map((x) => ({ value: x[0], label: x[1] }))}
                  placeholder="Тип занятости"
                />
              </Form.Group>

              <Form.Group className="mb-3" as={Col}>
                <Form.Label className="mb-1">График работы</Form.Label>

                <FormikSelect
                  name="workType"
                  value={formik.values.workType}
                  form={formik}
                  options={Object.entries(workType).map((x) => ({ value: x[0], label: x[1] }))}
                  placeholder="График работы"
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-success" onClick={() => formik.submitForm()} className="col">
            Фильтровать
          </Button>

          <Button variant="outline-secondary" onClick={onFilterClose} className="col">
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterVacancy;
