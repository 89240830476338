import React from 'react';
import { Link } from 'react-router-dom';

import {
    FaChalkboardTeacher,
    FaRegCalendarAlt,
    FaRegCircle,
    FaRegEdit,
    FaTrashAlt,
} from 'react-icons/fa';

import { Button } from 'react-bootstrap';

import STATUS from '../../statusRequest';

import logo from '../../assets/img/logo.jpg';
import EventSignUp from '../EventSignUp';
import { dateFormat } from '../../dateFormat';

const EventItem = ({
    obj,
    modalShowEdit,
    modalShowDelete,
    userId,
    isAuth,
    isAdmin,
    isManager,
    isUser,
    isProfile = false,
}) => {
    const { id, image, name, begin, type, requestStatus } = obj;

    return (
        <div className="event-item">
            {!isProfile && (
                <div className="event-img">
                    <img
                        src={image?.src ? process.env.REACT_APP_API_URL + image.src : logo}
                        alt={name}
                        className={
                            image?.src &&
                            (image?.src.includes('логотип 5 лет') ||
                                image?.src.includes('Y4PNx1yHHt4-1719385509306')) &&
                            'object-fit-contain'
                        }
                    />
                </div>
            )}

            <div className="event-content">
                <Link className="event-title" to={`/events/${id}`}>
                    {name}
                </Link>

                {isProfile && (
                    <div className="event-date">
                        {<FaChalkboardTeacher />} Форма проведения: {type.name}
                    </div>
                )}

                {begin && (
                    <div className="event-date">
                        {<FaRegCalendarAlt />} Дата проведения: {dateFormat(begin)}
                    </div>
                )}

                {(isAdmin || isManager) && (
                    <div className="event-status">
                        {<FaRegCircle />} Статус: {STATUS[requestStatus] ?? 'Не определен'}
                    </div>
                )}
            </div>

            {(isAdmin || isManager) && (
                <div className="event-btns">
                    <Button variant="outline-primary" onClick={() => modalShowEdit(obj)}>
                        <FaRegEdit /> Редактировать
                    </Button>

                    <Button variant="outline-danger" onClick={() => modalShowDelete(obj)}>
                        <FaTrashAlt /> Удалить
                    </Button>
                </div>
            )}

            {!isProfile && !isAdmin && !isAuth && (
                <Link to="/login" className="event-btn">
                    Принять участие <span>Авторизуйтесь или зарегистрируйтесь</span>
                </Link>
            )}

            <EventSignUp event={obj} userId={userId} isUser={isUser} />
        </div>
    );
};

export default EventItem;
