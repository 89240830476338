import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const equipmentsAPI = createApi({
  reducerPath: 'equipmentsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Equipments'],
  endpoints: (builder) => ({
    getEquipments: builder.query({
      query: ({ size = 20, page = 0, role }) => `${role}/equipments/?size=${size}&page=${page}`,
      providesTags: () => ['Equipments'],
    }),

    createEquipment: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/equipments/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Equipments'],
    }),

    updateEquipment: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/equipments/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Equipments'],
    }),

    deleteEquipment: builder.mutation({
      query: ({ role, id }) => ({
        url: `${role}/equipments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Equipments'],
    }),
  }),
});

export const {
  useGetEquipmentsQuery,
  useCreateEquipmentMutation,
  useUpdateEquipmentMutation,
  useDeleteEquipmentMutation,
} = equipmentsAPI;
