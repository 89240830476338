import React from 'react';

import { Modal, Form } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditModal = ({ userType, showEdit, submitEdit, modalCloseEdit }) => {
  const { id, name } = userType;

  const initialValues = {
    name: name || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitEdit(id, values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showEdit}
      onHide={modalCloseEdit}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование типа пользователя</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Form.Group controlId="userTypeName" className="mb-3">
            <Form.Control
              name="name"
              placeholder="Наименование"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>

          <button type="submit" className="btn btn-outline-secondary w-100">
            Сохранить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
