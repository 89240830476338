import React from 'react';
import { Button } from 'react-bootstrap';
import {
  FaChartLine,
  FaChevronRight,
  FaEdit,
  FaEye,
  FaFilter,
  FaPlus,
  FaTrashAlt,
  FaWindowClose,
} from 'react-icons/fa';
import { Link, useSearchParams } from 'react-router-dom';

import {
  useCreateVacancyMutation,
  useDeleteVacancyMutation,
  useGetVacancyQuery,
  useUpdateVacancyMutation,
} from '../../services/vacancy';

import { useGetConstructorQuery } from '../../services/constructor';

import CreateModal from '../../components/Modals/Vacancy/Create';
import EditModal from '../../components/Modals/Vacancy/Edit';
import DeleteModal from '../../components/Modals/Vacancy/Delete';

import AlertMessage from '../../components/AlertMessage';
import PaginationCustom from '../../components/PaginationCustom';
import useAuth from '../../hooks/useAuth';

import './Vacancy.scss';
import FilterVacancy from '../../components/Modals/FilterVacancy';

const Vacancy = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const [filterIsOpen, setFilterIsOpen] = React.useState(false);

  const nameOfFilter = searchParams.get('name') ?? null;
  const relationsOfFilter = searchParams.get('relations') ?? null;
  const workTypeOfFilter = searchParams.get('workType') ?? null;

  const isFilters = () => nameOfFilter || relationsOfFilter || workTypeOfFilter;

  const clearFilters = () => setSearchParams({});

  const { isEmployer, isAdmin } = useAuth();

  const { data: vacancy, isLoading } = useGetVacancyQuery({
    role: isEmployer ? 'employer' : 'user',
    size: 6,
    page,
  });

  const { data: objects, isLoadingObjects } = useGetConstructorQuery('objects');

  const [createVacancy, { isError: isErrorCreate }] = useCreateVacancyMutation();
  const [updateVacancy, { isError: isErrorUpdate }] = useUpdateVacancyMutation();
  const [deleteVacancy, { isError: isErrorDelete }] = useDeleteVacancyMutation();

  const [data, setData] = React.useState({});

  const [message, setMessage] = React.useState(null);
  const [deleteMessage, setDeleteMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values['object'] = { id: values.object };
    createVacancy(values)
      .unwrap()
      .then((response) => {
        setMessage(`Вакансия ${response?.position} успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить вакансию. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setData(obj);
    setShowEdit(true);
  };
  const submitEdit = (values) => {
    values['organization'] = { id: values.organization };
    values['object'] = { id: values.object };
    updateVacancy(values)
      .unwrap()
      .then((response) => {
        setMessage(`Вакансия ${response?.position} успешно обновлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить вакансию. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setData(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить вакансию ${
        vacancy?.content?.find((x) => x.id === obj.id)?.position
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteVacancy(id)
      .unwrap()
      .then((response) => {
        setMessage(`Вакансия успешно удалена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить вакансия. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Вакансии';
  }, []);

  return (
    <>
      <CreateModal
        objects={objects?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        objects={objects?.content ?? []}
        vacancy={data}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        vacancy={data}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-vacancy my-5">
        <div className="container">
          <div className="section-title">
            <h1 className="text-center">Вакансии</h1>
            <hr />

            <div className="filters-wrapper" onClick={() => setFilterIsOpen(true)}>
              <div className="filters">
                <FaFilter /> Фильтр
              </div>
              <span>{isLoading ? '...' : vacancy?.content?.length}</span>
            </div>

            {isFilters() && (
              <button className="btn btn-primary mb-3 fw-bold" onClick={clearFilters}>
                <FaWindowClose size={36} className="me-2" /> Очистить фильтры
              </button>
            )}
          </div>

          {isAdmin && (
            <Button
              variant="outline-primary"
              className="mb-4 d-block mx-auto"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL}/statistics/vacancies/`)
              }>
              <FaChartLine size="1.1em" /> Выгрузить статистику
            </Button>
          )}

          {isEmployer && (
            <Button
              variant="default"
              className="btn btn-outline-secondary w-100 mb-4"
              onClick={modalShowCreate}>
              <FaPlus className="me-2" />
              Добавить вакансию
            </Button>
          )}

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          <FilterVacancy filterIsOpen={filterIsOpen} setFilterIsOpen={setFilterIsOpen} />

          {!isLoading && (
            <>
              <div className="vacancy mb-3">
                {vacancy?.content?.map((x) => (
                  <div className="vacancy-item" key={x?.id}>
                    <Link to={`/vacancy/${x?.id}`}>
                      <h5 className="vacancy-name">
                        {x?.position} (г. {x?.city}
                        {x?.region ? `, ${x?.region}` : ''})
                      </h5>
                    </Link>

                    <h5 className="vacancy-compensation mb-3">
                      {String(x?.salaryFrom).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} –{' '}
                      {String(x?.salaryTo).replace(/(.)(?=(\d{3})+$)/g, '$1 ')} руб.
                    </h5>
                    {isEmployer && (
                      <h5 className="text-uppercase">
                        {x?.status === 'ACTIVE' ? 'Опубликована' : 'Скрыта'}
                      </h5>
                    )}

                    <p className="vacancy-company mb-3">{x?.organization}</p>

                    {/* <p className="vacancy-description">{x?.description}</p> */}

                    {isEmployer && (
                      <div className="vacancy-btns">
                        <Link to={`/vacancy/${x?.id}`} className="btn btn-outline-success w-100">
                          <FaEye size="3em" />
                        </Link>

                        <button
                          className="btn btn-outline-primary w-100"
                          onClick={() => modalShowEdit(x)}>
                          <FaEdit size="3em" />
                        </button>

                        <button
                          className="btn btn-outline-danger w-100"
                          onClick={() => modalShowDelete(x)}>
                          <FaTrashAlt size="3em" />
                        </button>
                      </div>
                    )}

                    <Link to={`/vacancy/${x?.id}`} className="mt-3">
                      Подробнее <FaChevronRight className="ms-1" size="0.75em" />
                    </Link>
                  </div>
                ))}
              </div>
              <PaginationCustom totalPages={vacancy?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Vacancy;
