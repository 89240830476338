import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const modulesAPI = createApi({
  reducerPath: 'modulesAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Modules'],
  endpoints: (builder) => ({
    getModules: builder.query({
      query: ({ size = 20, page = 0, role }) => `${role}/modules/?size=${size}&page=${page}`,
      providesTags: () => ['Modules'],
    }),

    createModule: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/modules/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Modules'],
    }),

    updateModule: builder.mutation({
      query: ({ role, body }) => ({
        url: `${role}/modules/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Modules'],
    }),

    deleteModule: builder.mutation({
      query: ({ role, id }) => ({
        url: `${role}/modules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Modules'],
    }),
  }),
});

export const {
  useGetModulesQuery,
  useCreateModuleMutation,
  useUpdateModuleMutation,
  useDeleteModuleMutation,
} = modulesAPI;
